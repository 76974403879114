import * as firebase from "firebase"

import C from "./constants"
import { settingHashParameters } from "../components/utils/urlParams"
import { createDish } from './createDish'
import { createRest } from './createRest'
import { selectDish } from './selectedDish'
import { selectRest } from './selectedRest'
import { updatingPersonalEvaluations } from './user'

export const evaluate = (form, userData, message, newDish, newRest) => {
  return dispatch => {
    console.log("EVALUATION_SAVING")
    dispatch({ type: C.EVALUATION_SAVING })

    if (form.dish.uid === undefined) {
      dispatch(createDish(form, true, userData, message))
    }

    if (form.dish.uid !== undefined && form.rest.uid === undefined) {
      newDish ? dispatch(createRest(form, true, userData, message, newDish)) : dispatch(createRest(form, true, userData, message))
    }

    if (form.dish.uid !== undefined && form.rest.uid !== undefined) {
      console.log('form', form)
      const evalRef = firebase.firestore().collection("evaluations")
      evalRef
        .add({
          anonymous: form.anonymous,
          comments: form.comments,
          creationTime: firebase.firestore.FieldValue.serverTimestamp(),
          dish: {
            name: form.dish.name,
            uid: form.dish.uid,
          },
          grades: {
            presentation: form.grades.presentation,
            price: form.grades.price,
            size: form.grades.size,
            taste: form.grades.taste,
            time: form.grades.time,
          },
          rest: {
            name: form.rest.name,
            uid: form.rest.uid,
          },
          tags: form.tags,
          user: {
            login: form.user.email,
            uid: form.user.uid,
          },
          newDishProcessed: !newDish,
          newRestProcessed: !newRest,
          firstRestEval: !!newRest
        })
        .then(function () {
          console.log("EVALUATION_SAVE_DONE")
          dispatch({ type: C.EVALUATION_SAVE_DONE })
          alert(message)
          settingHashParameters({
            find: "presentation",
            evaluate: "presentation",
          })
          dispatch(updatingPersonalEvaluations(userData))
          dispatch(selectDish(null, false, 'evaluate'))
          dispatch(selectRest(null, false, 'evaluate'))
        })
        .catch(error => {
          console.log("EVALUATION_SAVE_ERROR")
          console.log(error)
          dispatch({ type: C.EVALUATION_SAVE_EROR, error: error })
        })
    }
  }
}
