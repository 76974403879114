import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Colors, Shadows } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"

const CardComponent = styled.div`
  width: 100%;
  border-radius: 20px;
  border: none;
  padding: 20px 0px;
  min-height: 133px;
  min-width: 280px;
  margin-left: 23px;

  box-shadow: ${props => Shadows[props.styleMode][props.theme].L00};
  background: ${props => Colors[props.styleMode][props.theme].L10};
  // height: 125px;

  z-index: 2;

  ${props =>
    props.leftButton &&
    css`
      left: -10px;
    `}
`
// ${props =>
//   true ? "" : props.cardHeight && `height: ` + props.cardHeight + `px;`}

interface PropsCard {
  leftButton?: boolean
  children?: JSX.Element
  onClickCard?: Function
}

export default (props: PropsCard): JSX.Element => {
  const theme = useContext(ThemeContext)

  return <CardComponent {...theme} onClick={props.onClickCard}>{props.children}</CardComponent>
}
