import C from "./constants"
import * as firebase from "firebase"


export const fetchingUsersEvalsDishes = userID => {
  const db = firebase.firestore()
  let date = firebase.firestore.Timestamp.now()
  date.seconds = date.seconds - 20 * 60 * 60 * 1000
  const evalsRef = db
    .collection("evaluations")
    .where("user.uid", "==", userID)
    .where("creationTime", ">", date)
  const evals = []
  return evalsRef
    .get()
    .then(querySnapshot =>
      querySnapshot.forEach(doc =>
        evals.push({
          dish: doc.data().dish.uid,
          rest: doc.data().rest.uid,
        })
      )
    )
    .then(() => evals)
}



export const searchingItem = item => {
  return dispatch => {
    console.log('SEARCHING_ITEM')
    dispatch({ type: C.SEARCHING_ITEM, item: item })
  }
}

export const saveSearch = dish => {
  const db = firebase.firestore()
  const now = new Date(),
    nowFormatted =
      "" + now.getFullYear() + "/" + (now.getMonth() + 1) + "/" + now.getDate()

  return db
    .collection("kpis")
    .where("dateFormatted", "==", nowFormatted)
    .get()
    .then(querySnapshot => {
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(doc => {
          var searchesDishes = []
          doc.data().searchesDishes.map(e => searchesDishes.push(e))
          searchesDishes.push(dish)
          db.collection("kpis")
            .doc(doc.id)
            .set(
              {
                searchQty: doc.data().searchQty + 1,
                searchesDishes: searchesDishes,
              },
              { merge: true }
            ) //.then(res => console.log('KPI doc updated', res))
        })
      } else {
        db.collection("kpis").add({
          date: now,
          dateFormatted: nowFormatted,
          evaluationsQty: 0,
          evaluationsDishes: [],
          evaluationsRests: [],
          dishesQty: 0,
          restsQty: 0,
          loginsQty: 0,
          usersQty: 0,
          loginsUsers: [],
          searchQty: 1,
          searchesRests: [],
          searchesDishes: [dish],
        }) //.then(res => console.log('KPI doc created ', res))
      }
    })
    .catch(error => {
      console.log("Error getting documents: ", error)
    })
}




