import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ThemeContext } from "../../../constants/index"
import { Colors } from "../../../constants/palette"

import Typo from "../../../0_atoms/Typo/Typo"

const Wrapper = styled.div`
  flex-direction: column;
  animation: 0.51s opening;

  @keyframes opening {
    from {
      display: none;
      opacity: 0;
      height: 0px;
    }
    20% {
      display: flex;
      height: 0px;
    }
    60% {
      height: 190px;
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      height: unset;
    }
  }

  i.fa-user {
    font-size: 72px;
    opacity: 0.5;
    padding: 10px;
    color: ${props => Colors[props.styleMode][props.theme].letterUsual};
    text-align: center;
    display: block;
  }

  .descriptions {
    max-width: 260px;
    margin: auto;
    display: block;
  }

  .change-password {
    width: 200px;
    margin: auto;
    display: block;
  }

  i.fa-pen {
    opacity: 0.5;
    padding: 0px 0px 0px 5px;
    color: ${props => Colors[props.styleMode][props.theme].letterUsual};
  }
`

export default (): JSX.Element => {
  const { styleMode, theme } = useContext(ThemeContext)
  const { t } = useTranslation("common")

  return (
    <Wrapper className="profile-items" styleMode={styleMode} theme={theme}>
      <Typo cardDestak style={{ display: "block" }}>
        {t("configs.profile.title")}
      </Typo>
      <i className="fas fa-user" />
      <Typo className="descriptions" cardText>
        joaoricardofl
      </Typo>
      <Typo className="descriptions" cardText>
        joaoricardofl@gmail.com <i style={{}} className="fas fa-pen" />
      </Typo>
      <Typo className="change-password" simpleLink>{t("configs.profile.changePassword")}</Typo>
    </Wrapper>
  )
}
