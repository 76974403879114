import C from "./constants"
import * as firebase from "firebase"
import { evaluate } from "./evaluate"

export const createRest = (form, createRestOnEvaluation, userData, message, newDish) => {
    return dispatch => {
      console.log('RESTAURANT_SAVING')
      dispatch({ type: C.RESTAURANT_SAVING })
      const restRef = firebase.firestore().collection("restaurants")
      restRef
        .add({
          name: form.rest.name,
          evaluations: 0,
          tags: [],
          grades_abs: {
            taste: 0,
            price: 0,
            time: 0,
            size: 0,
            presentation: 0,
          },
          grades_avg: {
            taste: 0,
            price: 0,
            time: 0,
            size: 0,
            presentation: 0,
          },
        })
        .then(restUid => {
          if (createRestOnEvaluation) {
            let newForm = { ...form }
            newForm.rest.uid = restUid.id
            console.log('RESTAURANT_SAVE_DONE')
            dispatch({ type: C.RESTAURANT_SAVE_DONE, form: newForm })
            dispatch(evaluate(newForm, userData, message, newDish, true))
          } else {
            console.log('RESTAURANT_SAVE_DONE')
            dispatch({
              type: C.RESTAURANT_SAVE_DONE,
              form: { rest: { name: form.rest.name } },
            })
          }
        })
        .catch(error => {
          console.log('RESTAURANT_SAVE_ERROR')
          dispatch({ type: C.RESTAURANT_SAVE_ERROR, error: error })
        })
    }
  }
  