import React, { useContext, ReactElement } from "react"
import styled from "styled-components"
import { Colors } from "../../constants/palette"
import Typo from "../Typo/Typo"
import { useTranslation } from "react-i18next"
import { ThemeContext } from "../../constants/index"

import Favorite from "@material-ui/icons/Favorite"
import Schedule from "@material-ui/icons/Schedule"
import LocalAtm from "@material-ui/icons/LocalAtm"
import Straighten from "@material-ui/icons/Straighten"
import Restaurant from "@material-ui/icons/Restaurant"

const iconStyles: React.CSSProperties = {
  verticalAlign: "top",
  marginTop: "2px",
  fontSize: "12.5px",
}

const typoStyles: React.CSSProperties = {
  paddingTop: "5px",
  letterSpacing: "0.2px",
  fontSize: "10px",
  lineHeight: "9px",
  verticalAlign: "top",
}

const iconComponents = (category: string): ReactElement => {
  switch (category) {
    case "taste":
      return <Favorite style={{ ...iconStyles, color: Colors.taste }} />
    case "price":
      return <LocalAtm style={{ ...iconStyles, color: Colors.price }} />
    case "time":
      return <Schedule style={{ ...iconStyles, color: Colors.time }} />
    case "size":
      return <Straighten style={{ ...iconStyles, color: Colors.size }} />
    case "presentation":
      return (
        <Restaurant style={{ ...iconStyles, color: Colors.presentation }} />
      )
  }
}
const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 5px;
  margin-top: 3px;
  width: 100px;
`

interface PropsGradesSpiderChart {
  category: [string, number]
  reference: number
  displayFocus?: boolean
  displayBasis?: boolean
}

export default (props: PropsGradesSpiderChart): JSX.Element => {
  const { styleMode, theme } = useContext(ThemeContext)
  const { t } = useTranslation("common")

  return (
    <Wrapper>
      <Typo
        dropdownTitle
        style={{
          padding: "0px",
          lineHeight: "9px",
          fontSize: "10px",
          marginLeft: props.category[0] === "presentation" ? "14px" : "blapx",
          color: Colors[styleMode][theme].letterAuxiliary,
          opacity: "unset",
        }}
      >
        {t(props.category[0])}
      </Typo>
      <div style={{ textAlign: "center" }}>
        {iconComponents(props.category[0])}
        <Typo
          dropdownTitle
          style={{
            ...typoStyles,
            color: Colors.mainOrange,
            paddingRight: "0px",
            filter: props.displayFocus ? "none" : "opacity(.5)",
            opacity: "unset",
          }}
        >
          {(Math.round(props.category[1] * 10) / 10).toFixed(1)}{" "}
        </Typo>
        <Typo
          dropdownTitle
          style={{
            ...typoStyles,
            filter: props.displayBasis ? "none" : "opacity(.5)",
            color: Colors[styleMode][theme].letterAuxiliary,
            opacity: "unset",
          }}
        >
          {props.reference !== undefined
            ? "/ " + (Math.round(props.reference * 10) / 10).toFixed(1)
            : ""}{" "}
        </Typo>
      </div>
    </Wrapper>
  )
}
