import React, { useRef, useEffect, useState, useContext } from "react"
import styled from "styled-components"
import L from "leaflet"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import "../../../../node_modules/leaflet/dist/leaflet.css"
import { gsap, TweenMax } from "gsap"

import { Colors, Shadows } from "../../constants/palette"
import { usePrevious } from "../../utils/misc"
import { ThemeContext } from "../../constants/index"

import PopupContent2 from "./Popup"

const Wrapper = styled.div.attrs(props => ({
  className: "map-DishFound " + props.styleMode + props.theme,
}))`
  //margin-top: 15px;
  height: 300px;
  box-shadow: ${props => Shadows[props.styleMode][props.theme].Lm5upin0};

  .leaflet-container {
    background: ${props => (props.theme === "dark" ? "#333333" : "#f0f0f0")};
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    color: ${props => Colors[props.styleMode][props.theme].letterUsual};
    background-color: ${props => Colors[props.styleMode][props.theme].L15};
  }
  .leaflet-touch .leaflet-bar {
    border: 1px solid
      ${props => Colors[props.styleMode][props.theme].letterAuxiliary}80;
  }
  .leaflet-control-zoom-in.leaflet-disabled,
  .leaflet-control-zoom-out.leaflet-disabled {
    background-color: ${props => Colors[props.styleMode][props.theme].L15};
  }

  div.leaflet-popup-content-wrapper {
    background-color: ${props => Colors[props.styleMode][props.theme].L10};
    div.leaflet-popup-content > div > div {
      color: ${props => Colors[props.styleMode][props.theme].letterUsual};
    }
  }
  div.leaflet-popup-tip-container > div.leaflet-popup-tip {
    background: ${props => Colors[props.styleMode][props.theme].L10};
  }

  .popupSelected {
    bottom: 4px !important;
  }
`

const defaultCharcteristics = {
  shadowSize: [41, 41],
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  shadowAnchor: [12, 41],
  popupAnchor: [0, -35],
}

let defaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  ...defaultCharcteristics,
  //25 41
})

// interface PropsMaps {
//   dish: {
//     restaurants: any
//   }
//   restSelected?: any
// }

const MapStyled = props => {
  const themeStyle = useContext(ThemeContext)
  const mapRef = useRef()
  const markersRef = useRef([])
  const [tweenMarker] = useState(gsap.timeline({ paused: true }))
  const prevRestSelected = usePrevious(props.restSelected)

  const increaseMarker = (tween, selector) =>
    TweenMax.to(selector, 0.3, {
      width: 32,
      height: 53,
      top: -11,
      left: -4,
      filter: "contrast(4.05)sepia(1)hue-rotate(350deg)",
    }) // #0084c6

  const decreaseMarker = (tween, selector) =>
    TweenMax.to(selector, 0.3, {
      width: 25,
      height: 41,
      top: 0,
      left: 0,
      filter: "unset",
    })

  useEffect(() => {
    const map = mapRef.current
    if (map != null) {
      setTimeout(() => {
        let restaurants = props.dish.restaurants,
          points = []

        restaurants.map(e =>
          points.push([e.location.latitude, e.location.longitude])
        )
        if (points.length > 0) {
          var bounds = new L.LatLngBounds(points)
          map.leafletElement.invalidateSize()
          map.leafletElement.fitBounds(bounds, {
            padding: [50, 50],
            maxZoom: 16,
          })
          if (props.restSelected !== undefined)
            increaseMarker(
              tweenMarker,
              ".leaflet-marker-icon:nth-of-type(" +
                (props.restSelected + 1) +
                ")"
            )
          if (markersRef.current.length === 1)
            markersRef.current[0].leafletElement.openPopup()
        }
      }, 1500)
    }
  }, [mapRef.current])

  useEffect(() => {
    const map = mapRef.current
    if (map != null) {
      if (prevRestSelected !== undefined) {
        increaseMarker(
          tweenMarker,
          ".leaflet-marker-icon:nth-of-type(" + (props.restSelected + 1) + ")"
        )
        let selectedRest = props.dish.restaurants[props.restSelected]
        map.leafletElement.panTo(
          new L.LatLng(
            selectedRest.location.latitude,
            selectedRest.location.longitude
          )
        )
        decreaseMarker(
          tweenMarker,
          ".leaflet-marker-icon:nth-of-type(" + (prevRestSelected + 1) + ")"
        )
      }
    }
  }, [props.restSelected])

  useEffect(() => {
    const map = mapRef.current
    if (map != null) {
      // setTimeout(() => {
      let restaurants = props.dish.restaurants,
        points = []

      restaurants.map(e =>
        points.push([e.location.latitude, e.location.longitude])
      )
      var bounds = new L.LatLngBounds(points)
      map.leafletElement.invalidateSize()
      if (props.dish.restaurants.length > 0)
        map.leafletElement.fitBounds(bounds, {
          padding: [50, 50],
          maxZoom: 14,
        })
      if (props.restSelected !== undefined)
        increaseMarker(
          tweenMarker,
          ".leaflet-marker-icon:nth-of-type(" + (props.restSelected + 1) + ")"
        )
      if (markersRef.current.length === 1)
        markersRef.current[0].leafletElement.openPopup()
      // }, 1500)
    }
  }, [mapRef.current, JSON.stringify(props.dish.restaurants)])

  useEffect(() => {
    markersRef.current = new Array(props.dish.restaurants.length)
  }, [])

  return (
    <Wrapper {...themeStyle}>
      <Map
        className={"mapComponent"}
        center={[0, 0]}
        zoom={1}
        maxZoom={18}
        style={{
          // width: closedInterval(props.widthCard - 84,236, 366) + "px",
          height: "296px",
          // display: "none",
          margin: "auto",
          borderRadius: "5px",
        }}
        ref={mapRef}
      >
        <TileLayer
          url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {props.dish.restaurants.map((e, i) => (
          <Marker
            key={i}
            position={[e.location.latitude, e.location.longitude]}
            icon={defaultIcon}
            ref={el => (markersRef.current[i] = el)}
          >
            <Popup
              autoPanPaddingTopLeft={[0, 75]}
              className={i === props.restSelected ? "popupSelected" : ""}
              openPopUp
            >
              <PopupContent2 {...props} theme={"light"} item={e} />
            </Popup>
          </Marker>
        ))}
      </Map>
    </Wrapper>
  )
}

export default MapStyled

// https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
// https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png
// https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png
// https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png

// dark
// https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png
