import React, { useContext } from "react"
import propTypes from "prop-types"
import { connect } from "react-redux"

import {
  PropsModesWrappers,
  HashValuesContext,
  PropsHashes,
} from "../../constants/index"
import { clickLogout } from "../../../actions/user"
import { settingHashParameters, getHashChanges } from "../../utils/urlParams"

import DropdownList from "./dropdownList"

const checkType = state => (typeof state === "string" ? state : "none")

interface PropsDropdownListWrapper {
  state: any
  dropdownList: Array<{
    item: string
    href: null | string
    hashParameter?: any
  }>
  dispatch: any
  setShow: Function
}

const DropdownListWrapper: React.FC<PropsDropdownListWrapper> = props => {
  const actionFromObject = (e: string): void => {
    switch (e) {
      case "dropdownList.personal.editProfile":
        settingHashParameters({
          configurations: "profile",
        })
        break

      case "dropdownList.personal.myEvaluations":
        settingHashParameters({
          configurations: "myEvaluations",
        })
        break

      case "dropdownList.personal.settings":
        settingHashParameters({
          configurations: "settings",
        })
        break

      case "dropdownList.admin.aboutHelp":
        settingHashParameters({ configurations: "about" })
        break

      case "dropdownList.personal.logout":
        props.dispatch(
          clickLogout({
            theme: props.state.userFetch.theme,
            style: props.state.userFetch.style,
            language: props.state.userFetch.language,
          })
        )
        break

      case "dropdownList.admin.settings":
        settingHashParameters({
          configurations: "appSettings",
        })
        break

      case "dropdownList.admin.dataApp":
        settingHashParameters({
          configurations: "dataApp",
        })
        break

      default:
        console.log("action not defined yet")
    }
  }

  return <DropdownList {...props} actionFromObject={actionFromObject} />
}

DropdownListWrapper.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(DropdownListWrapper)
