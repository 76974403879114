import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { InstantSearch, Configure } from "react-instantsearch/dom"
import algoliasearch from "algoliasearch/lite"
import { useTranslation } from "react-i18next"
import { gsap, TweenMax } from "gsap"
import { tweenConfigurationsPartAppear } from "../../../utils/animations"

import { HashValuesContext } from "../../../constants/index"

import Typo from "../../../0_atoms/Typo/Typo"
import SearchBox from "./wrapperSearchBox"
import Hits from "./wrapperHits"

const ComponentsWrapper = styled.div`
  display: none;
  flex-direction: column;
  opacity: 0;
  height: 0px;
`

const searchClient = algoliasearch(
  "IF3U0V4NTS",
  "6a799c8787e6e0f3b4bcb939f74962f4"
)

export default (): JSX.Element => {
  const { t } = useTranslation("common")
  const hashValues = useContext(HashValuesContext)
  const [restName, setRestName] = useState("")
  const [tweenEvaluateDish]: any = useState(gsap.timeline({ paused: true }))
  const TweenMaxTyped: any = TweenMax

  useEffect(() => {
    tweenConfigurationsPartAppear(
      tweenEvaluateDish,
      ".evaluate-dish-items",
      215
    )
    TweenMaxTyped.delayedCall(1, () => tweenEvaluateDish.reversed(false))
    return () =>
      TweenMaxTyped.delayedCall(0.25, () => tweenEvaluateDish.reversed(true))
  }, [])

  useEffect(() => {
    if (hashValues.selected !== "" && hashValues.selected !== "none") {
      setRestName(JSON.parse(decodeURIComponent(hashValues.selected)).restName)
    }
  }, [JSON.stringify(hashValues)])

  return (
    <ComponentsWrapper className="evaluate-dish-items">
      <Typo style={{ padding: "10px 20px xpx" }} cardText>
        {t("atTheRestaurant")}
      </Typo>
      <Typo style={{ width: "100%" }} title={"Domino's"} button>
        {restName}
      </Typo>

      <InstantSearch
        indexName={"dishes"} //props.indexName
        searchClient={searchClient}
      >
        <SearchBox />
        <Configure hitsPerPage={20} />
        <Hits />
      </InstantSearch>
    </ComponentsWrapper>
  )
}
