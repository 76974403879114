import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import { gsap, TweenMax } from "gsap"
import styled from "styled-components"

import { ThemeContext } from "../../../constants/index"
import {
  evaluationsToGradesDishesRestaurants,
  evaluationsToRestaurantsGradesDishes,
  evaluationsToDishesGradesRestaurants,
} from "../../../utils/parseJson"
import { Colors } from "../../../constants/palette"
import { closedInterval, handleStates } from "../../../utils/misc"
import { PropsEvaluations } from "../../../utils/data/utilData"
import { toggleAnimation, tweenOpacityShadow } from "../../../utils/animations"

import WordCloud from "../../../0_atoms/Charts/WordCloud"
import Typo from "../../../0_atoms/Typo/Typo"
import ZoomableSunburst from "../../../0_atoms/Charts/ZoomableSunburst"
import InfiniteScrollEvaluations from "../../../1_molecules/InfiniteScroll/InfiniteScrollEvaluations"

const WrapperInfiniteScroll = styled.div`
  margin: 0px 20px 0px;
`

const Wrapper = styled.div`
  flex-direction: column;
  animation: 0.51s opening;

  @keyframes opening {
    from {
      display: none;
      opacity: 0;
      height: 0px;
    }
    20% {
      display: flex;
      height: 0px;
    }
    60% {
      height: 730px;
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      height: unset;
    }
  }

  .chart-title {
    float: left;
    text-align: left;
    display: block;
    width: 120px;
  }

  .chart-main-value {
    margin: auto;
    text-align: left;
    display: block;
    font-size: 24px;
    padding: 6px 4px;
  }

  .chart-wrapper {
    margin-left: 30px;
    padding: 4px 0px;
    opacity: 0;
    height: 0;
    display: none;
  }

  .evaluations-list {
    maxwidth: 260px;
    margin: auto;
    display: block;
    margin-top: 10px;
    font-weight: 500;
  }

  .cloud-title {
    max-width: 260px;
    margin: auto;
    display: block;
    margin-top: 10px;
    fontweight: 500;
  }

  i.fa-angle-down {
    font-size: 14px;
    opacity: 0.5;
    margin-right: 20px;
    padding-top: 6px;
    float: right;
    cursor: pointer;
    color: ${props => Colors[props.styleMode][props.theme].letterUsual};
  }
`

const options: Array<{
  label: string
  dataOrder: string
  function: Function
}> = [
  {
    label: "restaurants",
    dataOrder: "Restaurants/Grades/Dishes",
    function: evaluationsToRestaurantsGradesDishes,
  },
  {
    label: "dishes",
    dataOrder: "Dishes/Grades/Restaurants",
    function: evaluationsToDishesGradesRestaurants,
  },
  {
    label: "evaluations",
    dataOrder: "Grades/Dishes/Restaurants",
    function: evaluationsToGradesDishesRestaurants,
  },
]

interface PropsMyEvaluations {
  widthCard: number
  data: null | Array<PropsEvaluations>
}

export default (props: PropsMyEvaluations): JSX.Element => {
  const [states, setStates] = useState(new Array(options.length).fill(false))
  const { styleMode, theme } = useContext(ThemeContext)
  const { t } = useTranslation("common")

  const [tweenEvaluations] = useState(gsap.timeline({ paused: true }))
  const [tweenDishes] = useState(gsap.timeline({ paused: true }))
  const [tweenRestaurants] = useState(gsap.timeline({ paused: true }))

  const [tweenScrollEvaluations] = useState(gsap.timeline({ paused: true }))

  useEffect(() => {
    let height = closedInterval(props.widthCard - 40, 280, 410)
    toggleAnimation(tweenEvaluations, "evaluations", height)
    toggleAnimation(tweenDishes, "dishes", height)
    toggleAnimation(tweenRestaurants, "restaurants", height)
  }, [props.widthCard])

  useEffect(() => {
    tweenRestaurants.reversed(!states[0])
    tweenDishes.reversed(!states[1])
    tweenEvaluations.reversed(!states[2])
  }, [JSON.stringify(states)])

  useEffect(() => {
    tweenOpacityShadow(
      tweenScrollEvaluations,
      ".infinite-scroll-evaluations-0.",
      styleMode,
      theme,
      "Lm5upin1",
      0.3
    )
    tweenScrollEvaluations.reversed(false)

    TweenMax.to(".evaluation", 0.3, {
      opacity: 1,
      autoAlpha: 1,
      display: "flex",
    })
  }, [])

  return (
    <Wrapper
      className="my-evaluations-items"
      styleMode={styleMode}
      theme={theme}
    >
      <Typo cardDestak style={{ display: "block" }}>
        {t("configs.myEvaluations.title")}
      </Typo>

      {/* Charts */}
      {options.map((e, i) => (
        <div key={i} style={{ marginLeft: "30px", padding: "4px 0px" }}>
          <div style={{ height: "23px" }}>
            <Typo className="chart-title" cardText>
              {t(e.label)}
            </Typo>
            <i
              className={"fas fa-angle-down myEvaluations-i-" + e.label}
              onClick={() => handleStates(!states[i], i, states, setStates)}
            />
          </div>
          <Typo className="chart-main-value" cardText>
            {props.data === null
              ? null
              : i === 2
              ? props.data.length
              : e.function(props.data).children.length}
          </Typo>
          <div className={"chart-wrapper myEvaluations-" + e.label}>
            {props.data !== null && (
              <ZoomableSunburst
                data={props.data === null ? null : e.function(props.data)}
                style={{ left: "-32px", marginTop: "2px" }}
                dataOrder={e.dataOrder}
                widthCard={props.widthCard}
              />
            )}
          </div>
        </div>
      ))}

      {/* Evaluations list */}
      <Typo className="evaluations-list" cardText>
        {t("configs.myEvaluations.myEvaluationsList")}
      </Typo>

      <WrapperInfiniteScroll>
        {props.data !== null && (
          <InfiniteScrollEvaluations
            evaluations={props.data.sort((a, b) =>
              a.creationTime < b.creationTime ? 1 : -1
            )}
          />
        )}
      </WrapperInfiniteScroll>

      {/* Word cloud  */}
      <Typo className="cloud-title" cardText {...props}>
        {t("configs.myEvaluations.mainTerms")}
      </Typo>
      {props.data !== null && (
        <WordCloud data={props.data} widthCard={props.widthCard} />
      )}
    </Wrapper>
  )
}
