import C from "../actions/constants"

export const selectedDish = (
    state = { fetching: null, dishDoc: null },
    action
  ) => {
    switch (action.type) {
      case C.UNSELECTED_DISH:
        return Object.assign({}, state, { ...action.payload })
      case C.SELECTED_DISH:
        return Object.assign({}, state, { ...action.payload })
      case C.DISH_FETCHED:
        return Object.assign({}, state, { ...action.payload })
      case C.RESTS_DISH_FETCHING:
        return Object.assign({}, state, { ...action.payload })
      case C.RESTS_DISH_FETCHED:
        return Object.assign({}, state, { ...action.payload })

      default:
        return state
    }
  }