import React, { ReactElement } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { Colors } from "../../constants/palette"
import { PropsGrades } from "../../utils/data/utilData"

import Typo from "../Typo/Typo"
import Favorite from "@material-ui/icons/Favorite"
import Schedule from "@material-ui/icons/Schedule"
import LocalAtm from "@material-ui/icons/LocalAtm"
import Straighten from "@material-ui/icons/Straighten"
import Restaurant from "@material-ui/icons/Restaurant"

const iconStyles: React.CSSProperties = {
  verticalAlign: "top",
  marginTop: "4.5px",
  fontSize: "14.5px",
}

const iconComponents: Array<ReactElement> = [
  <Favorite style={{ ...iconStyles, color: Colors.taste }} />,
  <LocalAtm style={{ ...iconStyles, color: Colors.price }} />,
  <Schedule style={{ ...iconStyles, color: Colors.time }} />,
  <Straighten style={{ ...iconStyles, color: Colors.size }} />,
  <Restaurant style={{ ...iconStyles, color: Colors.presentation }} />,
]

const WrapperAllGrades = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: 5px;
  margin-top: 5px;
  min-width: 210px;
`

const WrapperGrades = styled.div`
  margin: auto;
`

interface PropsGradesComponent {
  grades_avg: any
}

const categories = ["taste", "price", "time", "size", "presentation"]

export default (props: PropsGradesComponent): JSX.Element => {
  const { t } = useTranslation("common")

  return (
    <WrapperAllGrades>
      {categories.map((key, idx) => (
        <WrapperGrades key={idx} title={t(key)}>
          {iconComponents[idx]}
          <Typo
            style={{
              marginLeft: "0px",
              fontSize: "11px",
              paddingTop: "0px",
              opacity: 0.5,
            }}
          >
            {(Math.round(props.grades_avg[key] * 10) / 10).toFixed(1)}
          </Typo>
        </WrapperGrades>
      ))}
    </WrapperAllGrades>
  )
}
