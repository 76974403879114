import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import * as _ from "lodash"

import { Colors } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"

import Typo from "../Typo/Typo"

const ItemRow = styled.div`
  height: 25px;
  display: flex;
  align-content: center;
  justify-content: space-between;

  color: ${props => Colors[props.styleMode][props.theme].letterUsual};

  border-bottom: 1px solid #b1afaf;
`

const Wrapper = styled.div`
  height: 40px;
  width: min-content;

  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 7px;
  padding-bottom: 7px;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`

interface PropsSelect {
  items: Array<{ name: string }>
  setItem: Function
}

export default (props: PropsSelect): JSX.Element => {
  const [randomName] = useState(() => Math.random())
  const [scrollState, setScrollState] = useState(0)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation("common")

  useEffect(() => {
    var elements = document.getElementsByClassName(randomName + "")
    Array.from(elements).forEach(e => {
      e.addEventListener(
        "scroll",
        _.throttle(() => setScrollState(e.scrollTop), 100)
      )
    })
  }, [])

  useEffect(() => props.setItem(scrollState), [scrollState])

  return (
    <Wrapper className={"divSlot " + randomName}>
      {props.items.map((f, j) => (
        <ItemRow key={j} {...theme} className={j === 0 ? "first-item" : ""}>
          <Typo
            title={f.name}
            style={{
              float: "left",
              whiteSpace: "nowrap",
              zIndex: 5,
              transform: "rotateX(" + (4 * scrollState - 90 * j) + "deg)",
              cursor: "unset",
            }}
            nameDish
          >
            {t(f.name)}
          </Typo>
        </ItemRow>
      ))}
    </Wrapper>
  )
}
