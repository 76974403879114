import { Colors, Shadows } from "../constants/palette"

export const tweenEffect = (tween, className, height) =>
  tween
    .to(className, 0.3, { height: height, display: "flex" }, "scene1")
    .to(className, 0.3, { opacity: 1, autoAlpha: 1 })
    .reverse()

// Height and opacity (tweenHeightOpacity)

export const openCaretTween = (tween, classNamePreffix, classNameSuffix) => {
  tween
    .to(
      classNamePreffix + classNameSuffix,
      0.3,
      { height: "310px", display: "block" },
      "scene1"
    )
    .to(
      classNamePreffix + "-i" + classNameSuffix,
      0.3,
      { paddingTop: "0px", rotation: "-180" }, //{ marginTop: "2.9px", rotation: "-180" },
      "scene1"
    )
    .to(classNamePreffix + classNameSuffix, 0.3, { opacity: 1, autoAlpha: 1 })
    .reverse()
}

export const tweenEffects = (
  tween,
  classNamePreffix,
  classNameSuffix,
  propsInput
) => {
  let classItem,
    classNameInfinite,
    shadow = Shadows[propsInput.styleMode][propsInput.theme].Lm5upin1,
    classNameComplement = "." + propsInput.styleMode + propsInput.theme
  switch (classNameSuffix) {
    case "-restaurants":
    case "-dishes":
      classItem = ".itemDescription"
      classNameInfinite = ".infinite-scroll-dishes-0"
      break
    case "-evaluationsDishRestaurant":
    case "-evaluationsDishesRestaurant":
      classItem = ".evaluation"
      classNameInfinite = ".infinite-scroll-evaluations-0"
      break
    case "-map":
      classItem = ".mapComponent"
      classNameInfinite = ".map-DishFound"
      shadow = Shadows[propsInput.styleMode][propsInput.theme].Lm5upin2
      break
    default:
      break
  }
  classNameInfinite += classNameComplement

  return tween
    .to(
      classNamePreffix + classNameSuffix,
      0.3,
      { height: "315px", display: "block" },
      "scene1"
    )
    .to(
      classNamePreffix + "-i" + classNameSuffix,
      0.3,
      { marginTop: "6px", rotation: "-180" },
      "scene1"
    )
    .to(classNameInfinite, 0.3, { opacity: 1, autoAlpha: 1 })
    .to(classNameInfinite, 0.3, { boxShadow: shadow })
    .to(classItem, 0.3, { opacity: 1, autoAlpha: 1, display: "flex" })
    .reverse()
}

export const tweenOpacityShadow = (
  tween,
  className,
  styleMode,
  theme,
  shadow,
  delay
) =>
  tween
    .to(className + styleMode + theme, 0.3, {
      opacity: 1,
      autoAlpha: 1,
    })
    .to(className + styleMode + theme, 0.3, {
      boxShadow: Shadows[styleMode][theme][shadow],
    })
    .delay(delay)
    .reverse()

export const tweenOpacityShadowOpacity = (
  tween,
  ref1,
  ref2,
  styleMode,
  theme,
  shadow
) =>
  tween
    .to(ref1 + styleMode + theme, 0.3, {
      opacity: 1,
      autoAlpha: 1,
    })
    .to(ref1 + styleMode + theme, 0.3, {
      boxShadow: Shadows[styleMode][theme][shadow],
    })
    .to(ref2, 0.3, {
      opacity: 1,
      autoAlpha: 1,
      display: "flex",
    })
    .reverse()

// Height and Rotation

export const toggleAnimation = (tween, className, height) =>
  tween
    .to(
      ".myEvaluations-" + className,
      0.3,
      { height: height, display: "block" },
      "scene1"
    )
    .to(
      ".myEvaluations-i-" + className,
      0.3,
      { marginTop: "6px", rotation: "-180" },
      "scene1"
    )
    .to(".myEvaluations-" + className, 0.3, { opacity: 1, autoAlpha: 1 })
    .reverse()

// Opacity

export const tweenDisappearAppear = (
  tween,
  classNameDisappear,
  classNameAppear
) =>
  tween
    .to(classNameDisappear, 0.3, {
      opacity: 0,
      autoAlpha: 1,
      display: "none",
    })
    .to(classNameAppear, 0.3, { opacity: 1, autoAlpha: 1, display: "flex" })

export const tweenConfigurationsPartAppear = (tween, ref, height) =>
  tween
    .to(ref, 0.1, { display: "flex" })
    .to(ref, 0.2, { height: height })
    .to(ref, 0.2, { opacity: 1 })
    .to(ref, 0.01, { height: "unset" })
    .reverse()
