import * as firebase from "firebase"

import C from "./constants"

export const fetchingEvaluations = (dishUid, restUid) => {
  return dispatch => {
    if (dishUid === null && restUid === null) {
      console.log("EVALS_NULLING")
      dispatch({
        type: C.EVALS_NULLING,
        payload: { evaluations: null },
      })
    } else {
      console.log("EVALS_FETCHING")
      dispatch({
        type: C.EVALS_FETCHING,
        payload: null,
      })
      const evaluations = []
      console.log('fetchingEvaluations', restUid, dishUid)
      firebase
        .firestore()
        .collection("evaluations")
        .where("dish.uid", "==", dishUid)
        .where("rest.uid", "==", restUid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => evaluations.push(doc.data()))
          console.log("EVALS_FETCHED")
          dispatch({
            type: C.EVALS_FETCHED,
            payload: { evaluations },
          })
        })
    }
  }
}
