import React, { useRef, useContext, useState } from "react"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import * as _ from "lodash"

import { Colors, Shadows } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"

import CellSelect from "./CellSelect"

const Wrapper = styled.div`
  display: flex;

  .movement-indicator{
    display: flex;
    flex-direction: column;
    margin-left: 3px;

    i{
      color: ${props => Colors[props.styleMode][props.theme].letterUsual};
      opacity: .6;
    }
  }
`

const WrapperSlot = styled.div`
  height: 40px;
  border: 1px solid #b1afaf;
  border-radius: 5px;
  display: flex;
  flex-flow: row;

  ${props => Shadows[props.styleMode][props.theme].Lm5upin}
  background-color: ${props =>
    props.styleMode === "new"
      ? "none"
      : Colors[props.styleMode][props.theme].L10};

  .first-item {
    border-top: 1px solid #b1afaf;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    props.styleMode === "new" &&
    css`
      border: none;
    `}

  .divSlot {
    height: 40px;

    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 7px;
    padding-bottom: 7px;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .divSlot:nth-child(2) {
    border-right: 1px solid #b1afaf;
    border-left: 1px solid #b1afaf;
  }
`

interface PropsTripleSelect {
  items: Array<Array<{ name: string }>>
  setStates: Function
}

export default (props: PropsTripleSelect): JSX.Element => {
  const theme = useContext(ThemeContext)
  const [selectState, setSelectState] = useState([0, 0, 0])
  const { t } = useTranslation("common")
  const ref = useRef()

  const changeValue = (value, index) => {
    let updateSelect = selectState
    updateSelect[index] = value > 15 ? 1 : 0
    setSelectState(updateSelect)
    props.setStates(JSON.stringify(updateSelect))
  }

  return (
    <Wrapper {...theme}>
      <WrapperSlot ref={ref} {...theme}>
        {props.items.map((e, i) => (
          <CellSelect key={i} items={e} setItem={e => changeValue(e, i)} />
        ))}
      </WrapperSlot>
      <div className="movement-indicator">
        <i className="fas fa-caret-up" />
        <i className="fas fa-caret-down" />
      </div>
    </Wrapper>
  )
}
