import React, { useContext } from "react"
import styled from "styled-components"
import Button from "../../0_atoms/Button/Button"
import { Colors, Shadows } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  a {
    display: block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    width: fit-content;

    box-shadow: ${props => Shadows[props.styleMode][props.theme].dropdownShadow};
    background-color: ${props => Colors[props.styleMode][props.theme].L15};
    padding: 8px 14px;
    z-index: 20;
    border-radius: 10px;
    margin-top: ${props => (props.styleMode === "classic" ? "3px" : "11px")};
    left: ${props => props.leftDropdown}px;
    text-align: center;
  }

  .dropdown:hover {
    filter: invert(0.5);
  }

  .dropdown-title {
    cursor: default;
    filter: invert(0);
  }

  .dropdown-content {
    display: ${props => (props.show ? "block" : "none")};
  }
`

interface PropsDropdown {
  buttonLabel: JSX.Element | string
  dropdownContent?: any
  onClickDropdown: Function
  leftDropdown: number
  pressed?: boolean
}

export default (props:PropsDropdown) : JSX.Element => {
  const { onClickDropdown } = props
  const theme = useContext(ThemeContext)

  return (
    <Dropdown
      {...theme}
      leftDropdown={props.leftDropdown}
      show={props.pressed}
    >
      <Button
        onClick={onClickDropdown}
        modalButton
      >
        {props.buttonLabel}
      </Button>
      <nav className={"dropdown-content"}>{props.dropdownContent}</nav>
    </Dropdown>
  )
}
