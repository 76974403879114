import C from "../actions/constants"

const appsConfigsInitial = {
  addDishes: true,
  addRests: true,
  isInPilotMode: false,
  pilotMessage: "",
  savingAppConfigs: null,
  errorSavingAppConfigs: null,
  tags: []
}

export const appConfigs = (state = appsConfigsInitial, action) => {
  switch (action.type) {
    
    // Config fetching on detecting user
    case C.CONFIG_FETCHED:
      return Object.assign({}, state, { ...action.payload })

    // Changes being saved on configs
      case C.CONFIG_SAVING:
        return Object.assign({}, state, { ...action.payload })
      case C.CONFIG_SAVE_DONE:
        return Object.assign({}, state, { ...action.payload })
      case C.CONFIG_SAVE_ERROR:
        return Object.assign({}, state, { ...action.payload })

    default:
      return state
  }
}