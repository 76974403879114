import React from "react"
import propTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { clickLogin, forgetEmail } from "../../../actions/user"

import DropdownLogin from "./Login"

interface PropsWrapperLogin{
  dispatch?: any
  state?: any
  setIsLogging: Function
}

const DropdownLoginWrapper: React.FC<PropsWrapperLogin> = props => {
  const { t } = useTranslation("common")

  const handleLogin = (email, password) => {
    if (email === "" || password === "")
      return alert(t("login.noData"))
    props.setIsLogging(false)
    props.dispatch(clickLogin(email, password, props.state.userFetch))
  }

  const handleForget = email => {
    if (email === "") return alert(t("login.noEmail"))
    props.dispatch(forgetEmail(email, props.state.userFetch))
  }

  return (
    <DropdownLogin
      {...props}
      handleLogin={handleLogin}
      handleForget={handleForget}
    />
  )
}

DropdownLoginWrapper.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(DropdownLoginWrapper)
