import { useEffect, useRef } from "react"
import * as _ from "lodash"

export const randomNumbers = (max, decimals) =>
  (Math.random() * max).toFixed(decimals)

export const singularOrPlural = (number, singular, plural) =>
  number < 2 ? singular : plural

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const closedInterval = (value, min, max) =>
  isNaN(value) ? min : value > max ? max : value < min ? min : value

export const handleAction = (e, action) =>
  action(parseInt(e.target.value) === 1 ? true : false)

export const handleStates = (f, i, states, setStates) => {
  let arrayStates = _.clone(states)
  arrayStates[i] = f
  setStates(arrayStates)
}
