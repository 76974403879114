import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import * as d3 from "d3"
import * as cloud from "d3-cloud"

import { Colors } from "../../constants/palette"
import { closedInterval } from "../../utils/misc"
import { PropsEvaluations, PropsGrades } from "../../utils/data/utilData"

const Wrapper = styled.div`
position: relative;

margin-bottom: 10px;
padding-right: 10px;
padding-left: 10px
margin: auto;
height: 160px;
`

const areas: Array<string> = ["taste", "price", "size", "time", "presentation"]
const types: Array<string> = ["dish", "rest"]

interface PropsWords {
  uid: string
  text: string
  times: number
  type: string
  grades: PropsGrades
  size?: number
  highestGrade?: string
}

const fillWordWeight = (type: string, data: PropsEvaluations): PropsWords => {
  return {
    uid: data[type].uid,
    text: data[type].name,
    times: 1,
    type: type,
    grades: {
      taste: data.grades.taste,
      size: data.grades.size,
      time: data.grades.time,
      presentation: data.grades.presentation,
      price: data.grades.price,
    },
  }
}

const fillWordTag = (tag: string, data: PropsEvaluations): PropsWords => {
  return {
    uid: tag,
    text: tag,
    times: 1,
    type: "tag",
    grades: {
      taste: data.grades.taste,
      size: data.grades.size,
      time: data.grades.time,
      presentation: data.grades.presentation,
      price: data.grades.price,
    },
  }
}

const checkingWordAndFilling = (
  wordsWeight: Array<PropsWords>,
  evaluation: PropsEvaluations,
  type: string,
  areas: Array<string>,
  tag: string
): void => {
  let evaluationIndex: number = wordsWeight.findIndex(
    g => g.uid === (type === "tag" ? tag : evaluation[type].uid)
  )

  if (evaluationIndex > -1) {
    wordsWeight[evaluationIndex].times = wordsWeight[evaluationIndex].times + 1
    areas.map(g => {
      wordsWeight[evaluationIndex].grades[g] =
        wordsWeight[evaluationIndex].grades[g] + evaluation.grades[g]
    })
  } else {
    wordsWeight.push(
      type === "tag"
        ? fillWordTag(tag, evaluation)
        : fillWordWeight(type, evaluation)
    )
  }
}

const countWordsWeights = (
  evaluations: Array<PropsEvaluations>
): Array<PropsWords> => {
  let wordsWeight: Array<PropsWords> = []
  types.map(e => {
    wordsWeight.push(fillWordWeight(e, evaluations[0]))

    if (evaluations[0].tags[0] !== undefined) {
      evaluations[0].tags.map(f => {
        checkingWordAndFilling(wordsWeight, evaluations[0], "tag", areas, f)
      })
    }
  })

  evaluations.map((e, i) => {
    if (i > 0) {
      types.map(f => {
        checkingWordAndFilling(wordsWeight, e, f, areas, null)
        if (e.tags[0] !== undefined) {
          e.tags.map(h => {
            checkingWordAndFilling(wordsWeight, e, "tag", areas, h)
          })
        }
      })
    }
  })
  return wordsWeight
}

const findHighestGrade = (grades: PropsGrades): string =>
  Object.keys(grades).reduce((a, b) => (grades[a] > grades[b] ? a : b))

interface PropsWordCloud {
  data: Array<PropsEvaluations>
  widthCard?: number
}

const Chart: React.FC<PropsWordCloud> = props => {
  const ref = useRef()

  useEffect(() => {
    if (props.data.length > 0) {
      let wordsWeight = countWordsWeights(props.data)
      let extent = d3.extent(wordsWeight, d => d.times)

      wordsWeight.map(e => {
        e.highestGrade =
          e.grades[findHighestGrade(e.grades)] === e.grades.taste
            ? "taste"
            : findHighestGrade(e.grades)
        e.size = 8 + (15 * e.times) / extent[1]
      })

      var layout = cloud()
        .size([closedInterval(props.widthCard - 70, 250, 380), 150])
        .words(wordsWeight)
        .padding(2)
        .rotate(() => ~~0) //(Math.random() * 2) * 90)
        .font("Montserrat")
        .fontSize(d => d.size)
        .on("end", draw)

      layout.start()

    }

    function draw(wordsWeight) {
      d3.select(ref.current).selectAll("*").remove()

      // let filterSvg = d3
      //   .select(ref.current)
      //   .append("defs")
      //   .append("filter")
      //   .attr("id", "glow")
      //   .attr("x", "-30%")
      //   .attr("y", "-30%")
      //   .attr("width", "160%")
      //   .attr("height", "160%")

      // filterSvg
      //   .append("feGaussianBlur")
      //   .attr("stdDeviation", "10 10")
      //   .attr("result", "glow")

      // let filterSvgMerge = filterSvg.append("feMerge")

      // filterSvgMerge.append("feMergeNode").attr("in", "glow")
      // filterSvgMerge.append("feMergeNode").attr("in", "glow")
      // filterSvgMerge.append("feMergeNode").attr("in", "glow")

      // let filterSvg = d3.select(ref.current).append("defs").append("filter").attr("id", "glow")
      //         .attr("x", "-20%")
      //   .attr("y", "-20%")
      //   .attr("width", "140%")
      //   .attr("height", "140%").append("feGaussianBlur").attr("stdDeviation", "2 2")

      d3.select(ref.current)
        .append("svg")
        .attr("width", layout.size()[0])
        .attr("height", layout.size()[1])
        .append("g")
        .attr(
          "transform",
          "translate(" +
            layout.size()[0] / 2 +
            "," +
            layout.size()[1] / 2 +
            ")"
        )
        .selectAll("text")
        .data(wordsWeight)
        .enter()
        .append("text")
        // .on("mouseover", function (d) {
        //   console.log("this", this)
        //   //d3.select(this).style("fill", "blue")
        //   d3.select(this).style("filter", "url(#shadow)").style("fill", "#0c9")
        // })
        // .on("mouseout", function (d) {
        //   d3.select(this).style("fill", "black")
        // })
        .style("font-size", d => d.size + "px")
        .style("font-family", "Montserrat")
        .style("fill", d => Colors[d.highestGrade])
        .attr("text-anchor", "middle")
        .attr(
          "transform",
          d => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")"
        )
        .text(d => d.text)
    }
  }, [props.widthCard, props.data.length])

  return (
    <Wrapper>
      <div ref={ref} />
    </Wrapper>
  )
}

export default Chart
