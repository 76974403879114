import i18next from "i18next"
import common_en from "./en/common.json"
import common_br from "./pt_br/common.json"

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "br", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    br: {
      common: common_br,
    },
  },
})

export default i18next