import React, { useContext, useRef, useEffect, useState } from "react"
import styled from "styled-components"

import { ThemeContext } from "../../constants/index"
import { Colors } from "../../constants/palette"

import CardExample from "../../0_atoms/CardFrame/CardFrame"
import { RoundButtonWithChevron } from "../../0_atoms/Button/Button"
import TexturedMedalShineCSS from "../../0_atoms/Logos/TexturedMedalShineCSS"
import LogoShiningSVG from "../../0_atoms/Logos/TexturedMedalShineCSS"

const roundButtonStyle: React.CSSProperties = {
  display: "inline-block",
  position: "relative",
  zIndex: 1,
}

const WrapperRoundButtonCard = styled.div`
  min-width: 320px;
  max-width: 450px;
  width: 100%;
  display: inline-block;
  margin: auto;
  display: flex;
  min-height: 133px;
  flex-flow: ${props => (props.leftButton ? "row-reverse" : "row")};
  justify-items: center;
  align-items: center;
  ${props => props.display && "display:" + props.display + ";"}
`

const OpacityLayer = styled.div`
  position: absolute;
  z-index: 5;
  width: ${props => props.width - 46 + "px"};
  height: ${props => props.height - 40 + "px"};
  margin-left: 23px;
  background-color: ${props => Colors[props.styleMode][props.theme].L10};
  opacity: 0.8;
`

const WrapperLogoFetching = styled.div`
  position: absolute;
  z-index: 10;
  width: ${props => props.width - 46 + "px"};
  height: ${props => props.height + "px"};
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-left: 23px;
`

interface PropsBaseCard {
  leftButton?: boolean
  className?: string
  display?: string
  onClickLeft?: Function
  secondButton?: boolean
  toggle?: boolean
  onClickRoundButton?: Function
  children?: any
  style?: React.CSSProperties
  fetching?: boolean
  onClickCard?: Function
}

export default (props: PropsBaseCard): JSX.Element => {
  const theme = useContext(ThemeContext)
  const refCard = useRef(null)
  const [width, setWidth] = useState(400)
  const [height, setHeight] = useState(133)

  useEffect(() => {
    if (refCard.current && refCard.current.clientWidth !== 0) {
      setWidth(refCard.current.clientWidth)
      setHeight(refCard.current.clientHeight)
    }
  }, [props.fetching])

  return (
    <WrapperRoundButtonCard
      display={props.display}
      leftButton={props.leftButton}
      className={props.className + " WrapperRoudButtonCard"}
      ref={refCard}
    >
      {props.fetching && (
        <>
          <OpacityLayer className={'opacity-layer'} width={width} height={height} {...theme} />
          <WrapperLogoFetching width={width} height={height}>
            <TexturedMedalShineCSS />
          </WrapperLogoFetching>
        </>
      )}
      <RoundButtonWithChevron
        style={roundButtonStyle}
        onClick={props.onClickLeft}
        leftButton={true}
        secondButton={props.secondButton}
        toggle={props.toggle}
        className="roundButton"
      />
      <CardExample onClickCard={props.onClickCard}>
        {props.children}
      </CardExample>
      <RoundButtonWithChevron
        style={roundButtonStyle}
        onClick={props.onClickRoundButton}
        leftButton={props.leftButton}
        toggle={props.toggle}
        className="roundButton"
      />
    </WrapperRoundButtonCard>
  )
}
