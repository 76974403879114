import React, { useEffect, useState, useContext } from "react"
import { gsap, TweenMax } from "gsap"

import { ExampleData } from "../../utils/data/data"
import { tweenConfigurationsPartAppear } from "../../utils/animations"
import { HashValuesContext } from "../../constants/index"

import BaseCard from "../../1_molecules/BaseCard/BaseCard"
import PresentationFind from "../../2_cards/Find/aPresentation/Presentation"
import NewSearch from "../../2_cards/Find/bNewSearch/NewSearch"
import DishFound from "../../2_cards/Find/cDishFound/WrapperDishFound"
import RestaurantFound from "../../2_cards/Find/dRestaurantFound/WrapperRestaurantFound"

import PresentationEvaluate from "../../2_cards/Evaluate/aPresentation/Presentation"
import TypeRestaurant from "../../2_cards/Evaluate/bTypeRestaurant/TypeRestaurant"
import TypeDish from "../../2_cards/Evaluate/cTypeDish/TypeDish"
import Evaluate from "../../2_cards/Evaluate/dEvaluate/wrapperEvaluate"

import ProfileCard from "../../2_cards/Configs/Profile/ProfileCard"
import MyEvaluationsCard from "../../2_cards/Configs/MyEvaluations/WrapperMyEvaluations"
import UserConfigurations from "../../2_cards/Configs/Configurations/WrapperConfigurations"
import AboutHelp from "../../2_cards/Configs/AboutHelp/AboutHelp"
import AppData from "../../2_cards/Configs/AppData/WrapperAppData"
import AppConfigurations from "../../2_cards/Configs/AppConfigurations/WrapperAppConfigurations"

const tweenDisappearTopCard = (tween, ref) =>
  tween
    .to(ref, 0.3, { opacity: 0, autoAlpha: 0 })
    .to(ref, 0.3, { height: 0, margin: 0, marginTop: 0 })
    .to(ref, 0, { display: "none" })
    .reverse()

const tweenDisappear = (tween, ref) =>
  tween
    .to(ref, 0.3, { opacity: 0, autoAlpha: 0 })
    .to(ref, 0.3, { height: 0, margin: 0 })
    .to(ref, 0, { display: "none" })
    .reverse()

const tweenAppear = (tween, ref) =>
  tween
    .to(ref, 0.1, { display: "flex" })
    .to(ref, 0.1, { opacity: 1, autoAlpha: 1 })
    .reverse()

const resettingMargin = (tween, ref) =>
  tween.eventCallback("onReverseComplete", () =>
    [].slice
      .call(document.getElementsByClassName(ref))
      .map(e => (e.style.margin = "auto"))
  )

interface PropsCards {
  widthCard: number
  onClickFind: Function
  onClickEvaluate: Function
  onClickConfigurations: Function
  onClickLeftFind: Function
  onClickLeftEvaluate: Function
  onClickFindCard: Function
  onClickEvaluateCard: Function
}

export default (props: PropsCards): JSX.Element => {
  const hashValues = useContext(HashValuesContext)
  const [find, setFind] = useState(hashValues.find)
  const [evaluate, setEvaluate] = useState(hashValues.evaluate)
  const [configurations, setConfigurations] = useState(
    hashValues.configurations
  )
  const [evaluateFetching, setEvaluateFetching] = useState(false)
  const [findFetching, setFindFetching] = useState(false)
  const [configFetching, setConfigFetching] = useState(false)

  const [tweenFind]: any = useState(gsap.timeline({ paused: true }))
  const [tweenEvaluate]: any = useState(gsap.timeline({ paused: true }))
  const [tweenConfigurations]: any = useState(gsap.timeline({ paused: true }))

  const TweenMaxTyped: any = TweenMax

  const {
    onClickFind,
    onClickEvaluate,
    onClickConfigurations,
    onClickLeftFind,
    onClickLeftEvaluate,
    onClickEvaluateCard,
    onClickFindCard,
  } = props

  useEffect(() => {
    setFind(hashValues.find)
    setEvaluate(hashValues.evaluate)
    setConfigurations(hashValues.configurations)
  }, [JSON.stringify(hashValues)])

  useEffect(() => {
    tweenDisappearTopCard(tweenFind, ".find-card")
    tweenDisappear(tweenEvaluate, ".evaluate-card")
    tweenAppear(tweenConfigurations, ".configurations-card")

    resettingMargin(tweenFind, "find-card")
    resettingMargin(tweenEvaluate, "evaluate-card")
  }, [])

  useEffect(() => {
    switch (find) {
      case "search":
        tweenEvaluate.reversed(false)
        break
      case "presentation":
        TweenMaxTyped.delayedCall(1, () => tweenEvaluate.reversed(true))
        break
      case "none":
        tweenFind.reversed(false)
        break
      default:
    }
  }, [find])

  useEffect(() => {
    switch (evaluate) {
      case "search":
        tweenFind.reversed(false)
        break
      case "presentation":
        TweenMaxTyped.delayedCall(1, () => {
          if (configurations === "none" || configurations === "")
            tweenFind.reversed(true)
        })
        // TweenMaxTyped.delayedCall(0.25, () =>
        //   tweenEvaluateGrades.reversed(true)
        // )
        break
      case "dish":
        tweenFind.reversed(false)

        break
      case "evaluate":
        tweenFind.reversed(false)
        break
      case "none":
        tweenEvaluate.reversed(false)
        break
      default:
    }
  }, [evaluate])

  useEffect(() => {
    if (
      configurations.slice(0, 13) !== "intermediate-" &&
      configurations !== "none"
    )
      TweenMaxTyped.delayedCall(0.65, () => tweenConfigurations.reversed(false))

    let updatedConfigurations =
      configurations.slice(0, 13) === "intermediate-"
        ? configurations.slice(13, configurations.length)
        : configurations

    if (updatedConfigurations === "none")
      TweenMaxTyped.delayedCall(0.45, () => tweenConfigurations.reversed(true))
  }, [configurations])

  useEffect(() => {
    if (configurations !== "none") {
      tweenFind.reversed(false)
      tweenEvaluate.reversed(false)
    }
    switch (configurations) {
      case "none":
        // tweenEvaluate.reversed(false)
        // tweenEvaluate.reversed(false)
        break
      default:
        tweenFind.reversed(false)
        tweenEvaluate.reversed(false)
    }
  }, [configurations])

  return (
    <>
      <BaseCard
        className="find-card"
        toggle={find !== "presentation" && find !== "none"}
        onClickRoundButton={onClickFind}
        secondButton={true}
        onClickLeft={onClickLeftFind}
        onClickCard={onClickFindCard}
        fetching={findFetching}
      >
        <PresentationFind />
        {find === "search" && <NewSearch />}
        <DishFound fetching={e => setFindFetching(e)} />
        <RestaurantFound fetching={e => setFindFetching(e)} />
      </BaseCard>

      <BaseCard
        className="evaluate-card"
        toggle={evaluate !== "presentation" && evaluate !== "none"}
        secondButton={true}
        onClickRoundButton={onClickEvaluate}
        onClickLeft={onClickLeftEvaluate}
        onClickCard={onClickEvaluateCard}
        fetching={evaluateFetching}
      >
        <PresentationEvaluate />
        {evaluate === "search" && <TypeRestaurant />}
        {evaluate === "dish" && <TypeDish />}
        {evaluate === "evaluate" && (
          <Evaluate
            widthCard={props.widthCard}
            fetching={e => setEvaluateFetching(e)}
          />
        )}
      </BaseCard>

      <BaseCard
        className="configurations-card"
        toggle={false}
        onClickRoundButton={onClickConfigurations}
        display={"none"}
        style={{ opacity: 0 }}
        leftButton
        secondButton={true}
        fetching={configFetching}
      >
        {configurations === "profile" && <ProfileCard />}
        {configurations === "myEvaluations" && (
          <MyEvaluationsCard
            widthCard={props.widthCard}
            // fetching={e => setConfigFetching(e)}
          />
        )}
        {configurations === "settings" && (
          <UserConfigurations widthCard={props.widthCard} />
        )}
        {configurations === "about" && <AboutHelp />}
        {configurations === "dataApp" && (
          <AppData widthCard={props.widthCard} />
        )}
        {configurations === "appSettings" && <AppConfigurations />}
        {console.log('configurations', configurations)}
      </BaseCard>
    </>
  )
}
