import C from "../actions/constants"


export const userInitial = {
  validated: null,
  error: null,
  uid: null,
  fetching: false,
  language: null,
  last_login: null,
  active: null,
  email: null,
  role: null,
  emailVerificationSent: null,
  personalEvaluations: [],
  style: null,
  theme: null,
  erroWritingData: null,
  emailForgetPasswordSent: null,
  checkedForLogin: null
}

export const userFetch = (state = userInitial, action) => {
  switch (action.type) {
    /// Login
    case C.LOGIN_REQUEST:
      return Object.assign({}, state, { ...action.payload })
    case C.LOGIN_DONE:
      return Object.assign({}, state, { ...action.payload })
    case C.LOGIN_ERROR:
      return Object.assign({}, state, { ...action.payload })
    case C.LOG_OUT:
      return Object.assign({}, state, { ...userInitial })
    case C.LOGIN_CLEAN_DATA:
      return Object.assign({}, state, { ...action.payload })

    /// Detecting user
    case C.FETCHING_USER:
      return Object.assign({}, state, { ...action.payload })
    case C.DETECTED_USER:
      return Object.assign({}, state, { ...action.payload })
    case C.NON_LOGGED_USER:
      return Object.assign({}, state, { ...action.payload })
    case C.EMAIL_NOT_VERIFIED:
      return Object.assign({}, state, { ...action.payload })
    case C.EMAIL_NOT_VERIFIED_ERROR:
      return Object.assign({}, state, { ...action.payload })

    /// Writing data
    case C.START_WRITING:
      return Object.assign({}, state, { ...action.payload })
    case C.WRITING_LOGIN_DATA_DONE:
      return Object.assign({}, state, { ...action.payload })
    case C.WRITING_LOGIN_DATA_EROR:
      return Object.assign({}, state, { ...action.payload })

    /// Forget password

    case C.FORGET_EMAIL_REQUEST:
      return Object.assign({}, state, { ...action.payload })
    case C.FORGET_EMAIL_ERROR:
      return Object.assign({}, state, { ...action.payload })
    case C.FORGET_EMAIL_SENT:
      return Object.assign({}, state, { ...action.payload })

    /// Register user

    case C.REGISTER_REQUEST:
      return Object.assign({}, state, { ...action.payload })
    case C.REGISTER_ERROR:
      return Object.assign({}, state, { ...action.payload })
    case C.REGISTER_EMAIL_SENT:
      return Object.assign({}, state, { ...action.payload })

    /// Personal evaluations

    case C.PERSONAL_EVALS_FETCHED:
      return Object.assign({}, state, { ...action.payload })

    /// Update user configs
    case C.UPDATING_USER_CONFIGS:
      return Object.assign({}, state, { ...action.payload })
    case C.UPDATED_USER_CONFIGS:
      return Object.assign({}, state, { ...action.payload })
    case C.UPDATING_USER_CONFIGS_ERROR:
      return Object.assign({}, state, { ...action.payload })

    default:
      return state
  }
}
