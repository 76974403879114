import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { gsap } from "gsap"
import styled from "styled-components"

import { PropsAppConfigs } from "../../../utils/data/data"
import { handleStates } from "../../../utils/misc"
import { tweenEffect } from "../../../utils/animations"

import Typo from "../../../0_atoms/Typo/Typo"
import Input from "../../../0_atoms/Input/Input"
import Button from "../../../0_atoms/Button/Button"
import TextArea from "../../../0_atoms/TextArea/TextArea"
import SimpleSlider from "../../../1_molecules/SimpleSlider/SimpleSlider"

const optionsStyle = {
  opacity: 0,
  height: 0,
  maxWidth: 450,
  padding: "0px 35px 0px",
  display: "none",
  flexFlow: "column",
  margin: "auto",
}

const TypoStyle = {
  minWidth: "220px",
  paddingTop: "10px",
}

const options = ["dishCreation", "restCreation", "pilotMode", "importData"]

const Wrapper = styled.div`
  flex-direction: column;
  animation: 0.51s opening;

  @keyframes opening {
    from {
      display: none;
      opacity: 0;
      height: 0px;
    }
    20% {
      display: flex;
      height: 0px;
    }
    60% {
      height: 221px;
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      height: unset;
    }
  }

  textarea {
    height: 50px;
    margin: auto;
    width: 100%;
    paddingtop: 3px;
  }

  .button-with-textarea {
    margin: auto;
    margin-top: 10px;
    width: min-content;
    white-space: nowrap;
  }
`

interface PropsAppConfigsComponent extends PropsAppConfigs {
  saveConfigs?: any
}

export default (props: PropsAppConfigsComponent): JSX.Element => {
  const { t } = useTranslation("common")
  const [states, setStates] = useState([
    !props.addDishes,
    !props.addRests,
    props.isInPilotMode,
    false,
  ])
  const [pilotModeMessage, setPilotModeMessage] = useState(props.pilotMessage)

  const [dishes, setDishes] = useState("")
  const [restaurants, setRestaurants] = useState("")

  const [tweenPilotMode] = useState(gsap.timeline({ paused: true }))
  const [modalTweenImportData] = useState(gsap.timeline({ paused: true }))

  useEffect(
    () => handleStates(!props.addDishes, 0, states, setStates),
    [props.addDishes]
  )

  useEffect(
    () => handleStates(!props.addRests, 1, states, setStates),
    [props.addRests]
  )

  useEffect(
    () => handleStates(props.isInPilotMode, 2, states, setStates),
    [props.isInPilotMode]
  )

  useEffect(() => {
    tweenEffect(tweenPilotMode, ".pilotMode", "84px")
    tweenEffect(modalTweenImportData, ".importData", "230px")
  }, [])

  useEffect(() => {
    if (
      props.addDishes !== !states[0] ||
      props.addRests !== !states[1] ||
      (props.isInPilotMode !== states[2] && states[2] === false)
    )
      props.saveConfigs({
        addDishes: !states[0],
        addRests: !states[1],
        isInPilotMode: states[2],
        pilotMessage: pilotModeMessage,
      })
  }, [JSON.stringify(states.slice(0, 3))])

  useEffect(() => {
    tweenPilotMode.reversed(!states[2])
    modalTweenImportData.reversed(!states[3])
  }, [JSON.stringify(states.slice(2, 4))])

  return (
    <Wrapper className="app-cofigurations-items">
      <Typo cardDestak style={{ display: "block", paddingBottom: "20px" }}>
        {t("configs.appConfigurations.title")}
      </Typo>

      {options.slice(0, 3).map((e, i) => (
        <SimpleSlider
          key={i}
          text={t("configs.appConfigurations." + e)}
          styleTypo={TypoStyle}
          setFunction={f => handleStates(f, i, states, setStates)}
          booleanFunction={states[i]}
        />
      ))}

      <div className="pilotMode" style={optionsStyle}>
        <Input
          placeholder={t("configs.appConfigurations.defaultValueInput")}
          onChange={e => setPilotModeMessage(e)}
          value={pilotModeMessage}
        />
        <Button
          onClick={() =>
            props.saveConfigs({
              addDishes: !states[0],
              addRests: !states[1],
              isInPilotMode: states[2],
              pilotMessage: pilotModeMessage,
            })
          }
          className="button-with-textarea"
        >
          {t("configs.appConfigurations.saveMessage")}
        </Button>
      </div>

      <SimpleSlider
        text={t("configs.appConfigurations.importData")}
        styleTypo={TypoStyle}
        setFunction={f => handleStates(f, 3, states, setStates)}
        booleanFunction={states[3]}
      />

      <div className="importData" style={optionsStyle}>
        <TextArea
          placeholder={t("configs.appConfigurations.typeRests")}
          value={restaurants}
          onChange={() => setRestaurants}
        />
        <Button
          onClick={() => console.log("clicked")}
          className="button-with-textarea"
        >
          {t("configs.appConfigurations.saveRests")}
        </Button>
        <TextArea
          placeholder={t("configs.appConfigurations.typeDishes")}
          style={{ marginTop: "15px" }}
          value={dishes}
          onChange={e => setDishes(e)}
        />
        <Button
          onClick={() => console.log("clicked")}
          className="button-with-textarea"
        >
          {t("configs.appConfigurations.saveDishes")}
        </Button>
      </div>
    </Wrapper>
  )
}
