import React from "react"
import propTypes from "prop-types"
import { connect } from "react-redux"

import AppData from "./AppData"

interface PropsWrapperAppData {
  state?: any
  widthCard?: any
}

const DropdownLoginWrapper: React.FC<PropsWrapperAppData> = props => {
  return <AppData kpis={props.state.kpis} widthCard={props.widthCard}/>
}

DropdownLoginWrapper.propTypes = {
  state: propTypes.object.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(DropdownLoginWrapper)
