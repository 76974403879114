import React, { useContext } from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.css"

import { Colors } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"
import { settingHashParameters } from "../../utils/urlParams"

import BarBtns from "../../1_molecules/BarButtons/BarButtons"
import Logo from "../../../media/Logo3.svg"

const Wrapper = styled.div`
  min-height: 60px;
  min-width: 350px
  
  background: ${props => Colors[props.styleMode][props.theme].L10};

  display: flex;
  flex-flow: row;
  justify-content: space-between;
`

//box-shadow: ${props => Shadows[props.styleMode][props.theme].L00};

const Img = styled.img`
  height: 47px;
  margin: 6px 0px 0px 15px;

  :hover {
    cursor: pointer;
  }
`

const WrapperBarBtns = styled.div`
  margin: 10px 15px 0px 0px;
`

export default props => {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper {...theme}>
      <Img
        src={Logo}
        onClick={() =>
          settingHashParameters({
            find: "presentation",
            evaluate: "presentation",
          })
        }
      />
      <WrapperBarBtns>
        <BarBtns isLogged={props.isLogged} />
      </WrapperBarBtns>
    </Wrapper>
  )
}
