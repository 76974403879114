import React, { useState, useContext, useEffect } from "react"
import styled, { css } from "styled-components"
import { connect } from "react-redux"
import propTypes from "prop-types"
import { connectHits } from "react-instantsearch-dom"
import * as _ from "lodash"

import { Shadows, Colors } from "../../../constants/palette"
import { HashValuesContext } from "../../../constants/index"
import { fetchingEvaluations } from "../../../../actions/evaluations"
import { ThemeContext } from "../../../constants/index"

import ListItems from "./List"
import Map from "../../../0_atoms/Map/Map"

const Wrapper = styled.div.attrs(props => ({
  className: "new-search " + props.styleMode + props.theme,
}))`
  margin: 12px 25px 0px;
  height: 300px;
  display: flex;
  flex-flow: column;
  box-shadow: ${props => Shadows[props.styleMode][props.theme].Lm5upin0};
  border: 1px solid rgba(177, 175, 175, 0.6);
  ${props =>
    props.styleMode === "new" &&
    css`
      border: none;
    `}

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => Colors[props.styleMode][props.theme].scrollTrack};
    border-radius: 5px;
    ${props =>
      props.styleMode === "new" &&
      `
        box-shadow: inset -2px -2px 4px ${props =>
          props.theme === "light" ? "#898989" : "#cfcfcf"};
      `}
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => Colors[props.styleMode][props.theme].scrollBar};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => Colors[props.styleMode][props.theme].scrollHover};
    border-radius: 5px;
  }
`

function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    let dish_restaurant = acc.find(e => e[property] === obj[property])
    if (dish_restaurant) {
      dish_restaurant.dishes.push(_.cloneDeep(obj))
    } else {
      acc.push(obj)
      acc[acc.length - 1].dishes = []
      acc[acc.length - 1].dishes.push(_.cloneDeep(obj))
    }
    return acc
  }, [])
}

const Hits = ({
  hits,
  index,
  suggestionType,
  dispatch,
  state,
  unmount,
  searchStates,
  listOrMap,
}) => {
  const [organizedHits, setOrganizedHits] = useState(hits)
  const theme = useContext(ThemeContext)
  const hashValues = useContext(HashValuesContext)

  useEffect(() => {
    if (hashValues.find === "search") dispatch(fetchingEvaluations(null, null))
  }, [JSON.stringify(hashValues)])

  useEffect(() => {
    if (hits && hits[0]) {
      if (searchStates === "[0,0,1]" && hits[0].rest) {
        let newOrganizedHits = _.cloneDeep(hits)
        newOrganizedHits.map(e => {
          e.name = e.rest.name
          e.uid = e.rest.uid
          e.location.latitude = e.location._latitude
          e.location.longitude = e.location._longitude
        })
        setOrganizedHits(groupBy(newOrganizedHits, "uid"))
      } else if (searchStates === "[0,1,1]") {
        let newOrganizedHits = _.cloneDeep(hits)
        newOrganizedHits.map(e => {
          e.location.latitude = e.location._latitude
          e.location.longitude = e.location._longitude
        })
        setOrganizedHits(newOrganizedHits)
      }
    }
  }, [JSON.stringify(hits), searchStates])

  return (
    <Wrapper {...theme}>
      <>
        {listOrMap === "list" && (
          <ListItems
            searchStates={searchStates}
            unmount={unmount}
            hits={hits}
          />
        )}
        {listOrMap === "map" && hits.length > 0 && hits[0].location && (
          <>
            <Map
              dish={{
                restaurants: organizedHits,
              }}
            />
          </>
        )}
      </>
    </Wrapper>
  )
}

Hits.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToPropsHits = state => ({
  state: state,
})

export default connectHits(connect(mapStateToPropsHits)(Hits))
