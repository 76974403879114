import C from "../actions/constants"

export const evaluate = (state = null, action) => {
  switch (action.type) {
    case C.EVALUATION_SAVING:
      return Object.assign({}, state, {
        user: null,
      })
    case C.EVALUATION_SAVE_DONE:
      return Object.assign({}, state, {
        user: null,
      })
    case C.RESTAURANT_SAVE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
      })
    default:
      return state
  }
}

export const searchingItem = (state = { item: null }, action) => {
  switch (action.type) {
    case C.SEARCHING_ITEM:
      return Object.assign({}, state, {
        item: action.item,
      })
    default:
      return state
  }
}
