import C from "./constants"
import * as firebase from "firebase"
import { searchingItem } from "./index"
import { fetchingEvaluations } from "./evaluations"

const initialState = {
  fetching: null,
  dishDoc: null,
  newItem: null,
}

const nullDish = {
  evaluations: 0,
  name: "",
  uid: "",
  grades_abs: null,
  grades_avg: null,
  restaurants: null,
  tags: [],
  newItem: null,
}

export const selectDish = (dishUid, newItem, whatFor) => {
  //, evalsdishRests, rest) => {
  return dispatch => {
    if (dishUid === null) {
      console.log("UNSELECTED_DISH")
      dispatch({ type: C.UNSELECTED_DISH, payload: { ...initialState } })
    } else {
      if (
        // evalsdishRests.filter(e => e.dish === dishUid && e.rest === rest)
        //   .length > 0
        false
      ) {
        alert(
          "Não é permitido fazer mais de uma avaliação para o mesmo prato de um mesmo restaurante em um período de 20 horas."
        )
        // dispatch(navigate("Home"))
      } else {
        var dishDoc

        console.log("dishUid 42", dishUid)
        if (newItem) {
          dispatch(searchingItem(null))
          console.log("SELECTED_DISH")
          dispatch({
            type: C.SELECTED_DISH,
            payload: {
              fetching: null,
              newDish: newItem,
              dishDoc: {
                ...nullDish,
                name: dishUid,
              },
            },
          })

          // page === "evaluate"
          //   ? dispatch(navigate("EvaluateForm"))
          //   : dispatch(navigate("FindDish"))
        } else {
          firebase
            .firestore()
            .collection("dishes")
            .doc(dishUid)
            .get()
            .then(doc => {
              if (doc.exists) {
                //saveSearch(doc.data().name)
                dishDoc = doc.data()
                let dishDocInStore = {
                  uid: dishUid,
                  evaluations: dishDoc.evaluations,
                  name: dishDoc.name,
                  grades_abs: dishDoc.grades_abs,
                  grades_avg: dishDoc.grades_avg,
                  tags: dishDoc.tags,
                  newItem: false,
                }
                console.log("DISH_FETCHED")
                dispatch({
                  type: C.DISH_FETCHED,
                  payload: {
                    fetching: false,
                    newDish: newItem,
                    dishDoc: dishDocInStore,
                  },
                })
                dispatch(dishRests(dishUid, dishDocInStore, whatFor))
              } else {
                console.log("No such document!")
                alert("This dish was not found on the database!")
              }
            })
            .catch(function (error) {
              console.log("Error getting document:", error)
            })
        }
      }
    }
  }
}

export const dishRests = (dishUid, dishDocInStore, whatFor) => {
  return dispatch => {
    console.log("RESTS_DISH_FETCHING")
    dispatch({
      type: C.RESTS_DISH_FETCHING,
      payload: {
        fetching: true,
        dishDoc: dishDocInStore,
      },
    })

    var restsDocs = []

    console.log("dish.uid", dishUid)

    firebase
      .firestore()
      .collection("dishes_restaurants")
      .where("dish.uid", "==", dishUid)
      // .orderBy("grades_abs.taste")
      .get()
      .then(querySnapshot => {
        console.log("heren querySnapshot")
        querySnapshot.forEach(doc => {
          console.log("hello doc", doc.data())
          restsDocs.push({
            ...doc.data(),
            name: doc.data().rest.name,
            uid: doc.data().rest.uid,
          })
        })
      })
      .then(() => {
        console.log("RESTS_DISH_FETCHED")
        dispatch({
          type: C.RESTS_DISH_FETCHED,
          payload: {
            fetching: false,
            dishDoc: { ...dishDocInStore },
            restaurants: restsDocs,
          },
        })
        if (whatFor !== "evaluate")
          console.log(
            "restDocs here",
            restsDocs,
            restsDocs[0].rest.uid,
            dishDocInStore.uid
          )
        dispatch(fetchingEvaluations(dishDocInStore.uid, restsDocs[0].rest.uid))
      })
  }
}
