import React, { useContext } from "react"
import styled from "styled-components"

import { Colors } from "../../constants/palette"

import LogoShiningSVG from "../../0_atoms/Logos/TexturedMedalShineCSS"

const LogoWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  opacity: 1;
  background-color: ${Colors["classic"]["light"].base};
  z-index: 100;
  position: absolute;
`

export default (): JSX.Element => {

  return (
    <LogoWrapper className="logo_background">
      <LogoShiningSVG
        className="logo_wrapper_on_loading"
        style={{
          position: "relative",
          top: "calc(50% - 114px)",
          textAlign: "center",
          opacity: 1,
        }}
        width={200}
      />
    </LogoWrapper>
  )
}

// export default (): any => {
//   return (
//     <svg
//       style={{
//         height: "100vh",
//         width: "100vw",
//         opacity: 1,
//         backgroundColor: Colors["classic"]["light"].base,
//         zIndex: 100,
//         position: "absolute",
//       }}
//     >
//       <foreignObject  style={{ transform: 'translate(calc(50% - 100px), calc(50% - 100px))'}} width="200" height="200">
//         <LogoShiningSVG
//           className="logo_wrapper_on_loading"
//           style={{
//             position: "relative",
//             top: "calc(50% - 114px)",
//             textAlign: "center",
//             opacity: 1,
//           }}
//           width={200}
//         />
//       </foreignObject>
//       <animate
//         attributeName="opacity"
//         values="1;0"
//         begin="1s"
//         dur="2s"
//         fill="freeze"
//       />
//       <animate
//         attributeName="display"
//         to="none"
//         begin="3s"
//         dur="0.5s"
//         fill="freeze"
//       />
//     </svg>
//   )
// }
