import React, { useEffect } from "react"
import propTypes from "prop-types"
import { connect } from "react-redux"

import { PropsWrappers } from "../../../constants/index"

import MyEvaluations from "./MyEvaluations"

interface PropsMyEvaluationsWrapper extends PropsWrappers {
  widthCard: number
  fetching?: Function
}

const WrapperMyEvaluations: React.FC<PropsMyEvaluationsWrapper> = props => {
  // useEffect(
  //   () =>
  //     props.state.userFetch.personalEvaluations === null
  //       ? props.fetching(true)
  //       : props.fetching(false),
  //   [JSON.stringify(props.state.userFetch.personalEvaluations)]
  // )

  return (
    <MyEvaluations
      data={props.state.userFetch.personalEvaluations}
      widthCard={props.widthCard}
    />
  )
}

WrapperMyEvaluations.propTypes = {
  state: propTypes.object.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(WrapperMyEvaluations)
