import React, { useContext } from "react"
import styled, { css } from "styled-components"

import { Colors, Shadows } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"

const Input = styled.input`
  height: 27px;

  border-radius: 5px;
  text-align: center;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  border: 1px solid #ccc;

  color: ${props => Colors[props.styleMode][props.theme].letterAuxiliary2};
  background-color: ${props => Colors[props.styleMode][props.theme].L15};

  ${props => Shadows[props.styleMode][props.theme].Lm5upin}

  ${props =>
    props.styleMode === "new" &&
    css`
      border: none;
    `}

    :focus {
    outline: none;
  }
`

interface PropsInput {
  placeholder?: string
  style?: React.CSSProperties
  onChange: Function
  value: string
  size?: string | number
  type?: string
}

export default (props: PropsInput): JSX.Element => {
  const theme = useContext(ThemeContext)

  return (
    <Input
      placeholder={props.placeholder}
      {...props}
      {...theme}
      style={props.style}
    />
  )
}
