import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { gsap, TweenMax } from "gsap"
import { useTranslation } from "react-i18next"
import { InstantSearch, Configure } from "react-instantsearch/dom"
import algoliasearch from "algoliasearch/lite"

import {
  tweenDisappearAppear,
  tweenOpacityShadow,
} from "../../../utils/animations"
import { ThemeContext } from "../../../constants/index"
import { tweenConfigurationsPartAppear } from "../../../utils/animations"

import TripleSelect from "../../../0_atoms/Select/TripleSelect"
import Typo from "../../../0_atoms/Typo/Typo"
import InfiniteScroll from "./wrapperHits"
import SearchBox from "./wrapperSearchBox"

const searchClient = algoliasearch(
  "IF3U0V4NTS",
  "6a799c8787e6e0f3b4bcb939f74962f4"
)

const ComponentsWrapper = styled.div`
  display: none;
  flex-direction: column;
  opacity: 0;
  height: 0px;
`

const SelectsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const InputWrapper = styled.div`
  margin: 15px 40px 5px;
  width: calc(100% - 80px);
  max-width: 450px;
`

const searchOptions = [
  [{ name: "find.findSearch.name" }, { name: "find.findSearch.tag" }],
  [
    { name: "find.findSearch.ofTheDish" },
    { name: "find.findSearch.ofTheRestaurant" },
  ],
  [{ name: "find.findSearch.onAllData" }, { name: "find.findSearch.withMap" }],
]

export default (): JSX.Element => {
  const { t } = useTranslation("common")
  const theme = useContext(ThemeContext)
  const [tweenSearch] = useState(gsap.timeline({ paused: true }))
  const [searchStates, setSearchStates] = useState(JSON.stringify([0, 0, 0]))
  const [defaultValue, setDefaultValue] = useState(
    t("find.findSearch.0,0") as string
  )
  const [nameOrTag, setNameOrTag] = useState("name")
  const [listOrMap, setListOrMap] = useState("list")
  const [dishesOrRestaurants, setDishesOrRestaurants] = useState("dishes")
  const [tweenFindSearch]: any = useState(gsap.timeline({ paused: true }))
  const TweenMaxTyped: any = TweenMax

  useEffect(() => {
    tweenOpacityShadow(
      tweenSearch,
      ".new-search.",
      theme.styleMode,
      theme.theme,
      "Lm5upin2",
      0
    )
    tweenSearch.reversed(false)
    tweenConfigurationsPartAppear(tweenFindSearch, ".find-search-items", 420)
    TweenMaxTyped.delayedCall(0.25, () => tweenFindSearch.reversed(false))
    return () => tweenFindSearch.reversed(true)
  }, [])

  useEffect(() => {
    console.log(
      "searchStates",
      searchStates,
      searchStates.substring(1, 2),
      searchStates.substring(3, 4),
      searchStates.substring(5, 6)
    )

    if (searchStates.substring(5, 6) === "1") {
      searchStates.substring(3, 4) === "0"
        ? setDishesOrRestaurants("dishes_restaurants")
        : setDishesOrRestaurants("restaurants")
    } else {
      searchStates.substring(3, 4) === "0"
        ? setDishesOrRestaurants("dishes")
        : setDishesOrRestaurants("restaurants")
    }

    setDefaultValue(
      t("find.findSearch." + searchStates.substring(1, 4)) as string
    )
    searchStates.substring(5, 6) === "1"
      ? setListOrMap("map")
      : setListOrMap("list")

    if (searchStates.substring(1, 2) === "1") {
      setNameOrTag("tags")
    } else {
      searchStates.substring(5, 6) === "0"
        ? setNameOrTag("name")
        : setNameOrTag("dish.name")
    }
  }, [searchStates])

  console.log(
    "nameOrTag listOrMap dishesOrRestaurants",
    nameOrTag,
    dishesOrRestaurants,
    listOrMap
  )

  return (
    <ComponentsWrapper className="find-search-items">
      <Typo
        style={{
          margin: "10px 40px 5px",
          display: "block",
        }}
        cardText
      >
        {t("find.findSearch.searchFor")}
      </Typo>

      <SelectsWrapper>
        <TripleSelect
          items={searchOptions}
          setStates={e => setSearchStates(e)}
        />
      </SelectsWrapper>
      <InstantSearch
        indexName={dishesOrRestaurants}
        searchClient={searchClient}
      >
        <InputWrapper>
          <SearchBox searchStates={searchStates} />
        </InputWrapper>

        <Configure
          hitsPerPage={20}
          restrictSearchableAttributes={[nameOrTag]}
        />

        <InfiniteScroll
          unmount={() => tweenFindSearch.reversed(true)}
          searchStates={searchStates}
          listOrMap={listOrMap}
        />
      </InstantSearch>
    </ComponentsWrapper>
  )
}
