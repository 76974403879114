import React, { Component } from "react"
import * as firebase from "firebase"
import { connect } from "react-redux"
import i18next from "./config/i18n/i18n"
import { I18nextProvider } from "react-i18next"
import ReactGA from "react-ga"
import propTypes from "prop-types"
import { TweenMax } from "gsap"

import { ThemeContext } from "./components/constants/index"
import "./App.css"
import { CONFIG } from "./config/firebase/config.js"
import { detectUser } from "./actions/user"
import { selectDish } from "./actions/selectedDish"
import { selectRest } from "./actions/selectedRest"
import { getHashChanges } from "./components/utils/urlParams"

import StartLogo from "./components/1_molecules/StartLogo/StartLogo"
import LayoutWrapper from "./components/3_ecosystems/Cards/LayoutWrapper"

//import './node_modules/@fortawesome/fontawesome-free/css/all.css'
//import Home from './components/4_enviroments/HomePage'

const trackingId = "UA-125963102-2"

const animationOut = context => {
  context.setState({ animateOn: false })
  console.log("fading logo")
  TweenMax.to(".logo_background", 0.5, { opacity: 0 }).delay(0.3)
  TweenMax.to(".logo_background", 0.01, { display: "none" }).delay(0.8)
  console.log("animation out")
  setTimeout(() => context.setState({ logo: false }), 700)
}

const getInitialValue = (initialHashValues, property) =>
  initialHashValues[property]
    ? JSON.parse(decodeURIComponent(initialHashValues[property]))
    : null

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uid: null,
      style: localStorage.getItem("style")
        ? localStorage.getItem("style")
        : "new",
      theme: localStorage.getItem("theme")
        ? localStorage.getItem("theme")
        : "light",
      language: localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "en",
      animateOn: true,
      logo: true,
      initialHashValues: getHashChanges(),
    }
  }

  UNSAFE_componentWillMount(props) {
    const { detectUser, selectDish, selectRest } = this.props
    const { initialHashValues } = this.state
    //firebase.initializeApp(CONFIG)

    !firebase.apps.length
      ? firebase
          .initializeApp(CONFIG)
          .firestore()
          .settings({ timestampsInSnapshots: true })
      : firebase.app().firestore().settings({ timestampsInSnapshots: true })

    detectUser(this.props.userFetch)
    firebase.auth().useDeviceLanguage()

    ReactGA.initialize(trackingId, { testMode: true })
    ReactGA.pageview(window.location.pathname + window.location.search)

    i18next.changeLanguage("en", (err, t) => {
      if (err) return console.log("something went wrong loading", err)
    })

    if (initialHashValues.selected) {
      let selected = JSON.parse(decodeURIComponent(initialHashValues.selected))

      if (initialHashValues.find === "dish" && selected.dishUid !== "")
        selectDish(selected.dishUid, false, "find")
      if (initialHashValues.find === "restaurant" && selected.restUid !== "")
        selectRest(selected.restUid, false, "find")
    }
  }

  componentDidUpdate(prevProps) {
    const { initialHashValues } = this.state

    if (
      prevProps.state.userFetch.theme !== this.props.state.userFetch.theme ||
      prevProps.state.userFetch.style !== this.props.state.userFetch.style ||
      prevProps.state.userFetch.language !== this.props.state.userFetch.language
    )
      if (this.props.state.userFetch.uid) {
        console.log("setting theme")
        this.setState({
          theme: this.props.state.userFetch.theme || "light",
          style: this.props.state.userFetch.style || "new",
          language: this.props.state.userFetch.language || "en",
        })
      } else {
        console.log("setting theme")
        this.setState({
          theme: localStorage.getItem("theme") || "light",
          style: localStorage.getItem("style") || "new",
          language: localStorage.getItem("language") || "en",
        })
      }

    let checkedForLogin =
      this.props.state.userFetch.checkedForLogin &&
      prevProps.state.userFetch.checkedForLogin === null

    let selected = getInitialValue(initialHashValues, "selected"),
      find = initialHashValues.find,
      evaluate = initialHashValues.evaluate

    if (checkedForLogin && (!selected || evaluate)) animationOut(this)

    if (selected && find) {
      let checkedForDish =
        selected &&
        selected.dishName !== "" &&
        selected.dishUid !== "" &&
        this.props.state.selectedDish.dishDoc &&
        this.props.state.selectedDish.restaurants &&
        this.props.state.evaluations &&
        this.props.state.evaluations.evaluations &&
        this.props.state.evaluations.evaluations.length > 0 &&
        //this.props.state.selectedRest.restDoc &&
        prevProps.state.evaluations.evaluations === undefined

      let checkedForRest =
        selected &&
        selected.restName !== "" &&
        selected.restUid !== "" &&
        this.props.state.selectedRest.restDoc &&
        this.props.state.selectedRest.dishes &&
        this.props.state.evaluations &&
        this.props.state.evaluations.evaluations &&
        this.props.state.evaluations.evaluations.length > 0 &&
        //this.props.state.selectedDish.dishDoc &&
        prevProps.state.evaluations.evaluations === undefined

      if ((initialHashValues.find === "dish") & checkedForDish)
        animationOut(this)

      if ((initialHashValues.find === "restaurant") & checkedForRest)
        animationOut(this)
    }

    if (
      prevProps.state.appConfigs.tags.length !==
        this.props.state.appConfigs.tags.length &&
      this.props.state.appConfigs.tags
    ) {
      let enTags = {}
      Object.keys(this.props.state.appConfigs.tags).map(e => {
        return (enTags[e] = e)
      })
      i18next.addResourceBundle("en", "common", {
        tags: { ...enTags },
      })
      i18next.addResourceBundle("br", "common", {
        tags: { ...this.props.state.appConfigs.tags },
      })
    }
  }

  render() {
    console.log("state", this.props.state)

    i18next.changeLanguage(this.state.language, (err, t) => {
      if (err) return console.log("something went wrong loading", err)
    })

    return (
      <I18nextProvider i18n={i18next}>
        <ThemeContext.Provider
          value={{ theme: this.state.theme, styleMode: this.state.style }}
        >
          {this.state.logo && <StartLogo />}
          {!this.state.animateOn && (
            <LayoutWrapper isLogged={this.props.state.userFetch.uid !== null} />
          )}
        </ThemeContext.Provider>
      </I18nextProvider>
    )
  }
}

App.propTypes = {
  detectUser: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

const mapDispatchToProps = dispatch => ({
  detectUser: userFetch => dispatch(detectUser(userFetch)),
  selectDish: (dishUid, isNewDish, whatFor) =>
    dispatch(selectDish(dishUid, isNewDish, whatFor)),
  selectRest: (restUid, isNewRest, whatFor) =>
    dispatch(selectRest(restUid, isNewRest, whatFor)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
