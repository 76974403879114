import { PropsEvaluations } from "./data/utilData"
import * as d3 from "d3"

interface Nesting {
  name?: string
  values?: any
  children?: Array<{
    key: string
    name: string
    children: Array<{
      key: string
      name: string
      children: Array<{
        key: string
        name: string
      }>
    }>
  }>
}

export const evaluationsToGradesDishesRestaurants = (
  incomeEvaluations: Array<PropsEvaluations>
): Nesting => {
  let evaluations = incomeEvaluations
  evaluations.map(e => (e.value = 1))

  let nesting = {
    name: "evaluations",
    children: null,
    values: d3
      .nest()
      .key(d => d.grades.taste)
      .key(d => d.dish.uid)
      .key(d => d.rest.uid)
      .entries(evaluations),
  }

  let dishes = {},
    restaurants = {}
  evaluations.map(e => {
    dishes[e.dish.uid] = e.dish.name
    restaurants[e.rest.uid] = e.rest.name
  })

  nesting.values.map(e => {
    e.name = e.key
    e.children = e.values
    e.values.map(f => {
      f.name = dishes[f.key]
      f.children = f.values
      f.values.map(g => {
        g.name = restaurants[g.key]
        g.children = g.values
        delete g.values
      })
      delete f.values
    })
    delete e.values
  })
  nesting.children = nesting.values
  delete nesting.values

  return nesting
}

export const evaluationsToRestaurantsGradesDishes = (
  incomeEvaluations: Array<PropsEvaluations>
): Nesting => {
  let evaluations = incomeEvaluations
  evaluations.map(e => (e.value = 1))

  let nesting = {
    name: "evaluations",
    children: null,
    values: d3
      .nest()
      .key(d => d.rest.uid)
      .key(d => d.grades.taste)
      .key(d => d.dish.uid)

      .entries(evaluations),
  }

  let dishes = {},
    restaurants = {}
  evaluations.map(e => {
    dishes[e.dish.uid] = e.dish.name
    restaurants[e.rest.uid] = e.rest.name
  })

  nesting.values.map(e => {
    e.name = restaurants[e.key]
    e.children = e.values
    e.values.map(f => {
      f.name = f.key
      f.children = f.values
      f.values.map(g => {
        g.name = dishes[g.key]
        g.children = g.values
        delete g.values
      })
      delete f.values
    })
    delete e.values
  })
  nesting.children = nesting.values
  delete nesting.values
  return nesting
}

export const evaluationsToDishesGradesRestaurants = (
  incomeEvaluations: Array<PropsEvaluations>
): Nesting => {
  let evaluations = incomeEvaluations
  evaluations.map(e => (e.value = 1))

  let nesting = {
    name: "evaluations",
    children: null,
    values: d3
      .nest()
      .key(d => d.dish.uid)
      .key(d => d.grades.taste)
      .key(d => d.rest.uid)
      .entries(evaluations),
  }

  let dishes = {},
    restaurants = {}
  evaluations.map(e => {
    dishes[e.dish.uid] = e.dish.name
    restaurants[e.rest.uid] = e.rest.name
  })

  nesting.values.map(e => {
    e.name = dishes[e.key]
    e.children = e.values
    e.values.map(f => {
      f.name = f.key
      f.children = f.values
      f.values.map(g => {
        g.name = restaurants[g.key]
        g.children = g.values
        delete g.values
      })
      delete f.values
    })
    delete e.values
  })
  nesting.children = nesting.values
  delete nesting.values
  return nesting
}
