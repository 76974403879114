import React, { useContext } from "react"
import styled from "styled-components"
import Typo from "../Typo/Typo"
import { useTranslation } from "react-i18next"

import { singularOrPlural } from "../../utils/misc"
import { PropsRestsDishes } from "../../utils/data/dishData"
import { Colors } from "../../constants/palette"
import { settingHashParameters } from "../../utils/urlParams"
import { ThemeContext } from "../../constants/index"

import Favorite from "@material-ui/icons/Favorite"

const iconStyles: React.CSSProperties = {
  verticalAlign: "top",
  marginTop: "2px",
  fontSize: "13.5px",
  marginLeft: "2px",
}

const Wrapper = styled.div`
  width: 180px;
  display: flex;
  flex-flow: column;

  ul {
    list-style-type: none;
    padding: 0px 0px 0px 10px;
    max-height: 80px;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 6px;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      background: ${props => Colors[props.styleMode][props.theme].scrollTrack};
      border-radius: 5px;
      ${props =>
        props.styleMode === "new" &&
        `
          box-shadow: inset -2px -2px 4px ${props =>
            props.theme === "light" ? "#898989" : "#cfcfcf"};
        `}
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => Colors[props.styleMode][props.theme].scrollBar};
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${props => Colors[props.styleMode][props.theme].scrollHover};
      border-radius: 5px;
    }
    li {
      margin: 6px 0px;
    }
  }
`

const Grades: React.FC<{ item: PropsRestsDishes }> = props => {
  const { t } = useTranslation("common")
  const theme = useContext(ThemeContext)
  console.log("props on Popup", props)

  return (
    <Wrapper {...theme}>
      <Typo
        dropdownTitle
        style={{
          margin: "unset",
          textAlign: "left",
          padding: "0px",
          paddingLeft: "4px",
          opacity: 1,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          // unmount() - get the unmount from the mapSearch, from therestFound and from the dishFound
          setTimeout(
            () =>
              settingHashParameters({
                find: "restaurant",
                selected: encodeURIComponent(
                  JSON.stringify({
                    restName: props.item.name,
                    restUid: props.item.objectID || props.item.rest.objectID,
                  })
                ),
              }),
            530
          )
        }}
      >
        {props.item.name}{" "}
        <Typo
          dropdownTitle
          style={{
            margin: "unset",
            textAlign: "left",
            padding: "0px",
            paddingLeft: "4px",
            opacity: 1,
          }}
        >
          {(Math.round(props.item.grades_avg.taste * 10) / 10).toFixed(1)}
          <Favorite style={{ ...iconStyles, color: Colors.taste }} />
        </Typo>
      </Typo>

      {/* <Typo
        dropdownTitle
        style={{
          textAlign: "left",
          marginLeft: "0px",
          fontSize: "10px",
          padding: "0px",
          paddingLeft: "4px",
          opacity: 0.7,
        }}
      >
        {props.item.location.address}
      </Typo> */}
      {props.item.dishes && (
        <>
          <Typo
            dropdownTitle
            style={{
              margin: "unset",
              textAlign: "left",
              padding: "0px",
              paddingLeft: "4px",
              opacity: 1,
              marginTop: "10px",
            }}
          >
            Pratos:
          </Typo>
          <ul>
            {props.item.dishes.map((e, i) => (
              <li key={i}>
                <Typo
                  dropdownTitle
                  style={{
                    margin: "unset",
                    textAlign: "left",
                    padding: "0px",
                    paddingLeft: "4px",
                    opacity: 1,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // unmount() - get the unmount from the mapSearch, from therestFound and from the dishFound
                    setTimeout(
                      () =>
                        settingHashParameters({
                          find: "dish",
                          selected: encodeURIComponent(
                            JSON.stringify({
                              dishName: e.dish.name,
                              dishUid: e.dish.uid,
                            })
                          ),
                        }),
                      530
                    )
                  }}
                >
                  {" "}
                  {e.dish.name}
                  <Typo
                    dropdownTitle
                    style={{
                      margin: "unset",
                      textAlign: "left",
                      padding: "0px",
                      paddingLeft: "4px",
                      opacity: 1,
                    }}
                  >
                    {(
                      Math.round(props.item.grades_avg.taste * 10) / 10
                    ).toFixed(1)}
                    <Favorite style={{ ...iconStyles, color: Colors.taste }} />
                  </Typo>
                </Typo>
              </li>
            ))}
          </ul>
        </>
      )}
    </Wrapper>
  )
}

export default Grades
