import React from "react"
import { useTranslation } from "react-i18next"

import Typo from "../../../0_atoms/Typo/Typo"


export default (): JSX.Element => {
  const { t } = useTranslation("common")
  return (
    <>
      <Typo cardDestak style={{ display: "block" }}>
        {t("evaluate.evaluate1.title")}
      </Typo>
      <Typo
        style={{
          width: "200px",
          margin: "auto",
          marginTop: "15px",
          display: "block",
        }}
        cardText
      >
        {t("evaluate.evaluate1.description")}
      </Typo>
    </>
  )
}
