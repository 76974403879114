import React, { useState, useEffect, useContext, useRef } from "react"
import styled, { css } from "styled-components"
import gsap from "gsap"

import { ThemeContext } from "../../constants/index"
import { Colors, Shadows } from "../../constants/palette"

const ButtonComponent = styled.button`
  border: none;
  background: none;
  margin: 0px 4px;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  font: Montserrat;
  display: inline-block;
  // padding: 14px 11px;
  padding: 11px 11px;
  border-radius: 3px;

  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  letter-spacing: 0.05em;

  color: ${Colors.mainOrange};

  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  text-decoration: none;

  box-shadow: ${props => Shadows[props.styleMode][props.theme].L00};
  background: ${props => Colors[props.styleMode][props.theme].L10};

  :active {
    ${props =>
      props.pressed === undefined
        ? Shadows[props.styleMode][props.theme].Lm5
        : ""};

    ${props =>
      props.round &&
      css`
        box-shadow: ${props =>
          Shadows[props.styleMode][props.theme].L00roundpressed};
      `}
  }

  :focus {
    outline-color: ${Colors.mainOrange};
    outline-width: 0.5px;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  ${props =>
    props.pressed &&
    css`
      ${props => Shadows[props.styleMode][props.theme].Lm5};
    `}

  ${props =>
    props.round &&
    css`
      width: 32px;
      height: 32px;
      background: ${props => Shadows[props.styleMode][props.theme].round};
      box-shadow: ${props => Shadows[props.styleMode][props.theme].L00round};
      border-radius: 16px;
    `}

  ${props =>
    props.leftButton &&
    css`
      box-shadow: ${props => Shadows[props.styleMode][props.theme].Lleftbutton};
    `}

    ${props =>
    props.modalButton &&
    css`
      box-shadow: none;

      :active {
        box-shadow: none;
      }
    `}
`

const Wrapper = styled.div`
  height: 44px;
  width: min-content;
  margin: ${props =>
    props.leftButton ? "12px -40px 0px 0px" : "12px 0px 0px -18px"};
  z-index: 10;

  ${props =>
    props.secondButton &&
    `display: none; opacity: 0;
  `}
`

interface PropsButton {
  onClick: Function

  style?: React.CSSProperties
  className?: string

  toggle?: boolean
  pressed?: boolean
  round?: boolean

  secondButton?: boolean // when the card has two round buttons
  leftButton?: boolean // when the card has only the left button
  modalButton?: boolean
}

export const RoundButtonWithChevron: React.FC<PropsButton> = props => {
  const refIcon = useRef(null)
  const refWrapper = useRef(null)
  const [havePlayed, setHavePlayed] = useState(false)
  const [tl] = useState(gsap.timeline({ paused: true }))
  const [tl2] = useState(gsap.timeline({ paused: true }))

  useEffect(() => {
    if (!props.secondButton) {
      tl.to(refIcon.current, 0.5, { rotation: "90" })
        .delay(0.5)
        .to(refIcon.current, 0.5, {
          rotation: "0",
        })
      tl2.to(refIcon.current, 0.5, { rotation: "-90" }).to(
        refIcon.current,
        0.5,
        {
          rotation: "0",
        },
        1.3
      )
    } else {
      tl.to(refWrapper.current, 0.5, { display: "flex" })
        .to(refWrapper.current, 0.1, { opacity: 1, autoAlpha: 1 })
        .reverse()
    }
  }, [])

  useEffect(() => {
    if (!props.secondButton) {
      if (props.toggle) {
        setHavePlayed(true)
        tl.restart().play()
      } else {
        if (havePlayed) tl2.restart().play()
      }
    } else {
      tl.reversed(!props.toggle)
    }
  }, [props.toggle])

  return (
    <Wrapper
      leftButton={props.leftButton}
      className={props.className}
      secondButton={props.secondButton}
      ref={refWrapper}
    >
      <Button onClick={props.onClick} style={props.style} round>
        <i
          ref={refIcon}
          style={{
            position: "relative",
            top: "-4px",
            left: props.leftButton ? "-1.5px" : "1.5px",
            fontSize: "18px",
            transformOrigin: "4px 9px",
          }}
          className={
            props.leftButton ? "fa fa-chevron-left" : "fa fa-chevron-right"
          }
        />
      </Button>
    </Wrapper>
  )
}

const Button: React.FC<PropsButton> = props => {
  const theme = useContext(ThemeContext)

  return <ButtonComponent {...theme} {...props} />
}

export default Button
