import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { gsap } from "gsap"

import { Colors } from "../../constants/palette"
import { ThemeContext, PropsCaretComponent } from "../../constants/index"
import { openCaretTween } from "../../utils/animations"

import Typo from "../../0_atoms/Typo/Typo"

const Wrapper = styled.div`
  padding: 4px 0px;
  display: flex;
  flex-flow: column;
  margin: 10px 20px 0px;
`

const WrapperTitle = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  cursor: pointer;
`

const WrapperContent = styled.div`
  margin-left: -2px;
  margin-top: -2px;
  padding: 4px 0px;
  height: 0;
  display: none;
`

const CaretComponent: React.FC<PropsCaretComponent> = props => {
  const [openCaret, setOpenCaret] = useState(false)
  const [tweenTest] = useState(gsap.timeline({ paused: true }))
  const { styleMode, theme } = useContext(ThemeContext)
  const { t } = useTranslation("common")
  const { clickOpenCaret } = props

  useEffect(() => {
    openCaretTween(tweenTest, ".foundDish", "-map")
  }, [])

  useEffect(() => {
    tweenTest.reversed(!openCaret)
  }, [openCaret])

  return (
    <Wrapper>
      <WrapperTitle
        onClick={() => {
          clickOpenCaret()
          setOpenCaret(!openCaret)
        }}
      >
        <div>
          <Typo
            style={{
              textAlign: "left",
              fontSize: "11px",
              marginLeft: "0px",
              color: Colors[styleMode][theme].letterAuxiliary,
            }}
            cardText
          >
            <span
              style={{
                fontSize: "24px",
                paddingRight: props.noDistanceTitle ? "0px" : "6px",
              }}
            >
              {props.caretData.value}
            </span>
            {t(props.caretData.title)}
          </Typo>
        </div>
        <i
          style={{
            margin: "auto",
            fontSize: "14px",
            marginRight: "5px",
            paddingTop: "6px",
            cursor: "pointer",
            color: Colors[styleMode][theme].letterAuxiliary,
          }}
          className={
            "fas fa-angle-down " +
            props.caretData.classNamePreffix +
            "-i-" +
            props.caretData.label
          }
        />
      </WrapperTitle>
      <WrapperContent
        className={
          props.caretData.classNamePreffix + "-" + props.caretData.label
        }
      >
        {props.caretData.component}
      </WrapperContent>
    </Wrapper>
  )
}

export default CaretComponent
