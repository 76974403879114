export const dropdownList: Array<{
  title: string
  list: Array<{ item: string; href: null | string; hashParameter?: any }>
}> = [
  {
    title: "dropdownList.personalArea",
    list: [
      { item: "dropdownList.personal.editProfile", href: null },
      { item: "dropdownList.personal.myEvaluations", href: null },
      { item: "dropdownList.personal.settings", href: null },
      { item: "dropdownList.admin.aboutHelp", href: null },
      { item: "dropdownList.personal.logout", href: null },
    ],
  },
  {
    title: "dropdownList.adminArea",
    list: [
      { item: "dropdownList.admin.settings", href: null },
      { item: "dropdownList.admin.dataApp", href: null },
    ],
  },
]
