import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { BehaviorSubject } from "rxjs"
import { bufferTime, distinctUntilChanged } from "rxjs/operators"

import { searchingItem } from "../../../../actions/index"
import { connectWithQuery } from "../../../utils/algolia_helper"

import Input from "../../../0_atoms/Input/Input"

const MySearchBox = ({ currentRefinement, refine, searchingItem }) => {
  const { t } = useTranslation("common")
  const [searched, setSearched] = useState("")
  const [subject] = useState(new BehaviorSubject(""))

  useEffect(() => {
    subject
      .pipe(bufferTime(500))
      .pipe(
        distinctUntilChanged((p, q) => {
          let res = false
          if (q.length === 0 || p.length === 0) res = true
          return res
        })
      )
      .subscribe(e => {
        console.log("searcing item", e)
        refine(e[e.length - 1])
        searchingItem(e[e.length - 1])
      })

    return () => subject.unsubscribe()
  }, [])

  useEffect(() => subject.next(searched), [searched])

  console.log("searched", searched)

  return (
    <Input
      style={{
        margin: "20px 40px 0px",
        width: "calc(100% - 80px)",
        maxWidth: "450px",
      }}
      type="search"
      placeholder={t("dishName")}
      value={searched}
      onChange={e => setSearched(e.currentTarget.value)}
    />
  )
}

MySearchBox.propTypes = {
  searchingItem: PropTypes.func.isRequired,
}

const mapDispatchToMySearchBox = dispatch => ({
  searchingItem: item => dispatch(searchingItem(item)),
})

const mapStateToMySearchBox = state => ({
  state: state,
})

export default connectWithQuery(
  connect(mapStateToMySearchBox, mapDispatchToMySearchBox)(MySearchBox)
)
