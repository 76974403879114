import React, { useEffect, useContext } from "react"
import { connect } from "react-redux"
import propTypes from "prop-types"
import { connectHits } from "react-instantsearch-dom"
import { useTranslation } from "react-i18next"

import { settingHashParameters } from "../../../utils/urlParams"
import { selectRest } from "../../../../actions/selectedRest"
import { HashValuesContext } from "../../../constants/index"
import { fetchingEvaluations } from "../../../../actions/evaluations"

import InfiniteScroll from "../../../1_molecules/InfiniteScroll/InfiniteScroll"

const Hits = ({
  hits,
  dispatch,
  state,
}) => {
  const { t } = useTranslation("common")
  const hashValues = useContext(HashValuesContext)

  useEffect(() => {
    if (hashValues.find === "search") dispatch(fetchingEvaluations(null, null))
  }, [JSON.stringify(hashValues)])

  const handleSelectRest = (rest, newItem) => {
    if (newItem && !state.searchingItem.item)
      return alert(t("evaluate.evaluate2.noName"))

    if (newItem && state.searchingItem.item) {
      dispatch(selectRest(state.searchingItem.item, newItem, 'evaluate'))
      return settingHashParameters({
        evaluate: "dish",
        selected: encodeURIComponent(JSON.stringify({ restName: state.searchingItem.item })),
      })
    }

    dispatch(selectRest(rest.objectID, newItem, 'evaluate'))

    settingHashParameters({
      evaluate: "dish",
      selected: encodeURIComponent(JSON.stringify({ restName: rest.name, restUid: rest.objectID })),
    })
  }

  return (
    <InfiniteScroll
      newItem
      labelNewItem={"evaluate.evaluate2.newItem"}
      items={hits}
      onSelect={handleSelectRest}
    />
  )
}

Hits.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

// const mapDispatchToPropsHits = dispatch => ({
//   selectDish: (dish, page, newItem, dishRests, rest) =>
//     dispatch(selectDish(dish, page, newItem, dishRests, rest)),
//   selectRest: (rest, newItem) => dispatch(selectRest(rest, newItem)),
// })

const mapStateToPropsHits = state => ({
  state: state,
})

export default connectHits(connect(mapStateToPropsHits)(Hits))
