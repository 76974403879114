import React, { useState, useEffect, useContext } from "react"
import { connect } from "react-redux"
import propTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { selectDish } from "../../../../actions/selectedDish"
import { selectRest } from "../../../../actions/selectedRest"
import { PropsWrappers } from "../../../constants/index"
import { HashValuesContext } from "../../../constants/index"
import { ExampleData } from "../../../utils/data/data"

import RestaurantFound from "./RestaurantFound"

interface PropsWrapperEvaluate extends PropsWrappers {
  widthCard?: number
  fetching?: Function
}

const WrapperEvaluate: React.FC<PropsWrapperEvaluate> = props => {
  const { t } = useTranslation("common")
  const [restName, setRestName] = useState("")
  const [restUid, setRestUid] = useState("")
  const [loaded, setLoaded] = useState(false)
  const hashValues = useContext(HashValuesContext)

  useEffect(() => {
    if (hashValues.selected !== "" && hashValues.selected !== "none") {
      setRestName(JSON.parse(decodeURIComponent(hashValues.selected)).restName)
      setRestUid(JSON.parse(decodeURIComponent(hashValues.selected)).restUid)
    }
  }, [JSON.stringify(hashValues)])

  useEffect(() => {
    if (
      hashValues.find === "restaurant" &&
      restUid &&
      restUid !== "" &&
      restUid !== undefined
    ) {
      props.dispatch(selectRest(restUid, false, 'find'))
      props.fetching(true)
    }
  }, [restUid])

  useEffect(() => {
    if (
      props.state.selectedRest.dishes &&
      props.state.selectedRest.dishes.length > 0
    ) {
      props.dispatch(selectDish(props.state.selectedRest.dishes[0].dish.uid, false))
    }
  }, [JSON.stringify(props.state.selectedRest.dishes)])

  useEffect(() => {
    if (!(props.state.evaluations && props.state.evaluations.evaluations))
      setLoaded(false)
    if (hashValues.find === "restaurant") {
      if (
        props.state.selectedRest.restDoc &&
        props.state.selectedRest.dishes &&
        props.state.selectedDish.dishDoc &&
        props.state.evaluations &&
        props.state.evaluations.evaluations
      ) {
        setLoaded(true)
        props.fetching(false)
      } else {
        setLoaded(false)
        props.fetching(true)
      }
    }
  }, [props.state.evaluations])

  if (hashValues.find !== "restaurant") return null

  return loaded ? (
    <RestaurantFound
      rest={{
        ...props.state.selectedRest.restDoc,
        dishes: props.state.selectedRest.dishes,
      }}
      selectedDish={props.state.selectedDish.dishDoc}
      evaluations={props.state.evaluations.evaluations}
    />
  ) : null
}

WrapperEvaluate.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(WrapperEvaluate)
