const constants = {
    LOGIN: "LOGIN",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_DONE: "LOGIN_DONE",
    LOGIN_ERROR: "LOGIN_ERROR",
    LOGIN_CLEAN_DATA: "LOGIN_CLEAN_DATA",

    LOGOUT: "LOGOUT",
    EVALUATE: "EVALUATE",
    SEARCH: "SEARCH",
    FETCH_RESTAURANTS: "FETCH_RESTAURANTS",
    CANCEL_FETCHING: "CANCEL_FETCHING",
    ADD_ERROR: "ADD_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",

    // USER AUTO DETECT
    FETCHING_USER: "FETCHING_USER",
    DETECTED_USER: "DETECTED_USER",
    NON_LOGGED_USER: "NON_LOGGED_USER",

    LOG_OUT: "LOG_OUT",
    EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
    EMAIL_NOT_VERIFIED_ERROR: "EMAIL_NOT_VERIFIED_ERROR",

    // FORGET EMAIL
    FORGET_EMAIL_REQUEST: "FORGET_EMAIL_REQUEST",
    FORGET_EMAIL_ERROR: "FORGET_EMAIL_ERROR",
    FORGET_EMAIL_SENT: "FORGET_EMAIL_SENT",


    // REGISTER
    REGISTER_REQUEST: "REGISTER_REQUEST",
    REGISTER_ERROR: "REGISTER_ERROR",
    REGISTER_EMAIL_SENT: "REGISTER_EMAIL_SENT",

    // WRITE LOGIN DATA ON THE DATABASE
    START_WRITING: 'START_WRITING',
    WRITING_LOGIN_DATA_DONE: 'WRITING_LOGIN_DATA_DONE',
    WRITING_LOGIN_DATA_EROR: 'WRITING_LOGIN_DATA_EROR',

    // UPDATE USER CONFIGS
    UPDATING_USER_CONFIGS: 'UPDATING_USER_CONFIGS',
    UPDATED_USER_CONFIGS: 'UPDATED_USER_CONFIGS',
    UPDATING_USER_CONFIGS_ERROR: 'UPDATING_USER_CONFIGS_ERROR',

    // NAVIGATE
    GO_TO: 'GO_TO',

    //FIND DISH
    SELECTED_DISH: 'SELECTED_DISH',
    DISH_FETCHED: 'DISH_FETCHED',
    RESTS_DISH_FETCHING: 'RESTS_DISH_FETCHING',
    RESTS_DISH_FETCHED: 'RESTS_DISH_FETCHED',
    UNSELECTED_DISH: 'UNSELECTED_DISH',

    //FIND FETCH_RESTAURANTS
    UNSELECTED_REST: 'UNSELECTED_REST',
    SELECTED_REST: 'SELECTED_REST',
    REST_FETCHED: 'REST_FETCHED',
    DISHES_REST_FETCHING: 'DISHES_REST_FETCHING',
    DISHES_REST_FETCHED: 'DISHES_REST_FETCHED',

    // SAVING EVALUATION
    EVALUATION_SAVING: 'EVALUATION_SAVING',
    EVALUATION_SAVE_DONE: 'EVALUATION_SAVE_DONE',
    EVALUATION_SAVE_ERROR: 'EVALUATION_SAVE_ERROR',

    // SAVING DISH
    DISH_SAVING: 'DISH_SAVING',
    DISH_SAVE_DONE: 'DISH_SAVE_DONE',
    DISH_SAVE_ERROR: 'DISH_SAVE_ERROR',


    // SAVING RESTAURANT
    RESTAURANT_SAVING: 'RESTAURANT_SAVING',
    RESTAURANT_SAVE_DONE: 'RESTAURANT_SAVE_DONE',
    RESTAURANT_SAVE_ERROR: 'RESTAURANT_SAVE_ERROR',

    // SEARCHING ITEM

    SEARCHING_ITEM: 'SEARCHING_ITEM',

    // FETCHING KPIS

    KPIS_FETCHED: 'KPIS_FETCHED',

    // FETCHING CONFIGS

    CONFIG_FETCHED: 'CONFIG_FETCHED',

    // SAVING CONFIGS

    CONFIG_SAVING: 'CONFIG_SAVING',
    CONFIG_SAVE_DONE: 'CONFIG_SAVE_DONE',
    CONFIG_SAVE_ERROR: 'CONFIG_SAVE_ERROR',

    // PERSONAL EVALS

    PERSONAL_EVALS_FETCHED: 'PERSONAL_EVALS_FETCHED',

    // EVALS FETCHING
    EVALS_NULLING: 'EVALS_NULLING',
    EVALS_FETCHING: 'EVALS_FETCHING',
    EVALS_FETCHED: 'EVALS_FETCHED',
    EVALS_FETCH_ERROR: 'EVALS_FETCH_ERROR'
    
}

export default constants