import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import propTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { evaluate } from "../../../../actions/evaluate"
import { PropsWrappers } from "../../../constants/index"

import Evaluate from "./Evaluate"

interface PropsWrapperEvaluate extends PropsWrappers {
  widthCard?: number
  fetching?: Function
}

const WrapperEvaluate: React.FC<PropsWrapperEvaluate> = props => {
  const { t } = useTranslation("common")
  const [resetComponent, setResetComponent] = useState(false)
  const handleEvaluation = form => {
    props.fetching(true)
    return props.dispatch(evaluate(form, props.state.userFetch, t("evaluate.evaluate4.saved")))
  }

  useEffect(() => {
    setResetComponent(true)
    props.fetching(false)
    setTimeout(() => {
      setResetComponent(false)}
      ,500)
  },[JSON.stringify(props.state.selectedDish.dishDoc)])

  return (
    <Evaluate
      widthCard={props.widthCard}
      onEvaluate={handleEvaluation}
      user={{
        email: props.state.userFetch.email,
        uid: props.state.userFetch.uid,
        language: props.state.userFetch.language,
      }}
      resetComponent={resetComponent}
    />
  )
}

WrapperEvaluate.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(WrapperEvaluate)
