import React, { useContext, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { gsap } from "gsap"

import { Colors, Shadows } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"
import { PropsRestsDishes, PropsDishesStore } from "../../utils/data/dishData"
import { tweenOpacityShadowOpacity } from "../../utils/animations"

import ItemDescription from "../Descriptions/ItemDescription"

const Wrapper = styled.div`
  margin: auto;
  height: 300px;
  margin-top: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid rgba(177, 175, 175, 0.6);
  border-radius: 5px;
  opacity: 0;
  padding-bottom: 10px;
  ${props =>
    props.transition
      ? "box-shadow: " + Shadows[props.styleMode][props.theme].Lm5upin0 + ";"
      : Shadows[props.styleMode][props.theme].Lm5upin}

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => Colors[props.styleMode][props.theme].scrollTrack};
    border-radius: 5px;
    ${props =>
      props.styleMode === "new" &&
      `
        box-shadow: inset -2px -2px 4px ${props =>
          props.theme === "light" ? "#898989" : "#cfcfcf"};
      `}
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => Colors[props.styleMode][props.theme].scrollBar};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => Colors[props.styleMode][props.theme].scrollHover};
    border-radius: 5px;
  }

  ${props =>
    props.styleMode === "new" &&
    css`
      border: none;
    `}
`

interface PropsInfiniteScrollDishes {
  items: Array<PropsDishesStore | PropsRestsDishes>
  onClickItem?: Function
  onClickDropdown?: Function
  transition?: boolean
}

export default (props: PropsInfiniteScrollDishes): JSX.Element => {
  const theme = useContext(ThemeContext)
  const { onClickItem, onClickDropdown } = props
  const [tweenRestaurants] = useState(gsap.timeline({ paused: true }))

  useEffect(() => {
    tweenOpacityShadowOpacity(
      tweenRestaurants,
      ".infinite-scroll-dishes-0.",
      ".itemDescription",
      theme.styleMode,
      theme.theme,
      "Lm5upin1"
    )
    tweenRestaurants.reversed(false)
  }, [])

  return (
    <Wrapper
      className={"infinite-scroll-dishes-0 " + theme.styleMode + theme.theme}
      {...theme}
    >
      {props.items.map((e, i) => (
        <ItemDescription onClick={() => onClickItem(i)} key={i} item={e} />
      ))}
    </Wrapper>
  )
}
