import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import Typo from "../../../0_atoms/Typo/Typo"
import SimpleSlider from "../../../1_molecules/SimpleSlider/SimpleSlider"

const options = ["style", "theme", "language"]

const Wrapper = styled.div`
  flex-direction: column;
  animation: 0.51s opening;

  @keyframes opening {
    from {
      display: none;
      opacity: 0;
      height: 0px;
    }
    20% {
      display: flex;
      height: 0px;
    }
    60% {
      height: 166px;
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      height: unset;
    }
  }

  .configurations-title {
    display: block;
    padding-bottom: 10px;
  }

  .config-slider {
    min-width: 220px;
    padding-top: 10px;
  }
`

interface PropsConfigurations {
  style: string
  theme: string
  language: string
  dispatch?: any
}

export default (props: PropsConfigurations): JSX.Element => {
  const [style, setStyle] = useState(false)
  const [theme, setTheme] = useState(false)
  const [language, setLanguage] = useState(false)
  const [watcher, setWatcher] = useState(false)

  useEffect(() => {
    setStyle(props.style === null ? false : props.style !== "classic")
    setTheme(props.theme === null ? false : props.theme !== "light")
    setLanguage(props.language === null ? false : props.language !== "en")
  }, [props.style, props.theme, props.language])

  useEffect(() => {
    if (props.style !== (style ? "new" : "classic") && watcher) {
      localStorage.setItem("style", style ? "new" : "classic")
      props.dispatch({
        style: style ? "new" : "classic",
        theme: theme ? "dark" : "light",
        language: language ? "br" : "en",
      })
    }
  }, [style])

  useEffect(() => {
    if (props.theme !== (theme ? "dark" : "light") && watcher) {
      localStorage.setItem("theme", theme ? "dark" : "light")
      console.log("theme", theme ? "dark" : "light")
      props.dispatch({
        style: style ? "new" : "classic",
        theme: theme ? "dark" : "light",
        language: language ? "br" : "en",
      })
    }
  }, [theme])

  useEffect(() => {
    if (props.language !== (language ? "br" : "en") && watcher) {
      localStorage.setItem("language", language ? "br" : "en")
      props.dispatch({
        style: style ? "new" : "classic",
        theme: theme ? "dark" : "light",
        language: language ? "br" : "en",
      })
    }
  }, [language])

  const states = [style, theme, language]
  const setStates = [setStyle, setTheme, setLanguage]
  const { t } = useTranslation("common")

  return (
    <Wrapper className="user-configurations-items">
      <Typo cardDestak className="configurations-title">
        {t("configs.configurations.title")}
      </Typo>

      {options.map((e, i) => (
        <SimpleSlider
          key={i}
          className="config-slider"
          text={t("configs.configurations." + e)}
          setFunction={value => {
            setWatcher(true)
            return setStates[i](value)
          }}
          booleanFunction={states[i]}
        />
      ))}
    </Wrapper>
  )
}
