import React, { useContext, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { Colors, Shadows } from "../../constants/palette"
import Typo from "../../0_atoms/Typo/Typo"
import { useTranslation } from "react-i18next"
import { singularOrPlural } from "../../utils/misc"
import { ThemeContext } from "../../constants/index"

const ItemRow = styled.div`
  height: 25px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  cursor: pointer;

  color: ${props => Colors[props.styleMode][props.theme].letterUsual};

  border-bottom: 1px solid #b1afaf;
`
//background-color: ${props => Colors[props.styleMode][props.theme].L15};

const Wrapper = styled.div`
  margin: 20px 40px 0px;
  maxwidth: 460px;
  height: 100px;

  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #b1afaf;
  border-radius: 5px;
  padding-bottom: 10px;
  ${props => Shadows[props.styleMode][props.theme].Lm5upin}
  background-color: ${props =>
    props.styleMode === "new"
      ? "none"
      : Colors[props.styleMode][props.theme].L10};

  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => Colors[props.styleMode][props.theme].scrollTrack};
    border-radius: 5px;
    ${props =>
      props.styleMode === "new" &&
      `
        box-shadow: inset -2px -2px 4px ${props =>
          props.theme === "light" ? "#898989" : "#cfcfcf"};
      `}
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => Colors[props.styleMode][props.theme].scrollBar};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${props => Colors[props.styleMode][props.theme].scrollHover};
    border-radius: 5px;
  }

  ${props =>
    props.styleMode === "new" &&
    css`
      border: none;
    `}
`

interface PropsInfiniteScroll {
  items?: Array<any>
  children?: any
  onSelect?: any
  newItem?: boolean
  labelNewItem?: string
  onSelectNewItem?: Function
  concurrentItems?: Array<any>
}

export default (props: PropsInfiniteScroll): JSX.Element => {
  const [duplicatedItems, setDuplicatedItems] = useState([])
  const [items, setItems] = useState(
    props.items.sort((a, b) => b.evaluations - a.evaluations)
  )
  const { t } = useTranslation("common")
  const theme = useContext(ThemeContext)

  useEffect(
    () => setItems(props.items.sort((a, b) => b.evaluations - a.evaluations)),
    [JSON.stringify(props.items)]
  )

  useEffect(() => {
    if (props.items && props.concurrentItems) {
      setDuplicatedItems(
        props.items
          .filter(
            e =>
              props.concurrentItems.filter(f => f.uid === e.objectID).length > 0
          )
          .sort((a, b) => b.evaluations - a.evaluations)
      )

      setItems(
        props.items
          .filter(
            e =>
              props.concurrentItems.filter(f => f.uid === e.objectID).length ===
              0
          )
          .sort((a, b) => b.evaluations - a.evaluations)
      )
    }
  }, [JSON.stringify(props.items), JSON.stringify(props.concurrentItems)])

  if (items === undefined) return null

  return (
    <Wrapper {...theme}>
      {duplicatedItems.length > 0 &&
        props.concurrentItems &&
        props.concurrentItems.length > 0 && (
          <>
            <ItemRow {...theme} style={{ cursor: "default" }}>
              <Typo style={{ cursor: "default" }} nameDish>
                Dishes already evaluated on the restaurant
              </Typo>
            </ItemRow>

            {duplicatedItems.map((e, i) => (
              <ItemRow
                key={i}
                {...theme}
                onClick={() =>
                  props.onSelect ? props.onSelect(e, false) : null
                }
              >
                <Typo
                  title={e.name}
                  style={{
                    whiteSpace: "nowrap",
                    zIndex: 5,
                    marginLeft: "10px",
                  }}
                  nameDish
                >
                  {e.name}
                </Typo>
                <Typo
                  style={{
                    opacity: 0.6,
                    fontSize: "9.5px",
                    minWidth: "85px",
                    marginRight: "10px",
                  }}
                >
                  {e.evaluations} -{" "}
                  {
                    props.concurrentItems.filter(f => f.uid === e.objectID)[0]
                      .evaluations
                  }{" "}
                  {t(singularOrPlural(125, "evaluation", "evaluations"))}
                </Typo>
              </ItemRow>
            ))}
            <ItemRow {...theme} style={{ cursor: "default" }}>
              <Typo style={{ cursor: "default" }} nameDish>
                Dishes not yet evaluated on the restaurant
              </Typo>
            </ItemRow>
          </>
        )}

      {items.map((e, i) => (
        <ItemRow
          key={i}
          {...theme}
          onClick={() => (props.onSelect ? props.onSelect(e, false) : null)}
        >
          <Typo
            title={e.name}
            style={{
              whiteSpace: "nowrap",
              zIndex: 5,
              marginLeft: "10px",
            }}
            nameDish
          >
            {e.name}
          </Typo>
          <Typo
            style={{
              opacity: 0.6,
              fontSize: "9.5px",
              minWidth: "85px",
              marginRight: "10px",
            }}
          >
            {e.evaluations}{" "}
            {t(singularOrPlural(125, "evaluation", "evaluations"))}
          </Typo>
        </ItemRow>
      ))}
      {props.newItem && (
        <ItemRow
          {...theme}
          onClick={() => (props.onSelect ? props.onSelect("test", true) : null)}
        >
          <Typo nameDish>{t(props.labelNewItem)}</Typo>
        </ItemRow>
      )}
    </Wrapper>
  )
}
