import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { gsap, TweenMax } from "gsap"

import { Colors } from "../../../constants/palette"
import { HashValuesContext } from "../../../constants/index"
import { tweenConfigurationsPartAppear } from "../../../utils/animations"

import Typo from "../../../0_atoms/Typo/Typo"
import Button from "../../../0_atoms/Button/Button"
import TextArea from "../../../0_atoms/TextArea/TextArea"
import SimpleSlider from "../../../1_molecules/SimpleSlider/SimpleSlider"
import EvaluateCharacteristic from "../../../1_molecules/EvaluateCharacteristic/EvaluateCharacteristic"

const ComponentsWrapper = styled.div`
  display: none;
  flex-direction: column;
  opacity: 0;
  height: 0px;
`

interface PropsEvaluate {
  widthCard: number
  onEvaluate?: Function
  user: {
    email: string
    uid: string
    language: string
  },
  resetComponent?: boolean
}

export default (props: PropsEvaluate): JSX.Element => {
  const [stateTaste, setStateTaste] = useState(0)
  const [statePrice, setStatePrice] = useState(0)
  const [stateTime, setStateTime] = useState(0)
  const [statePresentation, setStatePresentation] = useState(0)
  const [stateSize, setStateSize] = useState(0)
  const [anonymous, setAnonymous] = useState(false)
  const [comments, setComments] = useState("")
  const hashValues = useContext(HashValuesContext)
  const [restName, setRestName] = useState("")
  const [restUid, setRestUid] = useState("")
  const [dishName, setDishName] = useState("")
  const [dishUid, setDishUid] = useState("")
  const [tweenEvaluateGrades]: any = useState(gsap.timeline({ paused: true }))
  const TweenMaxTyped: any = TweenMax

  useEffect(() => {
    tweenConfigurationsPartAppear(
      tweenEvaluateGrades,
      ".evaluate-grades-items",
      432
    )
    tweenEvaluateGrades.reversed(false)
    return () => TweenMaxTyped.delayedCall(1, () => tweenEvaluateGrades.reversed(true))
  },[])

  useEffect(() => {
    if (hashValues.selected !== "" && hashValues.selected !== "none") {
      setRestName(JSON.parse(decodeURIComponent(hashValues.selected)).restName)
      setRestUid(JSON.parse(decodeURIComponent(hashValues.selected)).restUid)
      setDishName(JSON.parse(decodeURIComponent(hashValues.selected)).dishName)
      setDishUid(JSON.parse(decodeURIComponent(hashValues.selected)).dishUid)
    }
  }, [JSON.stringify(hashValues)])

  useEffect(() => {
    if(props.resetComponent){
      setStatePresentation(0)
      setStatePrice(0)
      setStateSize(0)
      setStateTaste(0)
      setStateTime(0)
      setAnonymous(false)
      setComments("")
    }
  },[props.resetComponent])

  const handleChange = (e, idx) => {
    switch (idx) {
      case 0:
        return setStateTaste(parseInt(e.target.value))
      case 1:
        return setStateTime(parseInt(e.target.value))
      case 2:
        return setStateSize(parseInt(e.target.value))
      case 3:
        return setStatePresentation(parseInt(e.target.value))
      case 4:
        return setStatePrice(parseInt(e.target.value))
      default:
    }
  }

  const chooseValue = idx => {
    switch (idx) {
      case 0:
        return stateTaste
      case 1:
        return stateTime
      case 2:
        return stateSize
      case 3:
        return statePresentation
      case 4:
        return statePrice
      default:
    }
  }
  const { t } = useTranslation("common")
  const domains = ["taste", "time", "size", "presentation", "price"]

  return (
    <ComponentsWrapper className="evaluate-grades-items">
      {/* <Typo cardDestak style={{ display: "block" }}>
        {t("evaluate.evaluate1.title")}
      </Typo>
      <Typo
        style={{
          margin: "15px 40px 0px",
          display: "block",
        }}
        cardText
      >
        {t("evaluate.evaluate1.description")}
      </Typo> */}
      <Typo style={{ width: "100%", paddingTop: "10px" }} cardText>
        {t("atTheRestaurant")}
      </Typo>
      <Typo
        style={{ margin: "0px 40px 0px", width: "calc(100% - 80px)" }}
        title={restName}
        button
      >
        {restName}
      </Typo>
      <Typo
        style={{ margin: "10px 40px 0px", width: "calc(100% - 80px)" }}
        cardText
      >
        {t("dishName")}
      </Typo>
      <Typo style={{ width: "100%" }} title={dishName} button>
        {dishName}
      </Typo>

      {domains.map((e, idx) => (
        <EvaluateCharacteristic
          key={idx}
          onChangeValue={e => handleChange(e, idx)}
          firstPart={"evaluate.evaluate4." + e + ".firstPart"}
          domain={e}
          secondPart={"evaluate.evaluate4." + e + ".secondPart"}
          color={Colors[e]}
          value={chooseValue(idx)}
          low={"evaluate.evaluate4." + e + ".low"}
          high={"evaluate.evaluate4." + e + ".high"}
          widthCard={props.widthCard}
          resetComponent={props.resetComponent}
        />
      ))}

      <Typo
        style={{
          float: "left",
          paddingLeft: "40px",
          opacity: 0.95,
          paddingTop: "0px",
          fontSize: "12px",
          marginLeft: "0px",
          marginTop: "20px",
        }}
      >
        {t("evaluate.evaluate4.comments")}:
      </Typo>
      <TextArea
        placeholder={"..."}
        onChange={setComments}
        value={comments}
        style={{
          margin: "0px 30px 0px",
          height: "60px",
          textAlign: "left",
          padding: "0px 10px",
        }}
      />
      <SimpleSlider
        text={t("evaluate.evaluate4.anonymous")}
        setFunction={setAnonymous}
        booleanFunction={anonymous}
        styleTypo={{
          minWidth: "220px",
          paddingTop: "10px",
        }}
      />
      <Button
        onClick={() => {
          let evaluation = {
            anonymous: anonymous,
            comments: comments,
            tags: [],
            creationTime: new Date(),
            dish: {
              name: dishName,
              uid: dishUid,
            },
            rest: {
              name: restName,
              uid: restUid,
            },
            grades: {
              taste: stateTaste,
              price: statePrice,
              time: stateTime,
              presentation: statePresentation,
              size: stateSize,
            },
            user: {
              email: props.user.email,
              uid: props.user.uid,
              language: props.user.language,
            },
          }
          props.onEvaluate(evaluation)
        }}
        style={{
          width: "min-content",
          whiteSpace: "nowrap",
          margin: "20px auto 20px",
        }}
      >
        {t("evaluate.evaluate4.save")}
      </Button>
    </ComponentsWrapper>
  )
}
