import React, { useEffect, useRef } from "react"
import Icon from "../../../media/Logo3cGlow2c2"
import { TweenMax } from "gsap"

const animateShine = (
  selector,
  duration,
  offsetIn,
  offsetOut,
  repeatDelay,
  factor
) =>
  TweenMax.fromTo(
    selector,
    duration * factor,
    { attr: { offset: offsetIn } },
    { attr: { offset: offsetOut }, repeat: -1, ease: "power3" }
  )
    // .delay(delay)
    .repeatDelay(repeatDelay + offsetIn * factor)
    .play()

export default (props: {
  width?: number
  style?: React.CSSProperties
}): JSX.Element => {
  useEffect(() => {
    const factor = 1.8,
      repeatDelay = 1.5
    TweenMax.fromTo(
      ".texture-medal-shining",
      0.7,
      { opacity: 0 },
      { opacity: 1, ease: "power3" }
    )
    animateShine(".linearGradient-stop-1", 1, 0, 1, repeatDelay, factor)
    animateShine(".linearGradient-stop-2", 0.8, 0.2, 1, repeatDelay, factor)
    animateShine(".linearGradient-stop-3", 0.5, 0.5, 1, repeatDelay, factor)
  }, [])

  return <Icon js style={props.style} width={props.width ? props.width : 100} />
}
