import { combineReducers } from "redux"

import { userFetch } from "./userFetch"
import { appConfigs } from "./appConfigs"
import { kpis } from "./kpis"
import { selectedDish } from "./selectedDish"
import { selectedRest } from "./selectedRest"
import { evaluations } from './evaluations'

import { evaluate, searchingItem } from "./reducers"

export default combineReducers({
  userFetch,
  appConfigs,
  selectedDish,
  selectedRest,
  evaluate,
  searchingItem,
  kpis,
  evaluations
})
