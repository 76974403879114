// function paramsToObject(entries) {
//   let result = {}
//   for (let entry of entries) {
//     // each 'entry' is a [key, value] tupple
//     const [key, value] = entry
//     if (!(key in result)) result[key] = value
//   }
//   console.log('result on paramsToObject', result)
//   return result
// }

// export const urlParamsDecode = (url: Location): any => {
//   const urlParams = new URLSearchParams(url.hash.slice(1))
//   return paramsToObject(urlParams.entries())
// }

// export default urlParamsDecode

export const getHashChanges = ():any => {
  var result = window.location.hash
    .substr(1)
    .split("&")
    .reduce((res, item) => {
      var parts = item.split("=")
      res[parts[0]] = parts[1]
      return res
    }, {})
  return result
}

export const values = {
  find: "presentation",
  evaluate: "presentation",
  configurations: "none",
  dish: "test",
}

export const settingHashParameters = values => {
  let parsedValues = ""
  if (Object.keys(values).length === 1)
    parsedValues = Object.keys(values)[0] + "=" + values[Object.keys(values)[0]]
  if (Object.keys(values).length > 1)
    Object.keys(values).map((e, i) => {
      parsedValues = parsedValues + (i > 0 ? "&" : "") + e + "=" + values[e]
    })
  window.location.hash = parsedValues
}