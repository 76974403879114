import React, { useContext } from "react"
import { PropsDishesStore, PropsRestsDishes } from "../../utils/data/dishData"
import styled, { css } from "styled-components"
import { singularOrPlural } from "../../utils/misc"
import { ThemeContext } from "../../constants/index"

import Grades from "../../0_atoms/Grades/Grades"
import Typo from "../../0_atoms/Typo/Typo"
import { Colors } from "../../constants/palette"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div.attrs({ className: "itemDescription" })`
  padding-top: 5px;
  display: flex;
  flex-flow: column;
  background-color: ${props =>
    props.styleMode === "new"
      ? "none"
      : Colors[props.styleMode][props.theme].L10};
  border-bottom: 1px solid rgba(177, 175, 175, 0.6);
  cursor: pointer;
  ${props =>
    props.transition &&
    css`
      display: none;
      opacity: 0;
    `}
`

const WrapperTitle = styled.div`
  display: flex;
  margin-bottom: 0px;
`

interface PropsItemDescription {
  item: PropsDishesStore | PropsRestsDishes
  onClick?: Function
}

export default (props: PropsItemDescription): JSX.Element => {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation("common")
  let tags
  
  if (props.item.tags && props.item.tags.length > 0)
    tags =
      "Tags:" +
      props.item.tags.map(
        (e, i) =>
          " " + t("tags." + e) + (i === props.item.tags.length - 1 ? "." : "")
      )

  return (
    <Wrapper {...theme} onClick={props.onClick}>
      <WrapperTitle>
        <Typo
          style={{
            marginLeft: "5px",
            fontSize: "13px",
            marginBottom: "0px",
            paddingBottom: "0px",
            textAlign: "left",
            marginTop: "0px",
            color: Colors[theme.styleMode][theme.theme].letterAuxiliary2,
          }}
          cardText
        >
          {props.item.name}
        </Typo>
        <Typo
          style={{
            padding: "4px",
            paddingBottom: "0px",
            fontSize: "9px",
            marginRight: "4px",
            lineHeight: "15px",
            minWidth: "81px",
            marginTop: "0px",
            color: Colors[theme.styleMode][theme.theme].letterAuxiliary2,
          }}
        >
          {props.item.evaluations}{" "}
          {t(
            singularOrPlural(
              props.item.evaluations,
              "evaluation",
              "evaluations"
            )
          )}
        </Typo>
      </WrapperTitle>
      <Typo
        style={{
          paddingBottom: "0px",
          fontSize: "9px",
          marginLeft: "5px",
          lineHeight: "15px",
          minHeight: "15px",
          minWidth: "81px",
          marginTop: "0px",
          paddingTop: "0px",
          textAlign: "left",
          color: Colors[theme.styleMode][theme.theme].letterAuxiliary2,
        }}
        title={tags}
      >
        {tags}
      </Typo>
      <Grades
        grades_avg={props.item.grades_avg[0] || props.item.grades_avg}
        {...props}
      />
    </Wrapper>
  )
}
