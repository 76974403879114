import React from "react"
import { useTranslation } from "react-i18next"

import Typo from "../../0_atoms/Typo/Typo"

export interface PropsDropdownList {
  actionFromObject(string): void
  setShow: Function
  dropdownList: Array<{
    item: string
    href: null | string
    hashParameter?: any
  }>
}

export default (props: PropsDropdownList): JSX.Element => {
  const { t } = useTranslation("common")

  return (
    <>
      {props.dropdownList.map((e: any, i: number) => (
        <div key={i}>
          <a className={"dropdown-title"} href="#">
            <Typo dropdown dropdownTitle>
              {t(e.title)}
            </Typo>
          </a>
          {e.list.map((f: { item: string; href: string }, j: number) => (
            <a
              key={j}
              className={"dropdown"}
              onClick={() => {
                props.setShow(false)
                props.actionFromObject(f.item)
              }}
              href={f.href}
            >
              <Typo dropdown>{t(f.item)}</Typo>
            </a>
          ))}
        </div>
      ))}
    </>
  )
}
