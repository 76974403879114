import React from "react"
import propTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { PropsWrappers } from "../../constants/index"
import { clickRegister } from "../../../actions/user"

import DropdownSignup from "./Signup"

interface PropsSignup extends PropsWrappers {
  pressed: boolean
}

const DropdownSignUpWrapper: React.FC<PropsSignup> = props => {
  const { t } = useTranslation("common")

  const handleSignUp = (email, password) => {
    if (email === "" || password === "")
      return alert(t("login.noData"))
    props.dispatch(clickRegister(email, password, props.state.userFetch))
  }

  return <DropdownSignup handleSignUp={handleSignUp} pressed={props.pressed} />
}

DropdownSignUpWrapper.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(DropdownSignUpWrapper)
