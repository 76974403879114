import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { gsap, TweenMax } from "gsap"

import { ThemeContext } from "../../../constants/index"
import { singularOrPlural } from "../../../utils/misc"
import { Colors, Shadows } from "../../../constants/palette"
import {
  tweenEffects,
  tweenConfigurationsPartAppear,
} from "../../../utils/animations"
import { ExampleData } from "../../../utils/data/data"

import MapComponent2 from "../../../0_atoms/Map/Map"
import SvgLogo from "../../../0_atoms/Logos/TexturedMedalShine"
import SpiderChartComponent from "../../../0_atoms/Charts/SpiderChart"
import Typo from "../../../0_atoms/Typo/Typo"
import InfiniteScrollItems from "../../../1_molecules/InfiniteScroll/InfiniteScrollItems"
import InfiniteScrollEvaluations from "../../../1_molecules/InfiniteScroll/InfiniteScrollEvaluations"
import CaretComponent4 from "../../../1_molecules/CaretComponent/CaretComponent4"

const ComponentsWrapper = styled.div`
  display: none;
  flex-direction: column;
  opacity: 0;
  height: 0px;
`

const Wrapper = styled.div`
  padding: 0px 15px;
  margin-bottom: 10px;
`

const WrapperLogo = styled.div`
  display: inline-block;
  height: 16px;
  position: absolute;
  margin-top: -8px;
`

const titleStyle = {
  width: "200px",
  margin: "auto",
  marginTop: "15px",
  display: "block",
  paddingBottom: "0px",
}

const auxiliaryTextStyle: React.CSSProperties = {
  padding: "0px",
  paddingTop: "2px",

  marginTop: "0px",

  fontSize: "9px",
  marginRight: "4px",
  lineHeight: "11px",
  letterSpacing: "unset",

  display: "blocks",
  opacity: "unset",
}

interface PropsRestaurantFound {
  rest: any
  showMoreContent?: any
  selectedDish?: any
  evaluations?: any
}

export default (props: PropsRestaurantFound): JSX.Element => {
  const [dishes, setDishes] = useState(false)
  const [
    evaluationsDishesRestaurant,
    setEvaluationsDishesRestaurant,
  ] = useState(false)
  const [map, setMap] = useState(true)
  const [, setCardHeight] = useState(1015)

  const [tweenDishes] = useState(gsap.timeline({ paused: true }))
  const [tweenEvaluations] = useState(gsap.timeline({ paused: true }))
  const [tweenMap] = useState(gsap.timeline({ paused: true }))

  const { t } = useTranslation("common")
  const { styleMode, theme } = useContext(ThemeContext)
  const [dishSelected, setDishSelected] = useState(0)
  const [tweenFindRestaurant]: any = useState(gsap.timeline({ paused: true }))
  const TweenMaxTyped: any = TweenMax

  useEffect(() => {
    tweenEffects(tweenDishes, ".foundRest", "-restaurants", {
      styleMode,
      theme,
    })
    tweenEffects(
      tweenEvaluations,
      ".foundRest",
      "-evaluationsDishesRestaurant",
      { styleMode, theme }
    )
    tweenEffects(tweenMap, ".foundRest", "-map", { styleMode, theme })
    tweenConfigurationsPartAppear(
      tweenFindRestaurant,
      ".find-restaurant-items",
      815
    )
    TweenMaxTyped.delayedCall(1, () => tweenFindRestaurant.reversed(false))

    // tweenMap
    //   .to(".mapComponent", 0.3, {
    //     boxShadow: Shadows[styleMode][theme].Lm5upin1,
    //   })
    //   .to(".mapComponent", 0.3, { opacity: 1, autoAlpha: 1 })

    return () => tweenFindRestaurant.reversed(true)
  }, [])

  // useEffect(() => {
  //   setCardHeight(prevCardHeight => {
  //     let newCardHeight =
  //       785 +
  //       ((evaluationsDishesRestaurant ? 1 : 0) +
  //         (map ? 1 : 0) +
  //         (dishes ? 1 : 0)) *
  //         300
  //     TweenMax.to(".roundButton", 0.3, {
  //       top: newCardHeight / 2 - 12,
  //       delay: newCardHeight > prevCardHeight ? 0 : 0.3,
  //     })
  //     return newCardHeight
  //   })

  //   tweenConfigurationsPartAppear(
  //     tweenFindRestaurant,
  //     ".find-restaurant-items",
  //     815
  //   )

  //   tweenDishes.reversed(!dishes)
  //   tweenEvaluations.reversed(!evaluationsDishesRestaurant)
  //   tweenMap.reversed(!map)
  // }, [map, dishes, evaluationsDishesRestaurant])

  useEffect(() => {
    tweenDishes.reversed(!dishes)
    tweenEvaluations.reversed(!evaluationsDishesRestaurant)
    tweenMap.reversed(!map)
  }, [map, dishes, evaluationsDishesRestaurant])

  useEffect(() => {
    if (evaluationsDishesRestaurant)
      TweenMax.to(".evaluation", 0.3, {
        opacity: 1,
        autoAlpha: 1,
        display: "flex",
      })
  }, [dishSelected])

  return (
    <ComponentsWrapper className="find-restaurant-items">

      <Wrapper>
        <div>
          <Typo style={titleStyle} cardDestak {...props}>
            {props.rest.name}
          </Typo>
          <Typo
            style={{
              ...auxiliaryTextStyle,
              width: "100%",
              display: "block",
              color: Colors[styleMode][theme].letterAuxiliary2,
            }}
            {...props}
          >
            {"Tags:" +
              props.rest.tags.map(
                (e, i) =>
                  " " + e + (i === props.rest.tags.length - 1 ? "." : "")
              )}
          </Typo>
          <Typo
            style={{
              ...auxiliaryTextStyle,
              display: "block",
              color: Colors[styleMode][theme].letterAuxiliary2,
            }}
            {...props}
          >
            {props.rest.evaluations}{" "}
            {t(
              singularOrPlural(
                props.rest.evaluations,
                "evaluation",
                "evaluations"
              )
            )}
          </Typo>
          <Typo
            style={{ ...titleStyle, marginTop: "10px" }}
            cardDestak
            {...props}
          >
            {props.rest.dishes[dishSelected].name}{" "}
            {dishSelected === 0 && (
              <WrapperLogo>
                <SvgLogo width={29} />
              </WrapperLogo>
            )}
          </Typo>
          <Typo
            style={{
              ...auxiliaryTextStyle,
              width: "100%",
              color: Colors[styleMode][theme].letterAuxiliary2,
            }}
            {...props}
          >
            {dishSelected === 0
              ? t("find.foundRest.dishBestEvaluation")
              : t("find.foundRest.selectedDish")}
          </Typo>

          <SpiderChartComponent
            {...props}
            grades_focus={props.rest.dishes[dishSelected].grades_avg}
            grades_basis={props.rest.grades_avg}
          />
        </div>
      </Wrapper>

      {[
        {
          label: "restaurants",
          title: singularOrPlural(
            props.rest.dishes.length,
            t("find.foundRest.dishesInThatRestSing"),
            t("find.foundRest.dishesInThatRest")
          ),
          value: props.rest.dishes.length,
          data: "",
          classNamePreffix: "foundRest",
          callback: () => setDishes(!dishes),
          component: (
            <InfiniteScrollItems
              showMoreContent={props.showMoreContent}
              items={props.rest.dishes}
              onClickItem={setDishSelected}
              transition
              {...props}
            />
          ),
        },
        {
          label: "evaluationsDishesRestaurant",
          title: singularOrPlural(
            props.evaluations.length,
            t("find.foundRest.dishesEvaluationOnRestaurantSing"),
            t("find.foundRest.dishesEvaluationOnRestaurant")
          ),
          value: props.evaluations.length, //props.rest.dishes[dishSelected].evaluationsDocs.length,
          data: "",
          classNamePreffix: "foundRest",
          callback: () =>
            setEvaluationsDishesRestaurant(!evaluationsDishesRestaurant),
          component: (
            <InfiniteScrollEvaluations
              evaluations={props.evaluations}
              onClickItem={i => setDishSelected(i)}
            />
          ),
        },
        {
          label: "map",
          title: t("find.foundRest.map"),
          value: "",
          data: "",
          callbalck: () => setMap(!map),
          classNamePreffix: "foundRest",
          component: (
            <div style={{ marginTop: "15px" }}>
              <MapComponent2
                restSelected={0}
                dish={{ restaurants: [props.rest] }}
              />
            </div>
          ),
        },
      ].map((e, i) => (
        <CaretComponent4
          {...props}
          noDistanceTitle={e.label === "map" ? true : false}
          key={i}
          caretData={e}
          clickOpenCaret={e.callback}
        />
      ))}
    </ComponentsWrapper>
  )
}
