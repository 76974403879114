import C from "../actions/constants"

export const evaluations = (state = null, action) => {
  switch (action.type) {
    // Fetching evaluations
    case C.EVALS_NULLING:
      return Object.assign({}, state, { ...action.payload })
    case C.EVALS_FETCHING:
      return Object.assign({}, state, { ...action.payload })
    case C.EVALS_FETCHED:
      return Object.assign({}, state, { ...action.payload })
    case C.EVALS_FETCH_ERROR:
      return Object.assign({}, state, { ...action.payload })

    default:
      return state
  }
}
