import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { searchingItem } from "../../../../actions/index"
import { connectWithQuery } from "../../../utils/algolia_helper"
import { BehaviorSubject } from "rxjs"
import { bufferTime, distinctUntilChanged } from "rxjs/operators"

import Input from "../../../0_atoms/Input/Input"
import { keys } from "@material-ui/core/styles/createBreakpoints"

const checkIfNeedsToTranslateTag = (
  language,
  typeOfSearch,
  searching,
  tags
) => {
  let res = searching
  if (language === "br" && typeOfSearch.substring(1, 2) === "1" && res.length > 0)
    res = translateTags(searching, tags)
  return res
}

const translateTags = (searching, tags) => Object.keys(tags).find(key => tags[key].includes(searching))

const SearchBox = ({
  currentRefinement,
  refine,
  searchingItem,
  searchStates,
  ...state
}) => {
  const { t } = useTranslation("common")
  const [searched, setSearched] = useState("")
  const [subject] = useState(new BehaviorSubject(""))

  useEffect(() => {
    subject
      .pipe(bufferTime(500))
      .pipe(
        distinctUntilChanged((p, q) => {
          let res = false
          if (q.length === 0 || p.length === 0) res = true
          return res
        })
      )
      .subscribe(e => {
        refine(e[e.length - 1])
        searchingItem(e[e.length - 1])
      })

    return () => subject.unsubscribe()
  }, [])

  useEffect(() => {
    subject.next(
      checkIfNeedsToTranslateTag(
        state.state.userFetch.language,
        searchStates,
        searched,
        state.state.appConfigs.tags
      )
    )
  }, [searched])

  return (
    <Input
      style={{ width: "100%" }}
      type="search"
      placeholder={t("find.findSearch." + searchStates.substring(1, 4))}
      value={searched}
      onChange={e => setSearched(e.currentTarget.value)}
    />
  )
}

SearchBox.propTypes = {
  searchingItem: PropTypes.func.isRequired,
  searchStates: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
}

const mapDispatchToSearchBox = dispatch => ({
  searchingItem: item => dispatch(searchingItem(item)),
})

const mapStateToSearchBox = state => ({
  state: state,
})

export default connectWithQuery(
  connect(mapStateToSearchBox, mapDispatchToSearchBox)(SearchBox)
)
