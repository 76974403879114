import React, { useState, useContext, useEffect } from "react"
import { gsap, TweenMax } from "gsap"

import { settingHashParameters } from "../../../utils/urlParams"

import ItemDescription from "../../../1_molecules/Descriptions/ItemDescription"

export default ({ searchStates, unmount, hits }) => {
  const [tweenHits]: any = useState(gsap.timeline({ paused: true }))

  useEffect(() => {
    tweenHits
      .to(".itemDescription", 0.3, {
        opacity: 1,
        autoAlpha: 1,
        display: "flex",
      })
      .delay(0.3)

    return () =>
      tweenHits.to(".itemDescription", 0.3, {
        opacity: 0,
        autoAlpha: 1,
        display: "none",
      })
  }, [])

  return (
    <>
      {hits.map((e, i) => (
        <ItemDescription
          onClick={() => {
            if (searchStates.substring(3, 4) === "0") {
              unmount()
              setTimeout(
                () =>
                  settingHashParameters({
                    find: "dish",
                    selected: encodeURIComponent(
                      JSON.stringify({
                        dishName: e.name,
                        dishUid: e.objectID,
                      })
                    ),
                  }),
                530
              )
            } else {
              unmount()
              setTimeout(
                () =>
                  settingHashParameters({
                    find: "restaurant",
                    selected: encodeURIComponent(
                      JSON.stringify({
                        restName: e.name,
                        restUid: e.objectID,
                      })
                    ),
                  }),
                530
              )
            }
          }}
          key={i}
          item={{ ...e, tags: e.tags }}
        />
      ))}
    </>
  )
}
