import * as firebase from "firebase"

export const fetchingKpis = () => {
  const db = firebase.firestore()
  return db
    .collection("kpis")
    .get()
    .then(querySnapshot => {
      var date = [],
        dateFormatted = [],
        dishesQty = [],
        evaluationsDishes = [],
        evaluationsQty = [],
        evaluationsRests = [],
        loginsQty = [],
        loginsUsers = [],
        restsQty = [],
        searchQty = [],
        searchesDishes = [],
        searchesRests = [],
        usersQty = [],
        kpis2 = []

      querySnapshot.forEach(doc => {
        kpis2.push({
          date: doc.data().date,
          dateFormatted: doc.data().dateFormatted,
          dishesQty: doc.data().dishesQty,
          evaluationsDishes: doc.data().evaluationsDishes,
          evaluationsQty: doc.data().evaluationsQty,
          evaluationsRests: doc.data().evaluationsRests,
          loginsQty: doc.data().loginsQty,
          loginsUsers: doc.data().loginsUsers,
          restsQty: doc.data().restsQty,
          searchQty: doc.data().searchQty,
          searchesDishes: doc.data().searchesDishes,
          searchesRests: doc.data().searchesRests,
          usersQty: doc.data().usersQty,
        })
      })

      const cumsum = array =>
        array.map((e, i) => (array[i] = i === 0 ? e : e + array[i - 1]))

      kpis2.sort((a, b) => a.date.seconds - b.date.seconds)

      kpis2.map(e => {
        date.push(e.date.seconds)
        dateFormatted.push(e.dateFormatted)
        dishesQty.push(e.dishesQty)
        evaluationsDishes.push(e.evaluationsDishes)
        evaluationsQty.push(e.evaluationsQty)
        evaluationsRests.push(e.evaluationsRests)
        loginsQty.push(e.loginsQty)
        loginsUsers.push(e.loginsUsers)
        restsQty.push(e.restsQty)
        searchQty.push(e.searchQty)
        searchesDishes.push(e.searchesDishes)
        searchesRests.push(e.searchesRests)
        usersQty.push(e.usersQty)
        return null
      })

      return {
        evaluations: {
          quantityByDay: cumsum(evaluationsQty),
          days: date,
        },
        users: {
          quantityByDay: cumsum(usersQty),
          days: date,
        },
        dishes: {
          quantityByDay: cumsum(cumsum(dishesQty)),
          days: date,
        },
        restaurants: {
          quantityByDay: cumsum(cumsum(restsQty)),
          days: date,
        },
        searches: {
          quantityByDay: cumsum(cumsum(searchQty)),
          days: date,
        },
      }
    })
}
