import React, { useContext, useState, useEffect } from "react"
import { connect } from "react-redux"
import propTypes from "prop-types"
import { connectHits } from "react-instantsearch-dom"
import { useTranslation } from "react-i18next"

import { HashValuesContext } from "../../../constants/index"
import { settingHashParameters } from "../../../utils/urlParams"
import { selectDish } from "../../../../actions/selectedDish"
import { selectRest } from "../../../../actions/selectedRest"

import InfiniteScroll from "../../../1_molecules/InfiniteScroll/InfiniteScroll"

const Hits = ({
  hits,
  dispatch,
  state,
}) => {
  const { t } = useTranslation("common")
  const [restName, setRestName] = useState("")
  const [restUid, setRestUid] = useState("")
  const hashValues = useContext(HashValuesContext)

  useEffect(() => {
    if (hashValues.selected !== "" && hashValues.selected !== "none") {
      let restUidValue = JSON.parse(decodeURIComponent(hashValues.selected))
        .restUid
      setRestName(JSON.parse(decodeURIComponent(hashValues.selected)).restName)
      setRestUid(restUidValue)
      if (
        state.selectedRest.restDoc === null &&
        restUidValue !== undefined &&
        restUidValue !== ""
      )
        dispatch(selectRest(restUidValue, false, 'evaluate'))
    }
  }, [JSON.stringify(hashValues)])

  const handleSelecDish = (dish, newItem) => {
    if (newItem && !state.searchingItem.item)
      return alert(t("evaluate.evaluate3.noName"))
    console.log("WrapperHits")

    if (newItem && state.searchingItem.item) {
      dispatch(selectDish(state.searchingItem.item, newItem))
      if (restUid)
        return settingHashParameters({
          evaluate: "evaluate",
          selected: encodeURIComponent(
            JSON.stringify({
              restName: restName,
              restUid: restUid,
              dishName: state.searchingItem.item,
            })
          ),
        })
      return settingHashParameters({
        evaluate: "evaluate",
        selected: encodeURIComponent(
          JSON.stringify({
            restName: restName,
            dishName: state.searchingItem.item,
          })
        ),
      })
    }

    dispatch(selectDish(dish.objectID, newItem))

    settingHashParameters({
      evaluate: "evaluate",
      selected: encodeURIComponent(
        JSON.stringify({
          restName: restName,
          restUid: restUid,
          dishName: dish.name,
          dishUid: dish.objectID,
        })
      ),
    })
  }

  return (
    <InfiniteScroll
      newItem
      labelNewItem={"evaluate.evaluate3.newItem"}
      items={hits}
      concurrentItems={state.selectedRest.dishes}
      onSelect={handleSelecDish}
    />
  )
}

Hits.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

// const mapDispatchToPropsHits = dispatch => ({
//   selectDish: (dish, page, newItem, dishRests, rest) =>
//     dispatch(selectDish(dish, page, newItem, dishRests, rest)),
//   selectRest: (rest, newItem) => dispatch(selectRest(rest, newItem)),
// })

const mapStateToPropsHits = state => ({
  state: state,
})

export default connectHits(connect(mapStateToPropsHits)(Hits))
