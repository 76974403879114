import React, { useRef, useEffect, useContext } from "react"
import styled from "styled-components"
import { Colors } from "../../constants/palette"
import "bootstrap/dist/css/bootstrap.min.css"
import * as d3 from "d3"
import { ThemeContext } from "../../constants/index"

const Wrapper = styled.div`
position: relative;
max-width: ${props => props.width}px;

margin-bottom: 10px;
padding-right: 10px;
padding-left: 10px
margin: auto;
`

interface PropsCartesianChart {
  widthCard: number
  x: Array<number>
  y: Array<number>
  className?: string
  style?: React.CSSProperties
}

export default (props: PropsCartesianChart): JSX.Element => {
  const { styleMode, theme } = useContext(ThemeContext)
  const width =
    props.widthCard < 320
      ? 235
      : props.widthCard > 450
      ? 450 - 85
      : props.widthCard - 85
  const ref = useRef()

  var propsY = props.y,
    propsX: any[] = []

  props.x.map(e => propsX.push(new Date(e * 1000)))

  useEffect(() => {
    var data = []

    propsX.map((e, i) =>
      data.push({
        date: e,
        value: propsY[i],
      })
    )

    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 20, bottom: 30, left: 37 },
      widthChart = width - margin.left - margin.right,
      height = 165 - margin.top - margin.bottom

    d3.select(ref.current).selectAll("*").remove()

    // append the svg object to the body of the page
    var svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", widthChart + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    // Add X axis
    var x = d3.scaleTime().domain(d3.extent(propsX)).range([0, widthChart])

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "axis xAxis")
      .call(d3.axisBottom(x).ticks(3))
      .call(g => g.select(".domain").remove())

    // Add Y axis
    var y = d3
      .scaleLinear()
      .domain([0, d3.max(propsY)])
      .range([height, 0])

    svg.append("g").attr("class", "axis yAxis").call(d3.axisLeft(y).ticks(4))

    svg
      .selectAll(".axis")
      .selectAll("text")
      .style("fill", Colors[styleMode][theme].letterUsual)
      .style("opacity", 0.8)

    svg
      .selectAll(".axis")
      .selectAll("line,path")
      .style("stroke", Colors[styleMode][theme].letterUsual)
      .style("opacity", 0.8)

    svg.selectAll(".axis.yAxis").selectAll("path").style("stroke", "none")

    svg
      .selectAll(".axis.yAxis")
      .selectAll("line")
      .attr("x2", widthChart)
      .style("stroke-dasharray", "1 5")
      .filter((e, i) => i === 0)
      .style("stroke-dasharray", "none")

    // Add the line
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .curve(d3.curveBasis)
          .x(d => x(d.date))
          .y(d => y(d.value))
      )

    svg
      .selectAll("text")
      .style("font-family", "Montserrat")
      .style("font-size", "11px")
      .style("opacity", 0.8)

    const formatDate = date =>
      date.getDay() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()

    // Add markers
    svg
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", d => x(d.date))
      .attr("cy", d => y(d.value))
      .attr("r", 0)
      .attr("fill", "steelblue")
      .append("title")
      .text(d => formatDate(d.date) + ": " + d.value)
  }, [props.x])

  return (
    <Wrapper className={props.className} width={width} style={props.style}>
      <div ref={ref} />
    </Wrapper>
  )
}
