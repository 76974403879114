import styled, { css } from "styled-components"
import { Colors } from "../../constants/palette"
import React, { useContext } from "react"
import { ThemeContext } from "../../constants/index"

interface Props {
  readonly destaque?: boolean
  readonly texto?: boolean
  readonly texto2?: boolean
  readonly texto2b?: boolean
  readonly texto3?: boolean
  readonly texto2Link?: boolean
  readonly gauge?: boolean

  readonly styleMode?: string
  readonly theme?: string
  readonly button?: boolean

  readonly cardDestak?: boolean
  readonly cardText?: boolean
  readonly dropdownTitle?: boolean
  readonly dropdown?: boolean
  readonly hover?: boolean
}

const TypoComponent = styled.div<Props>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.8px;

  display: inline-block;
  align-items: center;
  text-align: center;
  margin: auto;
  padding: 4px;
  font-size: 14px;
  color: ${props => Colors[props.styleMode][props.theme].letterUsual};
  ${props =>
    props.theme === "light" &&
    css`
      opacity: 0.95;
    `}

  ${props =>
    props.button &&
    css`
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      /* identical to box height */
      text-align: center;
      letter-spacing: 0.05em;

      color: ${Colors.mainOrange};

      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    `}

  ${props =>
    props.cardDestak &&
    css`
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      /* identical to box height */
      text-align: center;
      letter-spacing: 0em;

      color: ${Colors.mainOrange};

      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    `}

  ${props =>
    props.cardText &&
    css`
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.05em;
      color: ${props => Colors[props.styleMode][props.theme].letterCardText};
    `}

  ${props =>
    props.dropdown &&
    css`
      padding-top: 0px;
      padding-bottom: 0px;
      cursor: pointer;
      font-size: 12.5px;
    `}

  ${props =>
    props.dropdownTitle &&
    css`
      opacity: 0.7;
      cursor: default;
      font-size: 13px;
    `}

  ${props =>
    props.simpleLink &&
    css`
      opacity: 0.8;
      cursor: pointer;
      text-decoration: underline;
      font-family: Montserrat;
      font-size: 12px;
    `}



  ${props =>
    props.nameDish &&
    css`
      font-size: 10px;
      font-family: Montserrat;
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      text-align: center;
      letter-spacing: 0.05em;
      color: ${props => Colors[props.styleMode][props.theme].letterCardText};
    `}
`

interface PropsTypo {
  button?: boolean
  cardDestak?: boolean
  cardText?: boolean
  dropdownTitle?: boolean
  dropdown?: boolean
  simpleLink?: boolean
  nameDish?: boolean
  style?: React.CSSProperties
  children: React.ReactNode
  onClick?: Function
  title?: string
  className?: string
}

export default (props: PropsTypo): JSX.Element => {
  const theme = useContext(ThemeContext)
  //const {cardDestak, cardText, button, dropdown, dropdownTitle, simpleLink, nameDish} = props
  // const {onChangeValue, ...allProps } = props

  return (
    <TypoComponent
      className={props.className}
      style={props.style}
      {...props}
      {...theme}
    />
  )
}
