import * as firebase from "firebase"

import C from "./constants"

import { configFetching } from "./appConfigs"
import { userInitial } from "../reducers/userFetch"
import { fetchingKpis } from "./kpi"
import { selectDish } from "./selectedDish"
import { selectRest } from "./selectedRest"
import { settingHashParameters } from "../components/utils/urlParams"
import { searchingItem } from "./index"

export const clickLogin = (email, password, userFetch) => {
  return dispatch => {
    if ((email === 0) & (password === 0)) {
      console.log("LOGIN_CLEAN_DATA")
      dispatch({
        type: C.LOGIN_CLEAN_DATA,
        payload: { ...userFetch, uid: null },
      })
    } else {
      console.log("LOGIN_REQUEST")
      dispatch({ type: C.LOGIN_REQUEST, payload: { ...userFetch, uid: null } })
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(error => {
          var err_msg
          switch (error.code) {
            case "auth/invalid-email":
              err_msg = "O email inserido é invalido."
              break
            case "auth/wrong-password":
              err_msg = "A senha não está correta."
              break
            case "auth/invalid-password":
              err_msg = "Essa senha não é válida."
              break
            case "auth/weak-password":
              err_msg = "A senha deve ter no mínimo 6 caracteres."
              break
            case "auth/user-not-found":
              err_msg = "Esse usuário não foi encontrado."
              break
            default:
              break
          }
          alert(err_msg)
          console.log("LOGIN_ERROR")
          dispatch({
            type: C.LOGIN_ERROR,
            payload: { ...userFetch, error: err_msg },
          })
        })
    }
  }
}

export const forgetEmail = (email, userFetch) => {
  return dispatch => {
    console.log("FORGET_EMAIL_REQUEST")
    dispatch({
      type: C.FORGET_EMAIL_REQUEST,
      payload: {
        ...userFetch,
        email: email,
      },
    })
    var auth = firebase.auth()
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        console.log("FORGET_EMAIL_SENT")
        alert("O email para a recuperação de senha foi enviado.")
        dispatch({
          type: C.FORGET_EMAIL_SENT,
          payload: { ...userFetch, emailForgetPasswordSent: true },
        })
      })
      .catch(error => {
        var err_msg
        switch (error.code) {
          case "auth/invalid-email":
            err_msg = "O email inserido é invalido."
            break
          default:
            err_msg = error.code
            break
        }
        console.log("FORGET_EMAIL_ERROR")
        dispatch({
          type: C.FORGET_EMAIL_ERROR,
          payload: { ...userFetch, error: err_msg },
        })
      })
  }
}

export const detectUser = userFetch => {
  return dispatch => {
    firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser && firebaseUser.uid) {
        if (!firebaseUser.emailVerified) {
          firebaseUser
            .sendEmailVerification()
            .then(function () {
              // Send verfication email
              dispatch(
                clickLogout({ theme: null, style: null, language: null })
              )
              console.log("EMAIL_NOT_VERIFIED")
              dispatch({
                type: C.EMAIL_NOT_VERIFIED,
                payload: {
                  ...userFetch,
                  uid: null,
                  email: null,
                  validated: false,
                  emailVerificationSent: true,
                  error: null,
                  checkedForLogin: true,
                },
              })
            })
            .catch(error => {
              console.log("EMAIL_NOT_VERIFIED_ERROR")
              dispatch({
                type: C.EMAIL_NOT_VERIFIED_ERROR,
                payload: {
                  ...userFetch,
                  uid: null,
                  email: null,
                  validated: false,
                  emailVerificationSent: false,
                  error:
                    error.code === "auth/too-many-requests"
                      ? "Muitas tentativas consecutivas desse dippositivo, tente novamente mais tarde."
                      : error.code,
                  checkedForLogin: true,
                },
              })
            })
        } else {
          configFetching().then(configs => {
            console.log("CONFIG_FETCHED")
            dispatch({
              type: C.CONFIG_FETCHED,
              payload: {
                ...configs,
                savingAppConfigs: null,
                errorSavingAppConfigs: null,
                checkedForLogin: true,
              },
            })
          })
          var docRef = firebase
            .firestore()
            .collection("users")
            .doc(firebaseUser.uid)
          docRef.get().then(doc => {
            console.log("DETECTED_USER")
            dispatch({
              type: C.DETECTED_USER,
              payload: {
                ...doc.data(),
                personalEvaluations: [],
                uid: firebaseUser.uid,
                email: firebaseUser.email,
                validated: firebaseUser.emailVerified,
                checkedForLogin: true,
              },
            })

            let userData = {
              ...doc.data(),
              uid: firebaseUser.uid,
              email: firebaseUser.email,
              validated: firebaseUser.emailVerified,
            }

            fetchingPersonalEvaluations(firebaseUser.uid).then(
              personalEvaluations => {
                console.log("PERSONAL_EVALS_FETCHED")
                dispatch({
                  type: C.PERSONAL_EVALS_FETCHED,
                  payload: {
                    ...userData,
                    personalEvaluations,
                    checkedForLogin: true,
                  },
                })
              }
            )

            if (doc.data().role === "admin")
              fetchingKpis().then(kpis => {
                console.log("KPIS_FETCHED")
                dispatch({ type: C.KPIS_FETCHED, payload: kpis })
              })

            dispatch(writeLoginData(userData))
          })
        }
      } else {
        console.log("NON_LOGGED_USER")
        dispatch({
          type: C.NON_LOGGED_USER,
          payload: { userInitial, checkedForLogin: true },
        })
      }
    })
    return { type: C.DETECT_USER }
  }
}

export const clickLogout = appConfigs => {
  return dispatch => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("LOG_OUT")
        console.log("appConfigs", appConfigs)
        dispatch({
          type: C.LOG_OUT,
          payload: {
            ...userInitial,
            language: appConfigs.language,
            theme: appConfigs.theme,
            style: appConfigs.style,
          },
        })
        dispatch(selectRest(null, false, 'evaluate'))
        dispatch(selectDish(null, false, 'evaluate'))
        dispatch(searchingItem(null))
        settingHashParameters({
          find: "presentation",
          evaluate: "presentation",
        })
      })
      .catch(error => {
        console.log("failed to logout")
        console.log(error)
      })
  }
}

export const writeLoginData = userFetch => {
  return dispatch => {
    console.log("START_WRITING")
    dispatch({ type: C.START_WRITING })
    let actualTimestamp = firebase.firestore.Timestamp.fromDate(new Date())
    const usersRef = firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
    usersRef
      .set(
        {
          last_login: actualTimestamp,
          uid: firebase.auth().currentUser.uid,
          email: firebase.auth().currentUser.email,
        },
        { merge: true }
      )
      .then(function () {
        console.log("WRITING_LOGIN_DATA_DONE")
        dispatch({
          type: C.WRITING_LOGIN_DATA_DONE,
          payload: {
            ...userFetch,
            uid: firebase.auth().currentUser.uid,
            email: firebase.auth().currentUser.email,
          },
        })
      })
      .catch(error => {
        console.log("WRITING_LOGIN_DATA_EROR")
        dispatch({
          type: C.WRITING_LOGIN_DATA_EROR,
          payload: {
            ...userFetch,
            uid: firebase.auth().currentUser.uid,
            email: firebase.auth().currentUser.email,
            erroWritingData: error,
          },
        })
      })
  }
}

export const clickRegister = (email, password, userFetch) => {
  return dispatch => {
    console.log("REGISTER_REQUEST")
    dispatch({ type: C.REGISTER_REQUEST })
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        console.log("REGISTER_EMAIL_SENT")
        alert("O email de validação foi enviado, por favor, confirme esse email.")
        dispatch({
          type: C.REGISTER_EMAIL_SENT,
          payload: { ...userFetch, emailVerificationSent: true },
        })
        dispatch(clickLogout({ theme: null, style: null, language: null }))
      })
      .catch(error => {
        // Handle Errors here.
        var err_msg
        switch (error.code) {
          case "auth/invalid-email":
            err_msg = "O email inserido é invalido."
            break
          case "auth/email-already-in-use":
            err_msg = "Esse email já está em uso por uma conta no site."
            break
          case "auth/weak-password":
            err_msg = "A senha precisa ter 6 dígitos."
            break
          default:
            err_msg = error.code
            break
        }
        console.log("REGISTER_ERROR")
        alert(err_msg)
        dispatch({
          type: C.REGISTER_ERROR,
          payload: {
            ...userFetch,
            error: err_msg,
          },
        })
      })
  }
}

export const updatingPersonalEvaluations = userData => {
  return dispatch => {
    console.log("PERSONAL_EVALS_UPDATING")
    fetchingPersonalEvaluations(userData.uid).then(personalEvaluations => {
      console.log("PERSONAL_EVALS_FETCHED")
      dispatch({
        type: C.PERSONAL_EVALS_FETCHED,
        payload: {
          ...userData,
          personalEvaluations,
        },
      })
    })
  }
}

export const fetchingPersonalEvaluations = userID => {
  const db = firebase.firestore()
  const personalEvals = []
  console.log("Fetching personal evaluations")
  return db
    .collection("evaluations")
    .where("user.uid", "==", userID)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc =>
        personalEvals.push(doc.data())
      )
      return personalEvals
    })
}

export const updateConfigs = (appConfigs, userFetch) => {
  return dispatch => {
    console.log("here updateConfigs")
    if (userFetch.uid !== null && userFetch.uid !== undefined) {
      console.log("UPDATING_USER_CONFIGS")
      dispatch({
        type: C.UPDATING_USER_CONFIGS,
        payload: { ...userFetch, ...appConfigs, fetching: true },
      })
      const usersRef = firebase
        .firestore()
        .collection("users")
        .doc(userFetch.uid)

      console.log("appConfigs", appConfigs)

      usersRef
        .set({ ...appConfigs }, { merge: true })
        .then(function () {
          console.log("UPDATED_USER_CONFIGS")
          dispatch({
            type: C.UPDATED_USER_CONFIGS,
            payload: {
              ...userFetch,
              ...appConfigs,
              fetching: false,
            },
          })
        })
        .catch(error => {
          console.log("UPDATING_USER_CONFIGS_ERROR")
          console.log("error", error)
          dispatch({
            type: C.UPDATING_USER_CONFIGS_ERROR,
            payload: {
              ...userFetch,
              ...appConfigs,
              fetching: false,
              erroWritingData: error,
            },
          })
        })
    } else {
      console.log("UPDATING_USER_CONFIGS_ERROR")
      dispatch({
        type: C.UPDATING_USER_CONFIGS_ERROR,
        payload: { ...userFetch, ...appConfigs, fetching: null },
      })
    }
  }
}
