import C from "./constants"
import * as firebase from "firebase"

export const configFetching = () => {
  const docRef = firebase
    .firestore()
    .collection("config")
    .doc("GR5AHHY67yubJRHP158G")

  console.log("configFetching")

  const tagsRef = docRef.collection("tags").doc("IT7qhmk3zEAS2sV1UJ9g")

  return docRef
    .get()
    .then(doc => {
      //if (doc.data().isInPilotMode) { alert(doc.data().pilotMessage) }
      return {
        addDishes: doc.data().addDishes,
        addRests: doc.data().addRests,
        isInPilotMode: doc.data().isInPilotMode,
        pilotMessage: doc.data().pilotMessage,
      }
    })
    .then(configs => {
      return tagsRef
        .get()
        .then(doc => {
          return { tags: doc.data(), ...configs }
        })
        .catch(error => {
          console.log("error on retrieving tags", error)
        })
    })
    .catch(error => {
      console.log("error on retrieving configs", error)
    })
}

// config: {
//   addDishes: null | boolean
//   addRests: null | boolean
//   isInPilotMode: null | boolean
//   pilotMessage: string
// }

export const saveConfig = config => {
  return dispatch => {
    console.log("CONFIG_SAVING")
    dispatch({
      type: C.CONFIG_SAVING,
      payload: {
        savingAppConfigs: true,
        errorSavingAppConfigs: null,
        ...config,
      },
    })

    const configRef = firebase
      .firestore()
      .collection("config")
      .doc("GR5AHHY67yubJRHP158G")
    configRef
      .set(
        {
          addDishes: config.addDishes,
          addRests: config.addRests,
          isInPilotMode: config.isInPilotMode,
          pilotMessage: config.pilotMessage,
        },
        { merge: true }
      )
      .then(res => {
        console.log("CONFIG_SAVE_DONE")
        dispatch({
          type: C.CONFIG_SAVE_DONE,
          payload: {
            savingAppConfigs: false,
            errorSavingAppConfigs: null,
            ...config,
          },
        })
      })
      .catch(error => {
        console.log("CONFIG_SAVE_ERROR")
        dispatch({
          type: C.CONFIG_SAVE_ERROR,
          payload: {
            savingAppConfigs: false,
            errorSavingAppConfigs: error,
            ...config,
          },
        })
      })
  }
}
