import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { gsap, TweenMax } from "gsap"
import L from "leaflet"

import { ThemeContext } from "../../../constants/index"
import { PropsDishesStore } from "../../../utils/data/dishData"
import { PropsRests } from "../../../utils/data/restData"
import { Colors } from "../../../constants/palette"
import { singularOrPlural } from "../../../utils/misc"
import { tweenEffects } from "../../../utils/animations"
import { ExampleData } from "../../../utils/data/data"
import { tweenConfigurationsPartAppear } from "../../../utils/animations"

import MapComponent2 from "../../../0_atoms/Map/Map"
import SvgLogo from "../../../0_atoms/Logos/TexturedMedalShineCSS"
import Typo from "../../../0_atoms/Typo/Typo"
import SpiderChartComponent from "../../../0_atoms/Charts/SpiderChart"
import InfiniteScrollDishes from "../../../1_molecules/InfiniteScroll/InfiniteScrollItems"
import InfiniteScrollEvaluations from "../../../1_molecules/InfiniteScroll/InfiniteScrollEvaluations"
import CaretComponent4 from "../../../1_molecules/CaretComponent/CaretComponent4"

const ComponentsWrapper = styled.div`
  display: none;
  flex-direction: column;
  opacity: 0;
  height: 0px;
`

const Wrapper = styled.div`
  padding: 0px 15px;
  margin-bottom: 10px;
`

const WrapperLogo = styled.div`
  display: inline-block;
  height: 16px;
  position: absolute;
  margin-top: -8px;
`

const titleStyle: React.CSSProperties = {
  width: "200px",
  margin: "auto",
  marginTop: "15px",
  display: "block",
  paddingBottom: "0px",
}

const auxiliaryTextStyle: React.CSSProperties = {
  padding: "0px",
  paddingTop: "2px",

  marginTop: "0px",

  fontSize: "9px",
  marginRight: "4px",
  lineHeight: "11px",
  letterSpacing: "unset",

  display: "blocks",
  opacity: "unset",
}

const findingScale: any = value =>
  value > 500
    ? (Math.round(value * 10) / 10 / 1000).toFixed(1)
    : (Math.round(value * 10) / 10).toFixed(0)

interface PropsDishFound {
  dish: PropsDishesStore
  selectedRest?: PropsRests
  evaluations?: any
}

export default (props: PropsDishFound): JSX.Element => {
  const { styleMode, theme } = useContext(ThemeContext)
  const [restaurants, setRestaurants] = useState(false)
  const [evaluationsDishRestaurant, setEvaluationsDishRestaurant] = useState(
    false
  )

  const [map, setMap] = useState(true)
  const [distance] = useState(() => {
    let points = []
    props.dish.restaurants.map(e =>
      points.push([e.location.latitude, e.location.longitude])
    )
    var bounds = new L.LatLngBounds(points)
    return bounds.getSouthWest().distanceTo(bounds.getNorthEast()) / 2
  })

  const [, setCardHeight] = useState(1015)

  const [tweenRestaurants] = useState(gsap.timeline({ paused: true }))
  const [tweenEvaluations] = useState(gsap.timeline({ paused: true }))
  const [tweenMap] = useState(gsap.timeline({ paused: true }))
  const [tweenFindDish]: any = useState(gsap.timeline({ paused: true }))
  const TweenMaxTyped: any = TweenMax

  const { t } = useTranslation("common")
  const [dishSelected, setDishSelected] = useState(0)

  // .map-DishFound

  useEffect(() => {
    tweenEffects(tweenRestaurants, ".foundDish", "-restaurants", {
      styleMode,
      theme,
    })
    tweenEffects(tweenEvaluations, ".foundDish", "-evaluationsDishRestaurant", {
      styleMode,
      theme,
    })
    tweenEffects(tweenMap, ".foundDish", "-map", { styleMode, theme })
    tweenConfigurationsPartAppear(tweenFindDish, ".find-dish-items", 815)
    TweenMaxTyped.delayedCall(1, () => tweenFindDish.reversed(false))

    return () => tweenFindDish.reversed(true)
  }, [])

  useEffect(() => {
    tweenRestaurants.reversed(!restaurants)
    tweenEvaluations.reversed(!evaluationsDishRestaurant)
    tweenMap.reversed(!map)
  }, [map, restaurants, evaluationsDishRestaurant])

  useEffect(() => {
    if (evaluationsDishRestaurant)
      TweenMax.to(".evaluation", 0.3, {
        opacity: 1,
        autoAlpha: 1,
        display: "flex",
      })
  }, [dishSelected])

  return (
    <ComponentsWrapper className="find-dish-items">
      {/* <Typo cardDestak style={{ display: "block" }}>
        {t("find.find1.title")}
      </Typo>
      <Typo style={titleStyle} cardText>
        {t("find.find1.description")}
      </Typo> */}

      <Wrapper>
        <div>
          <Typo style={titleStyle} cardDestak>
            {props.dish.name}
          </Typo>
          <Typo
            style={{
              ...auxiliaryTextStyle,
              width: "100%",
              display: "block",
              color: Colors[styleMode][theme].letterAuxiliary2,
            }}
          >
            {"Tags:" +
              props.dish.tags.map(
                (e, i) =>
                  " " + e + (i === props.dish.tags.length - 1 ? "." : "")
              )}
          </Typo>

          <Typo
            style={{
              ...auxiliaryTextStyle,
              display: "block",
              color: Colors[styleMode][theme].letterAuxiliary2,
            }}
          >
            {props.dish.evaluations}{" "}
            {t(
              singularOrPlural(
                props.dish.evaluations,
                "evaluation",
                "evaluations"
              )
            )}
          </Typo>
        </div>

        <Typo style={{ ...titleStyle, marginTop: "10px" }} cardDestak>
          {props.dish.restaurants[dishSelected].name}{" "}
          {dishSelected === 0 && (
            <WrapperLogo>
              <SvgLogo width={29} />
            </WrapperLogo>
          )}
        </Typo>
        <Typo
          style={{
            ...auxiliaryTextStyle,
            width: "100%",
            color: Colors[styleMode][theme].letterAuxiliary2,
          }}
        >
          {dishSelected === 0
            ? t("find.foundDish.restaurantBestEvaluation")
            : t("find.foundDish.selectedRestaurant")}
        </Typo>

        <SpiderChartComponent
          grades_focus={props.dish.restaurants[dishSelected].grades_avg}
          grades_basis={props.dish.grades_avg}
        />
      </Wrapper>

      {[
        {
          label: "restaurants",
          title: singularOrPlural(
            props.dish.restaurants.length,
            t("find.foundDish.restaurantsWithThatDishsing"),
            t("find.foundDish.restaurantsWithThatDish")
          ),
          value: props.dish.restaurants.length,
          data: "",
          classNamePreffix: "foundDish",
          callback: () => setRestaurants(!restaurants),
          component: (
            <InfiniteScrollDishes
              items={props.dish.restaurants}
              onClickItem={i => setDishSelected(i)}
            />
          ),
        },
        {
          label: "evaluationsDishRestaurant",
          title: singularOrPlural(
            props.dish.restaurants[dishSelected].evaluations,
            t("find.foundDish.dishsEvaluationOnSelectedRestaurantsing"),
            t("find.foundDish.dishsEvaluationOnSelectedRestaurant")
          ),
          value: props.dish.restaurants[dishSelected].evaluations,
          data: "",
          classNamePreffix: "foundDish",
          callback: () =>
            setEvaluationsDishRestaurant(!evaluationsDishRestaurant),
          component: (
            <InfiniteScrollEvaluations
              evaluations={props.evaluations}
              onClickItem={i => setDishSelected(i)}
            />
          ),
        },
        {
          label: "map",
          title:
            props.dish.restaurants.length > 1
              ? distance > 500
                ? t("find.foundDish.mapWithTheRestaurantskm")
                : t("find.foundDish.mapWithTheRestaurantsm")
              : t("find.foundDish.mapWithTheRestaurant"),
          value: distance > 0 ? distance : "",
          data: "",
          callbalck: () => setMap(!map),
          classNamePreffix: "foundDish",
          component: (
            <div style={{ marginTop: "15px" }}>
              <MapComponent2 restSelected={dishSelected} dish={props.dish} />
            </div>
          ),
        },
      ].map((e, i) => (
        <CaretComponent4
          noDistanceTitle={e.label === "map" ? true : false}
          label={e.label}
          key={i}
          caretData={e}
          clickOpenCaret={e.callback}
        />
      ))}
    </ComponentsWrapper>
  )
}
