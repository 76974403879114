import React, { useRef, useEffect, useContext } from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import * as d3 from "d3"
import * as _ from "lodash"
import { useTranslation } from "react-i18next"

import { Colors, Shadows } from "../../constants/palette"
import { singularOrPlural } from "../../utils/misc" //"../../../utils/misc"
import { closedInterval } from "../../utils/misc"
import { ThemeContext } from "../../constants/index"

const Wrapper = styled.div`
position: relative;
width: ${props => closedInterval(props.widthCard - 85, 235, 355)}px;
min-height: 290px;


margin-bottom: 10px;
padding-right: 10px;
padding-left: 10px
margin: auto;

.tooltip-line{
  stroke: ${props => Colors[props.styleMode][props.theme].letterUsual};
  opacity: 0.7;
}
.tooltip, .sunburst-helper{
  color: ${props => Colors[props.styleMode][props.theme].letterUsual};
}
.tooltip{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  font-family: Montserrat;
  font-size: 9px;
  padding: 5px;
  max-width: 220px;
}
`

interface PropsZoomableSunburst {
  data?: any
  dataOrder: string
  widthCard?: number
  top?: number
  left?: number
  style?: React.CSSProperties
}

const Chart: React.FC<PropsZoomableSunburst> = props => {
  const ref = useRef()
  const theme = useContext(ThemeContext)
  const { t } = useTranslation("common")

  useEffect(() => {
    let usedData = props.data,
      width = 200,
      radius = width / 6
    d3.select(ref.current).selectAll("*").remove()

    if (usedData.children !== undefined) {
      const partition = data => {
        const root = d3
          .hierarchy(data)
          .sum(d => d.value)
          .sort((a, b) => b.value - a.value)
        return d3.partition().size([2 * Math.PI, root.height + 1])(root)
      }

      const color = d3.scaleOrdinal(
        d3.quantize(d3.interpolateRainbow, usedData.children.length + 1)
      )

      const arcVisible = d => d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0

      const labelVisible = d =>
        d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03

      const labelTransform = d => {
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI
        const y = ((d.y0 + d.y1) / 2) * radius //*0.8
        return `rotate(${x - 90}) translate(${y},0) rotate(${
          x < 180 ? 0 : 180
        })`
      }

      const format = d3.format(",d")

      const constInnerRadius1 = 1, //0.5,
        constInnerRadius2 = 1 //0.875

      const arc = d3
        .arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
        //.padRadius(radius * 1.5)
        .innerRadius(d => d.y0 * radius)
        .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1))

      const root = partition(usedData)

      root.each(d => (d.current = d))

      d3.select(ref.current)
        .append("div")
        .attr("class", "sunburst-helper")
        .text(
          props.data.children.length === 0
            ? t("charts.zoomableSunburst.noData")
            : t("charts.zoomableSunburst.helper0")
        )
        .style("font-size", props.data.children.length === 0 ? "12px" : "8px")
        .style("opacity", ".6")
        .style("padding-bottom", "5px")

      const svg = d3
        .select(ref.current)
        .append("svg")
        .attr("viewBox", [0, 0, width, width + 5])
        .style("font", "10px sans-serif")

      const g = svg
        .append("g")
        .attr("transform", `translate(${width / 2},${width / 2})`)

      const path = g
        .append("g")
        .selectAll("path")
        .data(root.descendants().slice(1))
        .join("path")
        .attr("fill", d => {
          while (d.depth > 1) d = d.parent
          return color(d.data.name)
        })
        .attr("fill-opacity", d =>
          arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attr("d", d => arc(d.current))

      var clickedOnce = false,
        timer

      const run_on_simple_click = action => {
        // action
        return (clickedOnce = false)
      }

      const run_double_click = action => {
        clickedOnce = false
        // action
        clearTimeout(timer)
      }

      path
        .filter(d => d.children)
        .style("cursor", "pointer")
        .on("click", d => {
          if (clickedOnce) {
            run_double_click(
              d.depth >= 2
                ? null
                : clicked(
                    d,
                    t("charts.zoomableSunburst.helper0"),
                    t("charts.zoomableSunburst.helper1")
                  )
            )
          } else {
            timer = setTimeout(() => run_on_simple_click(mouseover(d)), 300)
            clickedOnce = true
          }
          return null
        })
      //.on("mouseleave", d => mouseleave(d))

      const lineTooltip = svg
        .append("line")
        .attr("class", "tooltip-line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 50)
        .attr("y2", 270)
        .style("display", "none")

      let stringLength = Math.round(
        closedInterval(props.widthCard / 260, 7, 10)
      )

      const label = g
        .append("g")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .style("user-select", "none")
        .selectAll("text")
        .data(root.descendants().slice(1))
        .join("text")
        .attr("dy", "0.35em")
        .attr("fill-opacity", d => +labelVisible(d.current))
        .attr("transform", d => labelTransform(d.current))
        .text(d =>
          d.data.name !== undefined && d.data.name.length > stringLength + 1
            ? d.data.name.slice(0, stringLength) + "..."
            : d.data.name
        )
        .style("font-size", "9px")
        .style("fill", Colors[theme.styleMode][theme.theme].letterUsual)
        .style("opacity", 0.8)

      const parent = g
        .append("circle")
        .datum(root)
        .attr("r", radius)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .on("click", d => {
          if (clickedOnce) {
            run_double_click(
              d.depth >= 2
                ? null
                : clicked(
                    d,
                    t("charts.zoomableSunburst.helper0"),
                    t("charts.zoomableSunburst.helper1")
                  )
            )
          } else {
            timer = setTimeout(function () {
              run_on_simple_click(mouseover(d))
            }, 300)
            clickedOnce = true
          }
          return null
        })
      //.on("mouseleave", d => mouseleave(d))

      var clicked = function (p, t0, t1) {
        Tooltip.style("opacity", 0)
        lineTooltip.style("display", "none")
        if (p.y0 === 0) {
          d3.selectAll(".sunburst-helper").text(t1)
        } else {
          d3.selectAll(".sunburst-helper").text(t0)
        }

        parent.datum(p.parent || root)

        root.each(
          d =>
            (d.target = {
              x0:
                Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
                2 *
                Math.PI,
              x1:
                Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
                2 *
                Math.PI,
              y0: Math.max(0, d.y0 - p.depth),
              y1: Math.max(0, d.y1 - p.depth),
            })
        )

        const t = g.transition().duration(750)

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        path
          .transition(t)
          .tween("data", d => {
            const i = d3.interpolate(d.current, d.target)
            return t => (d.current = i(t))
          })
          .filter(function (d) {
            return +this.getAttribute("fill-opacity") || arcVisible(d.target)
          })
          .attr("fill-opacity", d =>
            arcVisible(d.target) ? (d.values ? 0.6 : 0.4) : 0
          )
          .attrTween("d", d => () => arc(d.current))

        label
          .filter(function (d) {
            return +this.getAttribute("fill-opacity") || labelVisible(d.target)
          })
          .transition(t)
          .attr("fill-opacity", d => +labelVisible(d.target))
          .attrTween("transform", d => () => labelTransform(d.current))
      }

      d3.select(ref.current).append("div").attr("class", "hover-effect")

      // create a tooltip
      var Tooltip = d3
        .select(ref.current)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("opacity", "0")

      // Three function that change the tooltip when user hover / move / leave a cell
      var mouseover = function (d) {
        if (d.y0 !== 0) {
          let depth, angle
          if (d.target !== undefined) {
            depth = d.target.y0 === 1 ? 1.5 : 2.5
            angle = (d.target.x1 - d.target.x0) / 2 + d.target.x0 - Math.PI / 2
          } else {
            depth = d.y0 === 1 ? 1.5 : 2.5
            angle = (d.x1 - d.x0) / 2 + d.x0 - Math.PI / 2
          }
          const convertX = Math.cos(angle) * radius * depth + radius * 3
          const convertY = Math.sin(angle) * radius * depth + radius * 3
          Tooltip.style("opacity", 1).text(
            mountTooltip(
              d,
              singularOrPlural(
                d.value,
                t(
                  "charts.zoomableSunburst." + props.dataOrder + ".tooltip1sing"
                ),
                t("charts.zoomableSunburst." + props.dataOrder + ".tooltip1")
              ),
              t("charts.zoomableSunburst." + props.dataOrder + ".tooltip15"),
              t("charts.zoomableSunburst." + props.dataOrder + ".tooltip2"),
              t("charts.zoomableSunburst." + props.dataOrder + ".tooltip3")
            )
          )
          lineTooltip
            .attr("x1", convertX)
            .attr("y1", convertY)
            .style("display", "unset")
          d3.select(this).style("opacity", 0.7)
        } else {
          lineTooltip.style("display", "none")
          Tooltip.style("opacity", "0")
        }
      }

      var mouseleave = function () {
        Tooltip.style("opacity", 0)
        d3.select(this).style("stroke", "none").style("opacity", 0.7)
        lineTooltip.style("display", "none")
      }

      const mountTooltip = (d, t1, t15, t2, t3) => {
        let tooltipText
        switch (d.depth) {
          case 1:
            tooltipText = `${d.value} ${t1} ${d.data.name} ${t15}`
            break
          case 2:
            tooltipText = `${d.value} ${t1} ${d.parent.data.name} ${t15}\n ${t2} ${d.data.name}`
            break
          case 3:
            tooltipText = `${d.value} ${t1} ${d.parent.parent.data.name} ${t15}\n ${t2} ${d.parent.data.name}\n ${t3} ${d.data.name}`
            break
          default:
        }
        return tooltipText
      }
    }
  }, [props.data])

  return (
    <Wrapper style={props.style} widthCard={props.widthCard} {...theme}>
      <div ref={ref} />
    </Wrapper>
  )
}

export default Chart
