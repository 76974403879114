import React, { useContext } from "react"
import styled, { css } from "styled-components"

import { Colors } from "../../constants/palette"
import { ThemeContext, HashValuesContext } from "../../constants/index"

const Layout = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 60px 1fr;
  overflow: auto;

  background-color: ${props => Colors[props.styleMode][props.theme].base};

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  ::-webkit-scrollbar-track {
    background: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`

const Main = styled.main`
  ${props =>
    props.styleMode === "classic" &&
    css`
      background-image: linear-gradient(
        ${Colors.mainOrange} 33.33%,
        ${props => Colors[props.styleMode][props.theme].base} 33.33%,
        ${props => Colors[props.styleMode][props.theme].base} 66.66%,
        ${Colors.mainGray} 66.66%
      );
      filter: grayscale(0.2);
    `}

  display: flex;
  flex-flow: column;
  justify-content: space-around;
  padding: 50px 0px;
`
//padding: 30px 20px 30px;

interface PropsLayout {
  bar: JSX.Element
  content: JSX.Element
}

export default (props: PropsLayout): JSX.Element => {
  const theme = useContext(ThemeContext)
  const hashValues = useContext(HashValuesContext)

  return (
    <Layout {...theme} className={"layout-wrapper"}>
      <header>{props.bar}</header>
      <Main {...hashValues} {...theme}>{props.content}</Main>
    </Layout>
  )
}
