import React from "react"
import propTypes from "prop-types"
import { connect } from "react-redux"

import { PropsWrappers } from "../../../constants/index"
import { updateConfigs } from "../../../../actions/user"

import Configurations from "./Configurations"


const ConfigurationsWrapper: React.FC<PropsWrappers> = props => {
  const { language, style, theme } = props.state.userFetch

  const handleUpdateConfigs = appConfigs => {
    props.dispatch(updateConfigs(appConfigs, props.state.userFetch))
  }
  return (
    <Configurations
      {...props}
      style={style}
      theme={theme}
      language={language}
      dispatch={handleUpdateConfigs}
    />
  )
}

ConfigurationsWrapper.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(ConfigurationsWrapper)
