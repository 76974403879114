import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Colors, Shadows } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"

const TextAreaComponent = styled.textarea`
  border-radius: 5px;
  text-align: center;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  resize: none;

  border: 1px solid #ccc;

  color: ${props => Colors[props.styleMode][props.theme].letterUsual};
  background-color: ${props => Colors[props.styleMode][props.theme].L15};

  ${props => Shadows[props.styleMode][props.theme].Lm5upin}

  ${props =>
    props.styleMode === "new" &&
    css`
      border: none;
    `}

    :focus {
    outline: none;
  }
`

interface PropsTextArea {
  placeholder: string
  onChange: Function
  value: string
  style?: React.CSSProperties
}

export default (props: PropsTextArea): JSX.Element => {
  const theme = useContext(ThemeContext)

  return (
    <TextAreaComponent
      placeholder={props.placeholder}
      {...theme}
      style={props.style}
    />
  )
}
