import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { Colors } from "../../constants/palette"
import { handleAction } from "../../utils/misc"

import Typo from "../../0_atoms/Typo/Typo"
import Slider from "../../0_atoms/RangeSlider/RangeSlider"

const WrapperSlider = styled.div`
  padding-right: 30px;
  margin-top: 10px;
`

const Wrapper = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .slider {
    padding-left: 30px;
    font-size: 12px;
    text-align: left;
    width: min-content;
    margin: unset;
  }
`

const booleanToInteger = value => (value ? 1 : 0)

interface PropsSimpleSlider {
  setFunction: Function
  booleanFunction: boolean
  text?: string
  styles?: React.CSSProperties
  styleTypo?: React.CSSProperties
  className?: string
}

export default (props: PropsSimpleSlider): JSX.Element => (
  <Wrapper>
    <Typo
      className={props.className + " slider"}
      style={{ ...props.styleTypo }}
    >
      {props.text}
    </Typo>
    <WrapperSlider>
      <Slider
        color={Colors.mainOrange}
        onChange={e => handleAction(e, props.setFunction)}
        value={booleanToInteger(props.booleanFunction)}
        max={1}
        width={30}
        style={{
          display: "inline-block",
          float: "right",
        }}
      />
    </WrapperSlider>
  </Wrapper>
)
