import React, { useState, useEffect, useContext } from "react"
import { connect } from "react-redux"
import propTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { selectDish } from "../../../../actions/selectedDish"
import { selectRest } from "../../../../actions/selectedRest"
import { PropsWrappers } from "../../../constants/index"
import { HashValuesContext } from "../../../constants/index"
import { ExampleData } from "../../../utils/data/data"

import DishFound from "./DishFound"

interface PropsWrapperEvaluate extends PropsWrappers {
  widthCard?: number
  fetching?: Function
}

const WrapperEvaluate: React.FC<PropsWrapperEvaluate> = props => {
  const { t } = useTranslation("common")
  const [dishName, setDishName] = useState("")
  const [dishUid, setDishUid] = useState("")
  const [loaded, setLoaded] = useState(false)
  const hashValues = useContext(HashValuesContext)

  useEffect(() => {
    if (hashValues.selected !== "" && hashValues.selected !== "none") {
      setDishName(JSON.parse(decodeURIComponent(hashValues.selected)).dishName)
      setDishUid(JSON.parse(decodeURIComponent(hashValues.selected)).dishUid)
    }
  }, [JSON.stringify(hashValues)])

  useEffect(() => {
    if (
      hashValues.find === "dish" &&
      dishUid &&
      dishUid !== "" &&
      dishUid !== undefined
    ) {
      props.dispatch(selectDish(dishUid, false))
      props.fetching(true)
    }
  }, [dishUid])

  useEffect(() => {
    if (
      props.state.selectedDish.restaurants &&
      props.state.selectedDish.restaurants.length > 0
    ) {
      props.dispatch(
        selectRest(props.state.selectedDish.restaurants[0].rest.uid, false, 'find')
      )
    }
  }, [JSON.stringify(props.state.selectedDish.restaurants)])

  useEffect(() => {
    if (!(props.state.evaluations && props.state.evaluations.evaluations))
      setLoaded(false)
    if (hashValues.find === "dish") {
      if (
        props.state.selectedDish.dishDoc &&
        props.state.selectedDish.restaurants &&
        props.state.selectedDish.restaurants.length > 0 &&
        props.state.selectedRest.restDoc &&
        props.state.evaluations &&
        props.state.evaluations.evaluations
      ) {
        setLoaded(true)
        props.fetching(false)
      } else {
        setLoaded(false)
        props.fetching(true)
      }
    }
  }, [props.state.evaluations])

  if (hashValues.find !== "dish") return null

  return loaded ? (
    <DishFound
      dish={{
        ...props.state.selectedDish.dishDoc,
        restaurants: props.state.selectedDish.restaurants,
      }}
      selectedRest={props.state.selectedRest.restDoc}
      evaluations={props.state.evaluations.evaluations}
    />
  ) : null

  {
    /* <DishFound dish={ExampleData.selectedDish.dishDoc} /> */
  }
}

WrapperEvaluate.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(WrapperEvaluate)
