import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import "../../../../node_modules/@fortawesome/fontawesome-free/css/all.css"

import Typo from "../../0_atoms/Typo/Typo"
import DropdownComponent from "../Dropdown/dropdown"
import DropdownListWrapper from "../Dropdown/WrapperDropdownList"
import { dropdownList } from "../../constants/objects"
import WrapperLogin from "../Login/WrapperLogin"
import WrapperSignup from "../SignUp/WrapperSignup"

const Wrapper = styled.div`
  width: 205px;
`

const WrapperButton = styled.div`
  padding-right: 15px;
`

interface PropsBarBtns {
  isLogged: boolean
}

export default (props: PropsBarBtns): JSX.Element => {
  const [isLogging, setIsLogging] = useState(false)
  const [isSigning, setIsSigning] = useState(false)
  const [show, setShow] = useState(false)
  const { t } = useTranslation("common")

  useEffect(() => {
    if (isLogging && isSigning) setIsSigning(false)
  }, [isLogging])

  useEffect(() => {
    if (isLogging && isSigning) setIsLogging(false)
  }, [isSigning])

  return (
    <>
      {props.isLogged && (
        <WrapperButton>
          <DropdownComponent
            buttonLabel={
              <i style={{ textShadow: "none" }} className={"fas fa-bars"} />
            }
            dropdownContent={
              <DropdownListWrapper
                dropdownList={dropdownList}
                setShow={setShow}
              />
            }
            onClickDropdown={() => setShow(!show)}
            pressed={show}
            leftDropdown={-128}
          />
        </WrapperButton>
      )}
      {!props.isLogged && (
        <Wrapper>
          <DropdownComponent
            buttonLabel={t("signup")}
            onClickDropdown={() => setIsSigning(!isSigning)}
            pressed={isSigning}
            leftDropdown={-121}
            dropdownContent={<WrapperSignup pressed={isSigning} {...props} />}
          />
          <Typo style={{ padding: "4px" }} {...props}>
            {" "}
            ou{" "}
          </Typo>
          <DropdownComponent
            buttonLabel={"Login"}
            onClickDropdown={() => setIsLogging(!isLogging)}
            pressed={isLogging}
            leftDropdown={-151}
            dropdownContent={<WrapperLogin pressed={isLogging} setIsLogging={setIsLogging} {...props} />}
          />
        </Wrapper>
      )}
    </>
  )
}
