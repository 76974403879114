import React, { useRef, useEffect, useState, useContext } from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import * as d3 from "d3"
import { useTranslation } from "react-i18next"

import { Colors } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"
import { PropsGrades } from '../../utils/data/utilData'

import GradesSpiderChart from "../../0_atoms/Grades/GradesSpiderChart"
import Typo from "../../0_atoms/Typo/Typo"

const Wrapper = styled.div`
position: relative;
max-width: 260px;
margin-bottom: 15px;
padding-right: 10px;
padding-left: 10px
margin: auto;
padding: 40px 10px 0px;
margin-top: -8px;
height: 255px;
display: block;
`

const Square = styled.div`
  width: 7px;
  height: 7px;
  background-color: ${props => props.color};
  opacity: 1;
  display: inline-block;
`

const auxiliaryTextStyle: React.CSSProperties = {
  padding: "2px",
  marginTop: "0px",

  fontSize: "9px",
  marginRight: "4px",
  marginLeft: "30px",
  lineHeight: "11px",

  textAlign: "left",
  display: "block",

  cursor: "pointer",
}

const styleCategories = (category: string): React.CSSProperties => {
  switch (category) {
    case "taste":
      return { position: "absolute", top: "10px", left: "78px" } as React.CSSProperties
      break
    case "price":
      return { position: "absolute", top: "72px", left: "177px" } as React.CSSProperties
      break
    case "time":
      return { position: "absolute", top: "192px", left: "143px" } as React.CSSProperties
      break
    case "size":
      return { position: "absolute", top: "192px", left: "21px" } as React.CSSProperties
      break
    case "presentation":
      return { position: "absolute", top: "72px", left: "-22px" }  as React.CSSProperties//75px
      break
  }
} 

interface PropsSpideChart {
  grades_focus?: PropsGrades
  grades_basis?: PropsGrades
}

const Chart: React.FC<PropsSpideChart> = props => {
  const { t } = useTranslation("common")
  const { styleMode, theme } = useContext(ThemeContext)
  const [displayBasis, setDisplayBasis] = useState(true)
  const [displayFocus, setDisplayFocus] = useState(true)
  const ref = useRef()

  // Definitions for creating and updating the charts
  let size = 170
  let marginFixed = 10
  var margin = {
      top: marginFixed,
      right: marginFixed,
      bottom: marginFixed,
      left: marginFixed,
    },
    width = size - margin.left - margin.right,
    height = size - margin.top - margin.bottom

  var fixDegree = (Math.PI / 12) * 1.2

  var testGrades = {
    taste: 0,
    presentation: 0,
    size: 0,
    time: 0,
    price: 0,
  }

  var strokeOpacity =
    theme === "light" ? 0.15 : styleMode === "new" ? 0.55 : 0.65 //0.25 : 0.35

  const xPosition = (grade, radius, characteristic) =>
    (radius / 5) *
      grade *
      Math.cos(((2 * Math.PI) / 5) * characteristic - fixDegree) +
    width / 2

  const yPosition = (grade, radius, characteristic) =>
    (radius / 5) *
      grade *
      Math.sin(((2 * Math.PI) / 5) * characteristic - fixDegree) +
    height / 2

  useEffect(() => {
    d3.select(ref.current).selectAll("*").remove()

    var svg = d3
      .select(ref.current)
      .append("svg")
      .style("display", "block")
      .style("margin", "auto")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    // var circles = svg.append("g")

    // const circlesArray = [width / 6, width / 3, width / 2]

    // circlesArray.map(e => {
    //   circles
    //     .append("circle")
    //     .attr("cx", width / 2)
    //     .attr("cy", height / 2)
    //     .attr("r", e)
    //     .style("fill", "none")
    //     .style("stroke", "black")
    // })

    // Main functions

    let linesAxis = svg.append("g")
    let radius = width / 2 + 10
    let fillOpacity = theme === "light" ? 0.3 : styleMode === "new" ? 0.55 : 0.7

    // Axis line
    const lines = ["Price", "Time", "Size", "Presentation", "Taste"]
    lines.map((e, i) => {
      linesAxis
        .append("line")
        .attr("x1", width / 2)
        .attr("x2", xPosition(5, radius, i))
        .attr("y1", height / 2)
        .attr("y2", yPosition(5, radius, i))
    })

    // Auxiliary Lines
    let lineMarks = svg.append("g")
    for (let idx = 1; idx < 6; idx++) {
      lines.map((e, i) => {
        lineMarks
          .append("line")
          .attr("x1", xPosition(idx, width / 2, i))
          .attr("x2", xPosition(idx, width / 2, i + 1))
          .attr("y1", yPosition(idx, height / 2, i))
          .attr("y2", yPosition(idx, height / 2, i + 1))
      })
    }

    svg
      .selectAll("line")
      .style("stroke", Colors[styleMode][theme].letterAuxiliary)
      .style("stroke-width", 1)
      .style("stroke-opacity", 0.3)

    // Function that draws the chart path
    const drawChart = (svg, gradesInput, color, classname) => {
      let grades = [
        gradesInput.presentation,
        gradesInput.taste,
        gradesInput.price,
        gradesInput.time,
        gradesInput.size,
      ]
      //Object.keys(gradesInput).map(e => grades.push(gradesInput[e]))
      //let grades =
      grades.push(grades[0])
      return svg
        .append("path")
        .datum(grades)
        .attr("fill", color)
        .attr("class", classname)
        .attr("fill-opacity", fillOpacity)
        .attr("stroke", color)
        .attr("stroke-width", 1.8)
        .attr(
          "d",
          d3
            .line()
            .x((d, idx) => xPosition(d, width / 2, idx))
            .y((d, idx) => yPosition(d, height / 2, idx))
        )
        .on("mouseover", function (d, i) {
          d3.select(this)
            .transition()
            .duration("50")
            .attr("fill-opacity", ".75")
        })
        .on("mouseout", function (d, i) {
          d3.select(this).transition().duration("50").attr("fill-opacity", ".4")
        })
        .append("title")
        .text(
          classname === "grades_focus"
            ? t("charts.spiderChart.restaurantsGradeTitle")
            : t("charts.spiderChart.avaragesGradeTitle")
        )
    }

    if (props.grades_basis !== undefined)
      drawChart(svg, testGrades, Colors.mainGray, "grades_basis")
    if (props.grades_focus !== undefined)
      drawChart(svg, testGrades, Colors.mainOrange, "grades_focus")
  }, [])

  const updatingPaths = (gradesInput, className) => {
    // let grades = []
    // Object.keys(gradesInput).map(e => grades.push(gradesInput[e]))
    let grades = [
      gradesInput.price,
      gradesInput.time,
      gradesInput.size,
      gradesInput.presentation,
      gradesInput.taste,
    ]
    grades.push(grades[0])
    d3.select(ref.current)
      .select(className)
      .datum(grades)
      .transition()
      .duration(700)
      .attr(
        "d",
        d3
          .line()
          .x((d, idx) => xPosition(d, width / 2, idx))
          .y((d, idx) => yPosition(d, height / 2, idx))
      )
  }

  useEffect(() => updatingPaths(props.grades_basis, ".grades_basis"), [
    props.grades_basis,
  ])

  useEffect(() => updatingPaths(props.grades_focus, ".grades_focus"), [
    props.grades_focus,
  ])

  const changeDisplay = (className, state) => {
    d3.select(ref.current)
      .select(className)
      .style("display", state ? "unset" : "none")
  }

  useEffect(() => changeDisplay(".grades_focus", displayFocus), [displayFocus])

  useEffect(() => changeDisplay(".grades_basis", displayBasis), [displayBasis])

  return (
    <Wrapper>
      <div ref={ref} />
      {Object.entries(
        props.grades_focus !== undefined
          ? props.grades_focus
          : props.grades_basis
      ).map((e, i) => (
        <div key={i} style={styleCategories(e[0])}>
          <GradesSpiderChart
            key={e[0]}
            {...props}
            category={e}
            reference={
              props.grades_focus !== undefined
                ? props.grades_basis[e[0]]
                : undefined
            }
            displayFocus={displayFocus}
            displayBasis={displayBasis}
          />
        </div>
      ))}
      <Typo
        style={{
          ...auxiliaryTextStyle,
          marginTop: "15px",
          filter: displayFocus ? "none" : "opacity(.5)",
          color: Colors[styleMode][theme].letterAuxiliary,
          opacity: "unset",
        }}
        {...props}
        onClick={() => setDisplayFocus(!displayFocus)}
      >
        <Square color={Colors.mainOrange} />{" "}
        {t("charts.spiderChart.restaurantsGradeSubtitle")}
      </Typo>
      <Typo
        style={{
          ...auxiliaryTextStyle,
          filter: displayBasis ? "none" : "opacity(.5)",
          color: Colors[styleMode][theme].letterAuxiliary,
          opacity: "unset",
        }}
        {...props}
        onClick={() => setDisplayBasis(!displayBasis)}
      >
        <Square color={Colors.mainGray} />{" "}
        {t("charts.spiderChart.avarageGradeSubtitle")}
      </Typo>
    </Wrapper>
  )
}

export default Chart
