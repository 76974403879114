import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import Typo from "../../0_atoms/Typo/Typo"
import Input from "../../0_atoms/Input/Input"
import Button from "../../0_atoms/Button/Button"


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 130px;
  flex-flow: row wrap;
  align-content: space-around;
`

interface PropsModesDropdownLogin {
  handleLogin?: Function
  handleForget?: Function
  pressed?: boolean
}

export default (props:PropsModesDropdownLogin): JSX.Element=> {
  const { t } = useTranslation("common")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    setEmail("")
    setPassword("")
  }, [props.pressed])

  return (
    <Wrapper>
      <Input
        onChange={e => setEmail(e.target.value)}
        placeholder="E-mail"
        value={email}
        size="22"
      />

      <Input
        type="password"
        onChange={e => setPassword(e.target.value)}
        placeholder={t("login.password")}
        value={password}
        size="22"
      />

      <Typo onClick={() => props.handleForget(email)} simpleLink>
        {t("login.forgotThePassowrd")}
      </Typo>

      <Button onClick={() => props.handleLogin(email, password)} >
        {t("login.next")}
      </Button>
    </Wrapper>
  )
}
