import React from "react"
import styled from "styled-components"

import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import thunkMiddleware from "redux-thunk"
import rootReducer from "../../reducers"

export interface PropsCaretComponent {
  caretData: {
    label: string
    title: string
    value: number | string
    data: string
    classNamePreffix: string
    component: JSX.Element
  }
  noDistanceTitle?: boolean
  clickOpenCaret: Function
  label?: string
}

export interface PropsModesWrappers {
  state?: any
  dispatch?: any
  setShow: any
}

export interface PropsWrappers {
  state?: any
  dispatch?: any
}

interface PropsTheme{
  styleMode: string
  theme: string
}

export const lightClassic: PropsTheme = {
  styleMode: "classic",
  theme: "light",
}

export const darkClassic: PropsTheme = {
  styleMode: "classic",
  theme: "dark",
}

export const lightNew: PropsTheme = {
  styleMode: "new",
  theme: "light",
}

export const darkNew: PropsTheme = {
  styleMode: "new",
  theme: "dark",
}

export const styles = [
  {
    styleMode: "Classic",
    values: [
      {
        theme: "Light",
        props: lightClassic,
      },
      {
        theme: "Dark",
        props: darkClassic,
      },
    ],
  },
  {
    styleMode: "New",
    values: [
      {
        theme: "Light",
        props: lightNew,
      },
      {
        theme: "Dark",
        props: darkNew,
      },
    ],
  },
]

export const WrapperStyle = styled.div`
  padding: 15px;
  margin: auto;
  width: fit-content;
  text-align: center;
  font-family: Montserrat;

  p {
    font-size: 24px;
    letter-spacing: 2px;
    margin-top: 0px;
  }
`

export const WrapperTheme = styled.div`
  margin: 20px;
  margin-top: 0px;
  display: inline-block;
  vertical-align: top;
`

export const Wrapper = styled.div`
  padding: 50px 50px;
  margin: auto;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  width: ${props => (props.width ? props.width + "px" : "min-content")};
  ${props => (props.height ? "height: " + props.height + "px;" : "")}
  ${props => (props.right ? "text-align: right;" : "")}
  ${props =>
    props.paddingLeft
      ? "padding-left: " + props.paddingLeft + "px;"
      : ""}
  
  background-color: ${props =>
    props.styleMode === "classic"
      ? props.theme === "light"
        ? "#F0F0F0"
        : "#121212"
      : props.theme === "light"
      ? "#F0F0F0"
      : "#333333"};
`

function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunkMiddleware)
  )
}
export const store = configureStore({})

export const ThemeContext = React.createContext(lightClassic)

export interface PropsHashes {
  find: string
  evaluate: string
  configurations: string
  selected: string
}

export const initialState: PropsHashes = {
  find: "presentation",
  evaluate: "presentation",
  configurations: "none",
  selected: ""
}

export const HashValuesContext = React.createContext(initialState)

export const StoriesStyles = props => {
  return (
    <div>
      {styles.map((e, i) => (
        <WrapperStyle key={i}>
          <p>{e.styleMode}</p>
          {e.values.map((f, j) => (
            <WrapperTheme key={j}>
              <p>{f.theme}</p>
              <Wrapper {...f.props} {...props}>
                <ThemeContext.Provider
                  value={{
                    styleMode: e.styleMode.toLowerCase(),
                    theme: f.theme.toLowerCase(),
                  }}
                >
                  {props.children}
                </ThemeContext.Provider>
              </Wrapper>
            </WrapperTheme>
          ))}
        </WrapperStyle>
      ))}
    </div>
  )
}

export const StoriesStylesWithStore = props => {
  return (
    <Provider store={store}>
      <StoriesStyles {...props} />
    </Provider>
  )
}

export const selectStyle = (theme, style) => {
  let themeStyle
  switch (theme + style) {
    case "lightclassic":
      themeStyle = lightClassic
      break
    case "darkclassic":
      themeStyle = darkClassic
      break
    case "lightskeumorphic":
      themeStyle = lightNew
      break
    case "darkskeumorphic":
      themeStyle = darkNew
      break
    default:
      themeStyle = lightClassic
  }
  return themeStyle
}
