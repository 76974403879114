import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import Typo from "../../../0_atoms/Typo/Typo"

const Wrapper = styled.div`
  text-align: center;
  line-height: 20px;
  height: 34px;
`

const WrapperComponent = styled.div`
  flex-direction: column;
  animation: 0.51s opening;

  @keyframes opening {
    from {
      display: none;
      opacity: 0;
      height: 0px;
    }
    20% {
      display: flex;
      height: 0px;
    }
    60% {
      height: 181px;
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      height: unset;
    }
  }

  .references {
    padding-left: 20px;
    font-size: 13px;
    width: 280px;
    text-align: left;
  }

  .fab {
    font-size: 18px;
    padding: 2px 5px;
    opacity: 0.6;
  }

  .description {
    display: block;
    padding: 10px 20px 5px;
    font-size: 13px;
  }
`

export default () => {
  const { t } = useTranslation("common")

  return (
    <WrapperComponent className="about-help-items">
      <Typo cardDestak style={{ display: "block", paddingBottom: "5px" }}>
        {t("configs.aboutHelp.title")}
      </Typo>
      <Typo className="description">{t("configs.aboutHelp.description")}</Typo>

      <Wrapper>
        <Typo className="references">
          <i className="fab fa-facebook" />
          MelhoresPratos
        </Typo>
      </Wrapper>

      <Wrapper>
        <Typo className="references">
          <i className="fab fa-instagram" />
          @melhorespratos
        </Typo>
      </Wrapper>

      <Wrapper>
        <Typo className="references">suporte@melhorespratos.com.br</Typo>
      </Wrapper>
    </WrapperComponent>
  )
}
