import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { gsap } from "gsap"
import styled from "styled-components"

import { PropsKpis } from "../../../utils/data/data"
import { handleStates } from "../../../utils/misc"
import { tweenEffect } from "../../../utils/animations"

import Typo from "../../../0_atoms/Typo/Typo"
import Chart from "../../../0_atoms/Charts/CartesianChart"
import SimpleSlider from "../../../1_molecules/SimpleSlider/SimpleSlider"

const dataTypes = ["evaluations", "users", "dishes", "restaurants", "searches"]

const Wrapper = styled.div`
  flex-direction: column;
  animation: 0.51s opening;

  @keyframes opening {
    from {
      display: none;
      opacity: 0;
      height: 0px;
    }
    20% {
      display: flex;
      height: 0px;
    }
    60% {
      height: 266px;
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      height: unset;
    }
  }
`

interface PropsAppData {
  kpis: PropsKpis
  widthCard?: number
}

export default (props: PropsAppData): JSX.Element => {
  const [states, setStates] = useState(new Array(dataTypes.length).fill(false))
  const [kpis, setKpis] = useState(props.kpis)

  const [tweenEvaluations] = useState(gsap.timeline({ paused: true }))
  const [tweenUsers] = useState(gsap.timeline({ paused: true }))
  const [tweenDishes] = useState(gsap.timeline({ paused: true }))
  const [tweenRestaurants] = useState(gsap.timeline({ paused: true }))
  const [tweenSearches] = useState(gsap.timeline({ paused: true }))

  useEffect(() => {
    tweenEffect(tweenEvaluations, ".appData-evaluations", "175px")
    tweenEffect(tweenUsers, ".appData-users", "175px")
    tweenEffect(tweenDishes, ".appData-dishes", "175px")
    tweenEffect(tweenRestaurants, ".appData-restaurants", "175px")
    tweenEffect(tweenSearches, ".appData-searches", "175px")
  }, [])

  useEffect(() => {
    tweenEvaluations.reversed(!states[0])
    tweenUsers.reversed(!states[1])
    tweenDishes.reversed(!states[2])
    tweenRestaurants.reversed(!states[3])
    tweenSearches.reversed(!states[4])
  }, [JSON.stringify(states)])

  useEffect(() => setKpis(props.kpis), [JSON.stringify(props.kpis)])

  const { t } = useTranslation("common")

  return (
    <Wrapper className="app-data-items">
      <Typo cardDestak style={{ display: "block", paddingBottom: "20px" }}>
        {t("configs.appData.title")}
      </Typo>

      {dataTypes.map((e, i) => (
        <div key={"0-" + i}>
          <SimpleSlider
            text={t("configs.appData." + e)}
            setFunction={f => handleStates(f, i, states, setStates)}
            booleanFunction={states[i]}
          />
          <Chart
            className={"appData-" + e}
            style={{ opacity: 0, height: 0, display: "none" }}
            y={kpis[e].quantityByDay}
            x={kpis[e].days}
            widthCard={props.widthCard}
          />
        </div>
      ))}
    </Wrapper>
  )
}
