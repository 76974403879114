import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { InstantSearch, Configure } from "react-instantsearch/dom"
import algoliasearch from "algoliasearch/lite"
import { gsap, TweenMax } from "gsap"
import { tweenConfigurationsPartAppear } from "../../../utils/animations"

import SearchBox from "./wrapperSearchBox"
import Hits from "./wrapperHits"

const ComponentsWrapper = styled.div`
  display: none;
  flex-direction: column;
  opacity: 0;
  height: 0px;
`

const searchClient = algoliasearch(
  "IF3U0V4NTS",
  "6a799c8787e6e0f3b4bcb939f74962f4"
)

export default (): JSX.Element => {
  const [tweenEvaluateRestaurant]: any = useState(
    gsap.timeline({ paused: true })
  )
  const TweenMaxTyped: any = TweenMax

  useEffect(() => {
    tweenConfigurationsPartAppear(
      tweenEvaluateRestaurant,
      ".evaluate-restaurant-items",
      167
    )
    TweenMaxTyped.delayedCall(1, () => tweenEvaluateRestaurant.reversed(false))
    return () =>
      TweenMaxTyped.delayedCall(0.25, () =>
        tweenEvaluateRestaurant.reversed(true)
      )
  }, [])

  return (
    <ComponentsWrapper className="evaluate-restaurant-items">
      <InstantSearch
        indexName={"restaurants"} //props.indexName
        searchClient={searchClient}
      >
        <SearchBox />
        <Configure hitsPerPage={20} />
        <Hits />
      </InstantSearch>
    </ComponentsWrapper>
  )
}
