import C from "../actions/constants"

const initialState = {
  evaluations: {
    quantityByDay: [],
    days: [],
  },
  users: {
    quantityByDay: [],
    days: [],
  },
  dishes: {
    quantityByDay: [],
    days: [],
  },
  restaurants: {
    quantityByDay: [],
    days: [],
  },
  searches: {
    quantityByDay: [],
    days: [],
  },
}

export const kpis = (state = initialState, action) => {
  switch (action.type) {
    case C.KPIS_FETCHED:
      return Object.assign({}, state, { ...action.payload })
    default:
      return state
  }
}
