import React from "react"
import propTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { PropsWrappers } from "../../../constants/index"
import { PropsAppConfigs } from "../../../utils/data/data"
import { saveConfig } from "../../../../actions/appConfigs"

import AppConfigurations from "./AppConfigurations"

const WrapperAppConfigurations: React.FC<PropsWrappers> = props => {
  const { t } = useTranslation("common")

  const handleSaveConfigs = (configs: PropsAppConfigs): void => {
    if (configs.pilotMessage === "" && configs.isInPilotMode)
      return alert(t("configs.appConfigurations.pilotMessage"))
    return props.dispatch(saveConfig(configs))
  }

  return (
    <AppConfigurations
      {...props.state.appConfigs}
      saveConfigs={handleSaveConfigs}
    />
  )
}

WrapperAppConfigurations.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(WrapperAppConfigurations)
