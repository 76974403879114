import React, { useContext } from "react"
import styled from "styled-components"
import { Colors, Shadows } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"

const makeLongShadow = (color, size, value, styleMode, max, width) => {
  let i = 0
  let shadow =
    styleMode === "classic"
      ? `${i}px 0 0 ${size} ${color}`
      : `inset 0 0 1.5px 1px rgba(0,0,0,0.3), ${i}px 0 0 ${size} ${color}`

  const findValueShadow = (value, max) =>
    (value * width * 1.081) / max - (max > 2 ? (value - 1) * max : 10)

  let valueShadow = findValueShadow(value, max)

  for (; i < valueShadow; i++) {
    shadow = `${shadow}, -${i}px 0 0 ${size} ${color}`
  }

  return shadow
}

interface PropsRangeSliderComponent {
  color?: string
  onChange: Function
  value: number
  width?: number
  max: number
  style?: React.CSSProperties
}

interface PropsRangeSlider extends PropsRangeSliderComponent {
  styleMode: string
  theme: string
  upperColor: string
  min: string
}

const RangeSlider: React.FC<PropsRangeSlider> = styled.input.attrs({
  type: "range",
})`
  -webkit-appearance: none;
  width: ${props => props.width}px;
  height: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  // -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  transform: translateY(-2.5px);

  ${props => Shadows[props.styleMode][props.theme].Lm2upin}

  ${props =>
    props.styleMode === "new"
      ? "background-color:" + Colors[props.styleMode][props.theme].L45 + ";"
      : ""};

  input[type="range"] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    background: transparent; /* Otherwise white in Chrome */
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type="range"]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: ${props =>
      props.styleMode === "classic"
        ? props.color
        : Colors[props.styleMode][props.theme].L45};
    cursor: pointer;
    border-radius: 12px;
    margin-top: 0px;
    box-shadow: ${props =>
      makeLongShadow(
        props.color,
        "-4px",
        props.value,
        props.styleMode,
        props.max,
        props.width
      )};
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: ${props => props.color};
    cursor: pointer;
  }

  &::-moz-range-progress {
    background-color: ${props => props.color};
    height: 10px;
    border-radius: 10px;
  }

  &::-ms-fill-lower {
    background-color: ${props => props.color};
    height: 10px;
    border-radius: 10px;
  }
`

export default (props: PropsRangeSliderComponent): JSX.Element => {
  const { onChange } = props
  const theme = useContext(ThemeContext)

  return (
    <RangeSlider
      upperColor={"#d3d3d3"}
      min="0"
      max={props.max}
      color={props.color}
      value={props.value}
      onChange={onChange}
      width={props.width}
      {...theme}
    />
  )
}
