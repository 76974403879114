import React, { useState, useEffect, useContext } from "react"
import styled, { css } from "styled-components"
import { gsap } from "gsap"

import { Colors, Shadows } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"
import { PropsEvaluations } from "../../utils/data/utilData"
import { tweenOpacityShadowOpacity } from "../../utils/animations"

import Evaluations from "../../1_molecules/Descriptions/Evaluation"

const Wrapper = styled.div`
  margin: auto;
  height: 300px;
  margin-top: 15px;
  margin-left: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #b1afaf;
  border-radius: 5px;
  padding-bottom: 10px;
  ${props =>
    props.transition
      ? "box-shadow: " + Shadows[props.styleMode][props.theme].Lm5upin0 + ";"
      : Shadows[props.styleMode][props.theme].Lm5upin}

  opacity: 0;
  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => Colors[props.styleMode][props.theme].scrollTrack};
    border-radius: 5px;
    ${props =>
      props.styleMode === "new" &&
      `
        box-shadow: inset -2px -2px 4px ${props =>
          props.theme === "light" ? "#898989" : "#cfcfcf"};
      `}
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${props => Colors[props.styleMode][props.theme].scrollBar};
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 5px;
    background: ${props => Colors[props.styleMode][props.theme].scrollHover};
  }

  ${props =>
    props.styleMode === "new" &&
    css`
      border: none;
    `}
`

interface PropsInfiniteScrollEvaluations {
  //widthCard?: number
  evaluations: Array<PropsEvaluations>
  onClickItem?: Function
  transition?: boolean
}

export default (props: PropsInfiniteScrollEvaluations): JSX.Element => {
  const theme = useContext(ThemeContext)
  const [tweenRestaurants] = useState(gsap.timeline({ paused: true }))

  useEffect(() => {
    tweenOpacityShadowOpacity(
      tweenRestaurants,
      ".infinite-scroll-evaluations-0.",
      ".evaluationDescription",
      theme.styleMode,
      theme.theme,
      "Lm5upin1"
    )
    tweenRestaurants.reversed(false)
  }, [])

  //useEffect(() => {}, [props.widthCard])

  return (
    <Wrapper
      className={
        "infinite-scroll-evaluations-0 " + theme.styleMode + theme.theme
      }
      {...theme}
    >
      {props.evaluations.map((e, i) => (
        <Evaluations key={i} evaluation={e} />
      ))}
    </Wrapper>
  )
}
