interface PropsColors {
  base: string
  L00: string
  L05: string
  L10: string
  L15: string
  L20: string
  L25: string
  L30: string
  L35: string
  L40: string
  L45: string
  letterButton: string
  letterUsual: string
  letterCardText?: string
  letterAuxiliary?: string
  letterAuxiliary2?: string
  scrollBar: string
  scrollTrack: string
  scrollHover: string
  rangeSlider: string
}

interface ThemeColors {
  light: PropsColors
  dark: PropsColors
}

interface PropsStylesColors {
  mainOrange: string
  mainGray: string
  auxiliaryGray: string

  classic: ThemeColors
  new: ThemeColors

  taste: string
  price: string
  size: string
  presentation: string
  time: string
}

const testAuxiliary2 = "rgba(0, 0, 0, 0.5)"

export const Colors: PropsStylesColors = {
  mainOrange: "#FF8F00",
  mainGray: "#9e9e9e",
  auxiliaryGray: "#e9e9e9",

  taste: "#FF8F00",
  price: "#caa4dd",
  size: "#2B83BA",
  presentation: "#2fcc2d",
  time: "#D7191C",

  classic: {
    light: {
      base: "#F0F0F0",
      L00: "#F5F5F5",
      L05: "#F6F6F6",
      L10: "#F7F7F7",
      L15: "#F8F8F8",
      L20: "#F9F9F9",
      L25: "#FCFCFC",
      L30: "#FAFAFA",
      L35: "#FBFBFB",
      L40: "#FCFCFC",
      L45: "#FDFDFD",
      letterButton: "#",
      letterUsual: testAuxiliary2, //"#000000",
      letterCardText: testAuxiliary2, //"#989898",//"rgba(0, 0, 0, 0.8)",
      letterAuxiliary: testAuxiliary2, //"#989898",
      letterAuxiliary2: testAuxiliary2,
      scrollBar: "#888",
      scrollTrack: "#cfcfcf",
      scrollHover: "#555",
      rangeSlider: "#d3d3d3",
    },
    dark: {
      base: "#121212",
      L00: "#1E1E1E",
      L05: "#1E1E1E",
      L10: "#222222",
      L15: "#242424",
      L20: "#272727",
      L25: "#2C2C2C",
      L30: "#2E2E2E",
      L35: "#333333",
      L40: "#353535",
      L45: "#383838",
      letterButton: "#",
      letterUsual: "#FFFFFF",
      letterCardText: "#FFFFFF",
      letterAuxiliary: "#d2d2d2",
      letterAuxiliary2: "#FFFFFF",
      scrollBar: "#888",
      scrollTrack: "#222222",
      scrollHover: "#555",
      rangeSlider: "#d3d3d3",
    },
  },
  new: {
    light: {
      base: "#F0F0F0",
      L00: "#F0F0F0",
      L05: "#F0F0F0",
      L10: "#F0F0F0",
      L15: "#F0F0F0",
      L20: "#F0F0F0",
      L25: "#F0F0F0",
      L30: "#F0F0F0",
      L35: "#F0F0F0",
      L40: "#F0F0F0",
      L45: "#F0F0F0",
      letterButton: "#",
      letterUsual: testAuxiliary2, //"#000000",
      letterCardText: testAuxiliary2, //"#989898",//"rgba(0, 0, 0, 0.8)",
      letterAuxiliary: "#989898",
      letterAuxiliary2: testAuxiliary2,
      scrollBar: "#888",
      scrollTrack: "#cfcfcf",
      scrollHover: "#555",
      rangeSlider: "#EAEAEA",
    },
    dark: {
      base: "#333333",
      L00: "#333333",
      L05: "#333333",
      L10: "#333333",
      L15: "#333333",
      L20: "#333333",
      L25: "#333333",
      L30: "#333333",
      L35: "#333333",
      L40: "#333333",
      L45: "#121212",
      letterButton: "#",
      letterUsual: "#FFFFFF",
      letterCardText: "#FFFFFF",
      letterAuxiliary: "#FFFFFF",
      letterAuxiliary2: "#FFFFFF",
      scrollBar: "#888",
      scrollTrack: "#333333",
      scrollHover: "#555",
      rangeSlider: "#313030",
    },
  },
}

interface PropsShadows {
  Lm2upin?: string
  Lm5up?: string
  Lm5upin?: string
  Lm5upin0?: string
  Lm5upin1?: string
  Lm5upin2?: string
  L00up?: string
  Lm5: string
  Lleftbutton?: string
  Lleftbuttonpressed?: string
  dropdownShadow: string
  L00: string
  L05: string
  L10: string
  L15: string
  L20: string
  L25: string
  L30: string
  L35: string
  L40: string
  L45: string
  L00round: string
  L00roundpressed: string
  round: string
  LDD?: string
}

interface ThemeShadows {
  light: PropsShadows
  dark: PropsShadows
}

interface PropsStylesShadows {
  classic: ThemeShadows
  new: ThemeShadows
}

export const Shadows: PropsStylesShadows = {
  classic: {
    light: {
      Lm2upin: "",
      Lm5up: "box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);background:#F5F5F5",
      Lm5upin: "",
      Lm5upin0: "",
      Lm5upin1: "",
      Lm5upin2: "",
      L00up: "4px 4px 4px rgba(0, 0, 0, 0.25);",
      L00round: "4px 4px 4px rgba(0, 0, 0, 0.25);",
      L00roundpressed:
        "box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);background:#F5F5F5",
      Lm5: "box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);background:#F5F5F5",
      L00: "4px 4px 4px rgba(0, 0, 0, 0.25);",
      L05: "5px 5px 5px rgba(0, 0, 0, 0.25);",
      L10: "6px 6px 6px rgba(0, 0, 0, 0.25);",
      L15: "7px 7px 7px rgba(0, 0, 0, 0.25);",
      L20: "8px 8px 8px rgba(0, 0, 0, 0.25);",
      L25: "9px 9px 9px rgba(0, 0, 0, 0.25);",
      L30: "10px 10px 10px rgba(0, 0, 0, 0.25);",
      L35: "11px 11px 11px rgba(0, 0, 0, 0.25);",
      L40: "12px 12px 12px rgba(0, 0, 0, 0.25);",
      L45: "13px 13px 13px rgba(0, 0, 0, 0.25);",
      LDD: "7px 7px 7px rgba(0, 0, 0, 0.25);",
      round: "",
      Lleftbutton:
        "box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);background:#F5F5F5",
        dropdownShadow: "",
    },
    dark: {
      Lm2upin: "",
      Lm5up: "filter:brightness(0.9)",
      Lm5upin: "",
      Lm5upin0: "",
      Lm5upin1: "",
      Lm5upin2: "",
      L00up: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L00round: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L00roundpressed: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      Lm5: "filter:brightness(0.9)",
      L00: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L05: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L10: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L15: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L20: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L25: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L30: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L35: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L40: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      L45: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      LDD: "0px 0px 0px rgba(0, 0, 0, 0.25);",
      round: "",
      Lleftbutton: "filter:brightness(0.9)",
      dropdownShadow: "",
    },
  },
  new: {
    light: {
      Lm2upin:
        "box-shadow: inset -1px -1px 2px #FFFFFF, inset 1px 1px 2px #CCCCCC;",
      Lm5up:
        "box-shadow: -5px -0px 10px #FFFFFF, 5px 5px 10px #CCCCCC; background:#F0F0F0",
      Lm5upin:
        "box-shadow: inset -2px -2px 4px #FFFFFF, inset 2px 2px 4px #CCCCCC;",
      Lm5upin0: "inset 0px 0px 0px #FFFFFF, inset 0px 0px 0px #CCCCCC",
      Lm5upin1: "inset -2px -2px 4px #FFFFFF, inset 2px 2px 4px #CCCCCC",
      Lm5upin2: "-2px -2px 4px #FFFFFF, 2px 2px 4px #CCCCCC",
      L00up: "-10px -0px 20px #FFFFFF, 10px 10px 20px #CCCCCC",
      L00round: "-2px -2px 3px rgba(255,255,255,.3), 10px 10px 20px #CCCCCC",
      L00roundpressed:
        "-1px -1px 1.5px rgba(255,255,255,.3), 5px 5px 10px #CCCCCC",
      Lm5:
        "box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #CCCCCC; background:#F0F0F0",
      L00: "-10px -10px 20px #FFFFFF, 10px 10px 20px #CCCCCC",
      L05: "-11px -11px 22px #FFFFFF, 11px 11px 22px #CCCCCC",
      L10: "-12px -12px 24px #FFFFFF, 12px 12px 24px #CCCCCC",
      L15: "-13px -13px 26px #FFFFFF, 13px 13px 26px #CCCCCC",
      L20: "-14px -14px 28px #FFFFFF, 14px 14px 28px #CCCCCC",
      L25: "-15px -15px 30px #FFFFFF, 15px 15px 30px #CCCCCC",
      L30: "-16px -16px 32px #FFFFFF, 16px 16px 32px #CCCCCC",
      L35: "-17px -17px 34px #FFFFFF, 17px 17px 34px #CCCCCC",
      L40: "-18px -18px 36px #FFFFFF, 18px 18px 36px #CCCCCC",
      L45: "-19px -19px 38px #FFFFFF, 19px 19px 38px #CCCCCC",
      LDD: "-12px 0px 24px #FFFFFF, 12px 12px 24px #CCCCCC",
      round:
        "linear-gradient(137.4deg, #FFFFFF 17.09%, rgba(216, 216, 216, 0) 95.17%), #F0F0F0;",
      Lleftbutton: "-10px -10px 20px #FFFFFF, 4px 4px 8px #CCCCCC",
      Lleftbuttonpressed: "-5px -5px 10px #FFFFFF, 2px 2px 4px #CCCCCC",
      dropdownShadow: "-2px 0px 4px #ffffff, 2px 2px 4px #cccccc",
    },
    dark: {
      Lm2upin:
        "box-shadow: inset -1px -1px 2px #3B3B3B, inset 1px 1px 2px #2B2B2B;",
      Lm5up:
        "box-shadow: -5px -0px 10px #3B3B3B, 5px 5px 10px #2B2B2B; background:#333333",
      Lm5upin:
        "box-shadow: inset -2px -2px 4px #3B3B3B, inset 2px 2px 4px #2B2B2B;",
      Lm5upin0: "inset 0px 0px 0px #3B3B3B, inset 0px 0px 0px #2B2B2B",
      Lm5upin1: "inset -2px -2px 4px #3B3B3B, inset 2px 2px 4px #2B2B2B",
      Lm5upin2: "-2px -2px 4px #3B3B3B, 2px 2px 4px #2B2B2B",
      L00up: "-10px -0px 20px #3B3B3B, 10px 10px 20px #2B2B2B",
      L00round: "-2px -2px 3px rgba(59,59,59,.3), 10px 10px 20px #2B2B2B",
      L00roundpressed:
        "-1px -1px 1.5px rgba(59,59,59,.3), 5px 5px 10px #2B2B2B",
      Lm5:
        "box-shadow: -5px -5px 10px #3B3B3B, 5px 5px 10px #2B2B2B; background:#333333",
      L00: "-10px -10px 20px #3B3B3B, 10px 10px 20px #2B2B2B",
      L05: "-11px -11px 22px #3B3B3B, 11px 11px 22px #2B2B2B",
      L10: "-12px -12px 24px #3B3B3B, 12px 12px 24px #2B2B2B",
      L15: "-13px -13px 26px #3B3B3B, 13px 13px 26px #2B2B2B",
      L20: "-14px -14px 28px #3B3B3B, 14px 14px 28px #2B2B2B",
      L25: "-15px -15px 30px #3B3B3B, 15px 15px 30px #2B2B2B",
      L30: "-16px -16px 32px #3B3B3B, 16px 16px 32px #2B2B2B",
      L35: "-17px -17px 34px #3B3B3B, 17px 17px 34px #2B2B2B",
      L40: "-18px -18px 36px #3B3B3B, 18px 18px 36px #2B2B2B",
      L45: "-19px -19px 38px #3B3B3B, 19px 19px 38px #2B2B2B",
      LDD: "-12px 0px 24px #3B3B3B, 12px 12px 24px #2B2B2B",
      round:
        "linear-gradient(137.4deg, #373737 17.09%, rgba(46, 46, 46, 0) 95.17%), #333333;",
      Lleftbutton: "-10px -10px 20px #3B3B3B, 4px 4px 8px #333333",
      Lleftbuttonpressed: "-5px -5px 10px #3B3B3B, 2px 2px 4px #333333",
      dropdownShadow: "-2px 0px 4px #3B3B3B, 2px 2px 4px #333333",
    },
  },
}

export const Animate = {
  oneCard: {
    D0: "0s",
    L0: "0.3s",
    D1b: "0.35s",
    D1: "0.3s",
    L1: "0.3s",
    D2: "0.55s",
    L2: "0.1s",
  },
}
