import C from "./constants"
import * as firebase from "firebase"
import { searchingItem } from "./index"
import { fetchingEvaluations } from "./evaluations"

const nullRest = {
  evaluations: 0,
  name: "",
  uid: "",
  grades_abs: null,
  grades_avg: null,
  dishes: null,
  tags: [],
  location: null,
  newItem: null,
}

export const selectRest = (restUid, newItem, whatFor) => {
  return dispatch => {
    if (restUid === null) {
      console.log("UNSELECTED_REST")
      dispatch({
        type: C.UNSELECTED_REST,
        payload: { fetching: null, restDoc: { ...nullRest }, dishes: null },
      })
    } else {
      var restDoc

      if (newItem) {
        dispatch(searchingItem(null))
        console.log("SELECTED_REST")
        dispatch({
          type: C.SELECTED_REST,
          payload: {
            fetching: null,
            newRest: newItem,
            restDoc: { ...nullRest, name: restUid },
          },
        })
      } else {
        console.log("restUid selectedRest", restUid)
        firebase
          .firestore()
          .collection("restaurants")
          .doc(restUid)
          .get()
          .then(doc => {
            if (doc.exists) {
              restDoc = doc.data()
              let restDocInStore = {
                ...nullRest,
                uid: restUid,
                evaluations: restDoc.evaluations,
                grades_abs: restDoc.grades_abs,
                grades_avg: restDoc.grades_avg,
                name: restDoc.name,
                tags: restDoc.tags,
                location: restDoc.location,
                address: restDoc.address,
                newRest: false,
                dishes: null,
              }
              console.log("REST_FETCHED")
              dispatch({
                type: C.REST_FETCHED,
                restDoc: restDocInStore,
              })
              dispatch(restDishes(restUid, restDocInStore, whatFor))
            } else {
              console.log("No such document!")
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error)
          })
      }
    }
  }
}

export const restDishes = (restUid, restDocInStore, whatFor) => {
  return dispatch => {
    console.log("DISHES_REST_FETCHING")
    dispatch({
      type: C.DISHES_REST_FETCHING,
      payload: {
        fetching: true,
        restDoc: restDocInStore,
      },
    })
    var dishesDocs = []

    firebase
      .firestore()
      .collection("dishes_restaurants")
      .where("rest.uid", "==", restUid)
      // .orderBy("evaluations")
      .get()
      .then(querySnapshot =>
        querySnapshot.forEach(doc =>
          dishesDocs.push({
            ...doc.data(),
            name: doc.data().dish.name,
            uid: doc.data().dish.uid,
          })
        )
      )
      .then(() => {
        console.log("DISHES_REST_FETCHED")
        dispatch({
          type: C.DISHES_REST_FETCHED,
          payload: {
            fetching: false,
            restDoc: { ...restDocInStore },
            dishes: dishesDocs,
          },
        })
        if (whatFor !== "evaluate")
          dispatch(
            fetchingEvaluations(dishesDocs[0].dish.uid, restDocInStore.uid)
          )
      })
  }
}
