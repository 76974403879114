import React, { useContext } from "react"
import styled from "styled-components"

import { ThemeContext } from "../../constants/index"

import Icon from "../../../media/Logo3cGlow2c2"

const WrapperIcon = styled.div`
  height: min-content;
  margin: auto;

  .texture-medal-shining {
    opacity: 1;
  }
`

export default (props: {
  width?: number
  style?: React.CSSProperties
  styleIcon?: React.CSSProperties
  className?: string
  instance?: string
}): JSX.Element => {
  const theme = useContext(ThemeContext)

  return (
    <WrapperIcon className={props.className} style={props.style}>
      <Icon
        js={false}
        style={props.styleIcon}
        theme={theme.theme}
        styleMode={theme.styleMode}
        width={props.width ? props.width : 100}
      />
    </WrapperIcon>
  )
}
