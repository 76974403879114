import C from "../actions/constants"

export const selectedRest = (
    state = { fetching: null, restDoc: null},
    action
  ) => {
    switch (action.type) {
      case C.UNSELECTED_REST:
        return Object.assign({}, state, { ...action.payload })
      case C.SELECTED_REST:
        return Object.assign({}, state, { ...action.payload })
      case C.REST_FETCHED:
        return Object.assign({}, state, { ...action.payload })
      case C.DISHES_REST_FETCHING:
        return Object.assign({}, state, { ...action.payload })
      case C.DISHES_REST_FETCHED:
        return Object.assign({}, state, { ...action.payload })

      default:
        return state
    }
  }
  