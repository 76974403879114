import C from "./constants"
import * as firebase from "firebase"
import { evaluate } from "./evaluate"

export const createDish = (form, createDishOnEvaluation, userData, message) => {
  return dispatch => {
    console.log("createDish")
    console.log("DISH_SAVING")
    dispatch({ type: C.DISH_SAVING })
    
    const dishRef = firebase.firestore().collection("dishes")
    dishRef
      .add({
        name: form.dish.name,
        evaluations: 0,
        tags: [],
        grades_abs: {
          taste: 0,
          price: 0,
          time: 0,
          size: 0,
          presentation: 0,
        },
        grades_avg: {
          taste: 0,
          price: 0,
          time: 0,
          size: 0,
          presentation: 0,
        },
      })
      .then(dishUid => {
        if (createDishOnEvaluation) {
          let newForm = { ...form }
          newForm.dish.uid = dishUid.id
          console.log("DISH_SAVE_DONE")
          dispatch({ type: C.DISH_SAVE_DONE, form: newForm })
          dispatch(evaluate(newForm, userData, message, true))
        } else {
          console.log("DISH_SAVE_DONE")
          dispatch({
            type: C.DISH_SAVE_DONE,
            form: { dish: { name: form.dish.name } },
          })
        }
      })
      .catch(error => {
        console.log("DISH_SAVE_ERROR")
        dispatch({ type: C.DISH_SAVE_ERROR, error: error })
      })
  }
}
