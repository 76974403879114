import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"

import { PropsEvaluations } from "../../utils/data/utilData"
import { Colors } from "../../constants/palette"
import { ThemeContext } from "../../constants/index"

import Typo from "../../0_atoms/Typo/Typo"
import Grades from "../../0_atoms/Grades/Grades"

const Wrapper = styled.div.attrs({ className: "evaluation" })`
  padding-top: 5px;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid #b1afaf;
  background-color: ${props =>
    props.styleMode === "new"
      ? "none"
      : Colors[props.styleMode][props.theme].L10};

  ${props =>
    props.transition &&
    css`
      display: none;
      opacity: 0;
    `}
`

interface PropsEvaluationDescription {
  evaluation: PropsEvaluations
}

const dateConsideringLang = (lang: string, date: any): string =>
  lang === "eng"
    ? date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    : date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()

export default (props: PropsEvaluationDescription): JSX.Element => {
  const { t } = useTranslation("common")
  const theme = useContext(ThemeContext)

  return (
    <Wrapper className="evaluationDescription" {...theme}>
      <Typo
        style={{
          marginLeft: "5px",
          fontSize: "12px",
          marginBottom: "0px",
          paddingBottom: "0px",
          color: Colors[theme.styleMode][theme.theme].letterAuxiliary2,
        }}
        cardText
      >
        {props.evaluation.dish.name} - {props.evaluation.rest.name}
      </Typo>
      <Typo
        style={{
          marginLeft: "5px",
          fontSize: "11px",
          marginBottom: "0px",
          paddingBottom: "0px",
          color: Colors[theme.styleMode][theme.theme].letterAuxiliary2,
          fontStyle: "italic",
        }}
        cardText
      >
        {props.evaluation.anonymous
          ? t("anonymous")
          : props.evaluation.user.login}
      </Typo>
      <Typo
        style={{
          margin: "auto",
          marginLeft: "5px",
          textAlign: "left",
          fontSize: "10.5px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: Colors[theme.styleMode][theme.theme].letterAuxiliary2,
        }}
        cardText
        title={dateConsideringLang(
          props.evaluation.lang,
          props.evaluation.creationTime.toDate()
        )}
      >
        {dateConsideringLang(
          props.evaluation.lang,
          props.evaluation.creationTime.toDate()
        )}
      </Typo>

      {props.evaluation.comments !== "" && (
        <Typo
          style={{
            margin: "0px 5px",
            textAlign: "justify",
            fontSize: "10.5px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            color: Colors[theme.styleMode][theme.theme].letterAuxiliary2,
          }}
          cardText
          title={props.evaluation.comments}
        >
          {'"' + props.evaluation.comments + '"'}
        </Typo>
      )}
      <Grades grades_avg={props.evaluation.grades} {...props} />
    </Wrapper>
  )
}
