import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { gsap } from "gsap"

import Typo from "../../0_atoms/Typo/Typo"
import Slider from "../../0_atoms/RangeSlider/RangeSlider"

const Wrapper = styled.div`
  margin: auto;
  height: 85px;
  padding-top: 10px;
  max-width: 450px;
`

const WrapperSlider = styled.div`
  width: 100%;
  text-align: center;
  padding-top: -5px;
`

const WrapperValue = styled.div`
  display: none;
  opacity: 0;
`

interface PropsEvaluateCharacteristic {
  firstPart: string
  domain: string
  secondPart: string
  low: string
  high: string
  value: number | undefined
  onChangeValue: Function
  color: string
  widthCard?: number
  resetComponent?: boolean
}

export default (props: PropsEvaluateCharacteristic): JSX.Element => {
  const [showValue, setShowValue] = useState(false)
  const [tweenValue]: any = useState(gsap.timeline({ paused: true }))

  const { t } = useTranslation("common")

  useEffect(() => {
    tweenValue
      .to(".slider-value-" + props.domain, 0.1, { display: "flex" })
      .to(".slider-value-" + props.domain, 0.2, { opacity: 1, autoAlpha: 1 })
      .reverse()
  }, [])

  useEffect(() => {
    if (props.resetComponent) tweenValue.reversed(true)
  }, [props.resetComponent])

  useEffect(() => {
    if (showValue) tweenValue.reversed(false)
  }, [showValue])

  return (
    <Wrapper>
      <Typo style={{ marginBottom: "-5px" }} cardText>
        {t(props.firstPart)}
        <Typo style={{ color: props.color }} button>
          {t(props.domain)}
        </Typo>
        {t(props.secondPart)}
      </Typo>

      <WrapperSlider onClick={() => setShowValue(true)}>
        <Slider
          color={props.color}
          onChange={props.onChangeValue}
          value={props.value}
          width={
            props.widthCard > 490
              ? 340
              : props.widthCard < 320
              ? 170
              : props.widthCard - 150
          }
          max={5}
        />
      </WrapperSlider>

      <div
        style={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
        }}
      >
        <Typo
          style={{
            opacity: 0.6,
            paddingTop: "0px",
            fontSize: "9.5px",
            margin: "0px",
            width: "100px",
          }}
          cardText
        >
          {t(props.low)}
        </Typo>
        <WrapperValue className={"slider-value-" + props.domain}>
          <Typo
            style={{
              paddingTop: "0px",
              fontSize: "11px",
              color: props.color,
              margin: "0px",
            }}
            cardText
          >
            {props.value}
          </Typo>
        </WrapperValue>
        <Typo
          style={{
            opacity: 0.5,
            paddingTop: "0px",
            fontSize: "9.5px",
            margin: "0px",
            width: "100px",
          }}
          cardText
        >
          {t(props.high)}
        </Typo>
      </div>
    </Wrapper>
  )
}
