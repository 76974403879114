import React, { useState, useEffect } from "react"
import Input from "../../0_atoms/Input/Input"
import Button from "../../0_atoms/Button/Button"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 130px;
  flex-flow: row wrap;
  align-content: space-around;
  justify-content: center;
`

interface PropsSignup{
  handleSignUp?: any
  pressed: boolean
}

export default (props: PropsSignup) : JSX.Element => {
  const { t } = useTranslation("common")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    setEmail("")
    setPassword("")
  }, [props.pressed])

  return (
    <Wrapper>
      <Input
        onChange={e => setEmail(e.target.value)}
        placeholder="E-mail"
        value={email}
        size="22"
      />

      <Input
        type="password"
        onChange={e => setPassword(e.target.value)}
        placeholder={t("login.password")}
        value={password}
        size="22"
      />

      <Button onClick={() => props.handleSignUp(email, password)} {...props}>
        {t("login.next")}
      </Button>
    </Wrapper>
  )
}
