import React, { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import propTypes from "prop-types"

import { dropdownList } from "../../constants/objects"
import { getHashChanges, settingHashParameters } from "../../utils/urlParams"
import { HashValuesContext, initialState } from "../../constants/index"
import { selectDish } from "../../../actions/selectedDish"
import { selectRest } from "../../../actions/selectedRest"
import { fetchingEvaluations } from '../../../actions/evaluations'

import Layout2 from "../../0_atoms/Layout/Layout2"
import Bar from "../Bar/Bar"
import Cards2 from "./Cards2"

const checkType = state => (typeof state === "string" ? state : "none")

interface PropsLayoutWrapper {
  isLogged: boolean
  dispatch: Function
  state: any
}

const findRouteRightClick = (state: string, t): void => {
  switch (state) {
    case "search":
      alert(t("find.find1.noSelection"))
      //settingHashParameters({ find: "dish" })
      break
    case "dish":
      settingHashParameters({ find: "search" })
      break
    case "presentation":
      settingHashParameters({ find: "search" })
      break
    case "none":
      settingHashParameters({ find: "search" })
      break
    case "restaurant":
      settingHashParameters({ find: "presentation", evaluate: "presentation" })
      break
    default:
  }
}

const findRouteLeftClick = (state: string): void => {
  switch (state) {
    case "dish":
      settingHashParameters({ find: "search" })
      break
    case "restaurant":
      settingHashParameters({ find: "search" })
      break
    case "search":
      settingHashParameters({ find: "presentation", evaluate: "presentation" })
      break
    default:
  }
}

const evaluateRouteRightClick = (state: string, t: any): void => {
  switch (state) {
    case "search":
      alert(t("evaluate.evaluate2.noSelection"))
      //settingHashParameters({ evaluate: "dish" })
      break
    case "dish":
      alert(t("evaluate.evaluate3.noSelection"))
      //settingHashParameters({ evaluate: "evaluate" })
      break
    case "presentation":
      settingHashParameters({ evaluate: "search" })
      break
    case "none":
      settingHashParameters({ evaluate: "search" })
      break
    case "evaluate":
      alert(t("evaluate.evaluate4.doSave"))
      //settingHashParameters({ find: "presentation", evaluate: "presentation" })
      break
    default:
  }
}

const evaluateRouteLeftClick = (state: string): void => {
  switch (state) {
    case "search":
      settingHashParameters({ find: "presentation", evaluate: "presentation" })
      break
    case "dish":
      settingHashParameters({ evaluate: "search" })
      break
    case "evaluate":
      settingHashParameters({ evaluate: "dish" })
      break
    default:
  }
}

const checkLoggedEvaluate = (isLogged, hashValues, message) => {
  if (!hashValues.evaluate && !hashValues.configs && !hashValues.find)
    return "presentation"
  if (isLogged) {
    return checkType(hashValues.evaluate)
  } else {
    if (
      checkType(hashValues.evaluate) === "presentation" ||
      checkType(hashValues.evaluate) === "none" ||
      checkType(hashValues.evaluate) === ""
    ) {
      return checkType(hashValues.evaluate)
    }
    alert(message)
    settingHashParameters({ find: "presentation", evaluate: "presentation" })
    return "none"
  }
}

const checkLoggedConfigs = (isLogged, hashValues, message) => {
  if (isLogged) {
    checkSelectedonEvaluate(hashValues)
    return checkType(hashValues.configurations)
  } else {
    if (
      checkType(hashValues.configurations) === "none" ||
      checkType(hashValues.configurations) === ""
    )
      return checkType(hashValues.configurations)
    alert(message)
    settingHashParameters({ find: "presentation", evaluate: "presentation" })
    return "none"
  }
}

const checkSelectedonEvaluate = hashValues => {
  if (hashValues.selected) {
    let selected = JSON.parse(decodeURIComponent(hashValues.selected))
    if (
      selected.dish &&
      selected.dish &&
      selected.restName &&
      selected.restUid
    ) {
      alert("Please select a dish and a restaurant to write an evaluation")
      return "presentation"
    }
  }
}

const checkSelectedOnFind = hashValues => {
  if (hashValues.selected) {
    let selected = JSON.parse(decodeURIComponent(hashValues.selected))

    if (checkType(hashValues.find) === "dish") {
      if (selected.dish && selected.dish) {
        alert("Please select a dish to see its evaluation")
        return "none"
      }
    } else if (checkType(hashValues.find) === "resturant") {
      if (selected.dish && selected.dish) {
        alert("Please select a restaurant to see its evaluation")
        return "none"
      }
    }
  }
  if (!hashValues.evaluate && !hashValues.configs && !hashValues.find)
    return "presentation"
  return checkType(hashValues.find)
}

const LayoutWrapper = (props: PropsLayoutWrapper): JSX.Element => {
  const { t } = useTranslation("common")
  const [find, setFind] = useState(() => checkType(getHashChanges().find))
  const [evaluate, setEvaluate] = useState(() =>
    checkLoggedEvaluate(
      props.isLogged,
      getHashChanges(),
      t("evaluate.noLogged")
    )
  )
  const [configurations, setConfigurations] = useState(() =>
    checkLoggedConfigs(props.isLogged, getHashChanges(), t("configs.noLogged"))
  )
  const [selected, setSelected] = useState("")
  const [hashValues, setHashValues]: any = useState(getHashChanges)
  const [state, setState] = useState()
  const [width, setWidth] = useState(320)

  const hashListener = useCallback(() => setHashValues(getHashChanges), [state])

  useEffect(() => {
    setWidth(document.getElementsByClassName("layout-wrapper")[0].clientWidth)
    window.addEventListener("hashchange", hashListener, false)
    return () => window.removeEventListener("hashchange", hashListener, false)
  }, [])

  useEffect(() => {
    if (Object.keys(hashValues).length > 0) {
      setFind(checkSelectedOnFind(hashValues))
      setEvaluate(
        checkLoggedEvaluate(props.isLogged, hashValues, t("evaluate.noLogged"))
      )
      setConfigurations(
        checkLoggedConfigs(props.isLogged, hashValues, t("configs.noLogged"))
      )
      if (
        checkType(hashValues.configurations) !== "none" &&
        checkType(hashValues.configurations) !== ""
      ) {
        setFind("none")
        setEvaluate("none")
      }
      setSelected(checkType(hashValues.selected))
    }

    if (
      hashValues.find === "presentation" &&
      hashValues.evaluate === "presentation"
    ) {
      props.dispatch(selectDish(null, false, "evaluate"))
      props.dispatch(selectRest(null, false, "evaluate"))
      props.dispatch(fetchingEvaluations(null, null))
    }
  }, [JSON.stringify(hashValues)])

  return (
    <HashValuesContext.Provider
      value={{
        find: find,
        evaluate: evaluate,
        configurations: configurations,
        selected: selected,
      }}
    >
      <Layout2
        bar={<Bar isLogged={props.isLogged} dropdownList={dropdownList} />}
        content={
          <Cards2
            widthCard={width}
            onClickFind={() => findRouteRightClick(find, t)}
            onClickLeftFind={() => findRouteLeftClick(find)}
            onClickEvaluate={() => evaluateRouteRightClick(evaluate, t)}
            onClickLeftEvaluate={() => evaluateRouteLeftClick(evaluate)}
            onClickConfigurations={() => settingHashParameters(initialState)}
            onClickEvaluateCard={() =>
              evaluate === "presentation"
                ? evaluateRouteRightClick(evaluate, t)
                : null
            }
            onClickFindCard={() =>
              find === "presentation" ? findRouteRightClick(find, t) : null
            }
          />
        }
      />
    </HashValuesContext.Provider>
  )
}

LayoutWrapper.propTypes = {
  state: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  state: state,
})

export default connect(mapStateToProps)(LayoutWrapper)
